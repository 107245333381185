import { createAction, props } from '@ngrx/store';

export const loadConversationsStart = createAction('[Conversations] Load Conversations', props<{ payload: any }>());
export const loadNextPageConversationsStart = createAction('[Conversations] Load Next Page Conversations');
export const loadConversationsSuccess = createAction(
	'[Conversations] Load Conversations Success',
	props<{ payload: any }>()
);
export const loadConversationsError = createAction('[Conversations] Load Conversations Error');

export const readConversation = createAction(
	'[Conversations] Read Conversation',
	props<{ id: string; user: string; needRead: number }>()
);

export const updateConversionStart = createAction(
	'[Conversations] Update Conversation',
	props<{ id: any; msg: any; myInfoId: any; sendOrNot?: string }>()
);

export const addAvatar = createAction(
	'[Conversations] add Conversation Avarar',
	props<{ payload: any; MyinfoId: any }>()
);
