import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SharedservicelistComponent } from 'src/app/components/sharedservicelist/sharedservicelist.component';
import { Globals } from 'src/app/globals';
import { AppState } from 'src/app/store';
import { loadNextPageServicesStart, loadServicesStart } from 'src/app/store/actions/services.action';
import { SearchmodalPage } from '../../components/searchmodal/searchmodal.page';

@Component({
	selector: 'app-service',
	templateUrl: './service.page.html',
	styleUrls: ['./service.page.scss'],
})
export class ServicePage implements OnInit {
	// @ViewChild(SharedservicelistComponent, { static: true })
	@ViewChild(IonInfiniteScroll)
	infiniteScroll: IonInfiniteScroll;

	public services: any;

	category = null;
	subCategory = null;
	id = undefined;
	certificates = [];
	keyword = null;
	serviceLoaded = false;
	orgId = null;
	currentUrl;
	searchString = {};

	headers = [
		'id',
		'introduction',
		'reviewNumber',
		'price',
		'likedCount',
	];

	insertValues = {
		id: '',
		introduction: '',
		organization: '',
		age: '',
		reviewNumber: '',
		category: '',
		likedCount: '',
		price: '',
		page: 1,
	};

	stringTypeHeaders = ['id', 'introduction', 'organization', 'certificates'];
	numberTypeHeaders = ['reviewNumber', 'likedCount', 'price'];
	sharedservicelistComponent: SharedservicelistComponent;

	services$: Observable<any>;
	page: 1;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private store: Store<AppState>,
		public modalController: ModalController,
		public globals: Globals
	) {
		this.currentUrl = this.router.url;
	}
	ngOnInit() {
		this.getServices();
		this.activatedRoute.url.subscribe(() => {
			this.getServices();
		  });
		this.services$ = this.store.select('services').pipe(
			tap((res: any) => {
				for (const key in res.requestParams) {
					if (res.requestParams[key] != null) {
						this.insertValues[key] = res.requestParams[key];
					}
				}
			})
		);
	}

	loadData(event) {
		//到达底部了， dispatch一下
		this.store.dispatch(loadNextPageServicesStart());
		event.target.complete();
	}

	getServices() {
		this.store.dispatch(
			loadServicesStart({
				payload: {
					// search: JSON.stringify(this.searchString),
					page: this.page || 1,
					limit: 20,
					// category: this.globals.serviceCategory,
					// city: this.globals.serviceCity,
					// memberOf: this.orgId,
				},
			})
		);
	}

	openServiceDetailsPage(service) {}

	search(ev) {
		const val = ev.target.value;

		// search value
		console.log(val);
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: SearchmodalPage,
			componentProps: {
				headers: this.headers,
				numberTypeHeaders: this.numberTypeHeaders,
				stringTypeHeaders: this.stringTypeHeaders,
				values: this.insertValues,
			},
		});

		modal.onDidDismiss().then((dataReturned) => {
			if (dataReturned !== null) {
				console.log(dataReturned.data);
				this.searchString = dataReturned.data;
				this.store.dispatch(
					loadServicesStart({
						payload: {
							// search: JSON.stringify(this.searchString),
							page: this.page,
							limit: 20,
							// category: dataReturned.data.category,
							// city: dataReturned.data.city,
							// memberOf: this.orgId,
							...dataReturned.data,
						},
					})
				);
				this.infiniteScroll.disabled = false;
			}
		});

		return await modal.present();
	}
}
