import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { AppState } from 'src/app/store';
import { loadMyAppointmentsStart } from 'src/app/store/actions/myAppointments.action';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { ServiceService } from '../../../services/service.service';
import { readConversation } from 'src/app/store/actions/conversations.action';

@Component({
	selector: 'app-my-appointments',
	templateUrl: './myAppointments.page.html',
	styleUrls: ['./myAppointments.page.scss'],
})
export class MyAppointmentsPage implements OnInit {
	teacherId: string;
	password: string;
	param = 'world';
	myAppointments = [];
	isToday: boolean;
	calendar = {
		mode: 'week',
		currentDate: new Date(),
	};
	eventSource;
	alreadyLoggedIn = false;
	myInfo: any = {};
	show = [];
	isMobile = false;
    currentUrl;
	tableData: any;
	tableHeader = ['name', 'client', 'startTime', 'endTime', 'status'];
	rowsOnClicks: any;
	system$: Observable<any>;

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public authService: AuthService,
		private globalService: GlobalService,
		private alertCtrl: AlertController,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		public alertController: AlertController,
		private utilService: UtilService,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store.dispatch(loadMyAppointmentsStart());

		this.store.select('login').subscribe((res) => {
			this.myInfo = res.myInfo;
			this.alreadyLoggedIn = res.status === 'success';
		});
        this.currentUrl = this.router.url
		this.system$ = this.store.select('system');
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	formatDateToISOString(time) {
		new Date(time).toISOString().substring(0, 16);
	}

	ngOnInit() {
		this.store.select('myAppointments').subscribe((res: any) => {
			const resData = JSON.parse(JSON.stringify(res.data));
			this.myAppointments = resData.map((x) => (x = this.utilService.ISODateToInput(x)));
			const tableRowsData = this.myAppointments.length
				? this.myAppointments.map((element) => [
						element.service ? element.service.id : element.bigEvent.id,
						element.client?.nickname,
						element.startTime.replace('T', '　'),
						element.endTime.replace('T', '　'),
						element.status,
				  ])
				: [];
			this.tableData = [this.tableHeader, ...tableRowsData];
			this.rowsOnClicks = this.tableData.map((x: any[]) =>
				x[5]?.status === 'clientCancel'
					? () => this.teacherCancelReservation(x)
					: () => this.openCommentPage(x[0])
			);
		});
	}

	openCommentPage(_id) {
		this.router.navigateByUrl(this.router.url + '/comment?_id=' + _id);
	}

	elementStatus(element) {
		return element.status;
	}

	handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}

	openEventPage(e) {}

	openServicePage(e) {}
	getMyAppointments = () =>
		this.http.get<any>(environment.defaultURL + '/user/getMyCalendarAsTeacher').pipe(
			tap(() => this.globalService.toast(this.translate.instant('success'), 1500, 'top')),
			catchError(this.handleError('applyToJoinOrg', []))
		);

	async presentAlertConfirm(element) {
		const alert = await this.alertController.create({
			header: this.translate.instant('ConfirmTitle'),
			message: this.translate.instant('ConfirmMessage'),
			buttons: [
				{
					text: this.translate.instant('cancel'),
					role: 'cancel',
					handler: () => {},
				},
				{
					text: this.translate.instant('OK'),
					handler: () => {
						this.http
							.post(environment.defaultURL + '/reservation/teacherCancelReservation', {
								_id: this.myInfo._id,
								reservation_id: element._id,
								reason: 'ok',
							})
							.subscribe(
								(data2) => {
									if (data2) {
										this.globalService.toast(
											this.translate.instant('teacherCancelDone'),
											1500,
											'top'
										);
										element.status = 'teacherCancel';
									}
								},
								(err) => {
									if (this.testJSON(err._body)) {
										const errJson1 = JSON.parse(err._body);
										if (errJson1.message) {
											this.globalService.toast(
												this.translate.instant(errJson1.message),
												1500,
												'top'
											);
										}
									}
								}
							);
					},
				},
			],
		});
		await alert.present();
	}

	teacherCancelReservation(element) {
		if (this.myInfo) {
			if (this.myInfo.id) {
				this.presentAlertConfirm(element);
			} else {
				this.globalService.toast(this.translate.instant('loginPlease'), 1500, 'top');
			}
		} else {
			this.globalService.toast(this.translate.instant('loginPlease'), 1500, 'top');
		}
	}

	testJSON(text) {
		if (typeof text !== 'string') {
			return false;
		}
		try {
			JSON.parse(text);
			return true;
		} catch (error) {
			return false;
		}
	}

    openChatPage(patient) {
		this.store.dispatch(
			readConversation({
				id: patient.id,
				user: this.myInfo.id,
				needRead: 0,
			})
		);
		this.router.navigate([
			'/tabs/settings/myAppointments/',
			patient._id,
			'chat',
			patient.client?.id,
			patient.client?.nickname,
			patient._id,
			'123',
			{ p: this.currentUrl },
		]);

		// } else {
		//     this.globalService.toast(this.translate.instant('onlyCanChatYouLike'), 1500, 'top');
		// }
	}
	// doRefresh(refresher) {
	//
	//
	//     if (this.alreadyLoggedIn === true) {
	//
	//         setTimeout(() => {
	//
	//             this.userProvider.myCalendarAsTeacher(this.myInfo)
	//                 .then(data2 => {
	//                     var data3 = []
	//                     data3 = this.changeISOtoDate(data2)
	//
	//                     if (data3.length) {
	//                         if (!this.myInfo.freeTime) this.myInfo.freeTime = []
	//                         var data4 = this.changeISOtoDate(this.myInfo.freeTime)
	//                         this.freeTime = data4
	//                         this.eventSource = data4.concat(data3)
	//                     }
	//                 })
	//             refresher.complete();
	//         }, 1000);
	//     } else {
	//
	//         setTimeout(() => {
	//             this.userProvider.alert('', 'Please Login!', ['OK'], this.alertCtrl).present()
	//             refresher.complete();
	//         }, 1000);
	//     }
	// }
}
