import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  getQiniuNewUploadToken(validation) {
    return this.http.get(environment.defaultURL + '/file/upload/tokenNew');
  }

  changeMyInfo(data) {
    return this.http.put<any>(environment.defaultURL + '/user/', data);
  }
}
