import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { AppState } from 'src/app/store';
import { submitCommentStart } from 'src/app/store/actions/myAppointments.action';

@Component({
	selector: 'app-comment',
	templateUrl: './comment.page.html',
	styleUrls: ['./comment.page.scss'],
})
export class CommentPage implements OnInit {
	comment = {
		condition: '',
		advice: '',
		prescription: '',
	};

	reservation_id: any;

	constructor(
		teacherService: TeacherService,
		private translate: TranslateService,
		private http: HttpClient,
		private _location: Location,

		private activatedRoute: ActivatedRoute,
		private globalService: GlobalService,
		private store: Store<AppState>
	) {
		this.reservation_id = this.activatedRoute.snapshot.queryParams._id;
	}

	ngOnInit() {
		this.store.select('myAppointments').subscribe((res: any) => {
			const resData = res.data;
			const commentData =
				resData.find(
					(x) =>
						x._id === this.reservation_id && {
							condition: x.condition,
							advice: x.advice,
							prescription: x.prescription,
						}
				) || {};
			this.comment = JSON.parse(JSON.stringify(commentData));
		});
	}
	submitComment() {
		const requestData = {
			reservation_id: this.reservation_id,
			reservation: this.comment,
		};
		this.store.dispatch(submitCommentStart({ payload: requestData }));
		this._location.back();
	}
}
