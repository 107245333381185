import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { ChangeObserverService } from '../../../services/changeObserver.service';
import { GlobalService } from '../../../services/global.service';
import { OrgService } from '../../../services/org.service';
import { ServiceService } from '../../../services/service.service';
import { TeacherService } from '../../../services/teacher.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-approve-org-manager',
	templateUrl: './approveOrgManager.page.html',
	styleUrls: ['./approveOrgManager.page.scss'],
})
export class ApproveOrgManagerPage implements OnInit {
	id: string;
	password: string;
	param = 'world';
	certificates = [];
	categoryName = [];
	candidates: any;
	userLevelOrgManagers: any;

	uploadedImg = { data: undefined };
	page = 1;
	searchString = {};
	userType = 'candidates';

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private teacherService: TeacherService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public globals: Globals,
		public authService: AuthService,
		private userService: UserService,
		private orgService: OrgService,
		private changeObserver: ChangeObserverService,
		private globalService: GlobalService,
		public navCtrl: NavController,
		private _location: Location
	) {}

	getData(){
		const userLevelNormalToOrgManagerSearchString = {
			userLevel: this.globals.userLevelNormalToOrgManager,
		};
		const userLevelOrgManagersearchString = {
			userLevel: this.globals.userLevelOrg,
		};

		this.userService
			.adminGetUser(userLevelNormalToOrgManagerSearchString, this.page, null, null, null)
			.subscribe((res: any[]) => {
				this.candidates = res;
			});
			
		this.userService
			.adminGetUser(userLevelOrgManagersearchString, this.page, null, null, null)
			.subscribe((res: any[]) => {
				this.userLevelOrgManagers = res;
			});
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ngOnInit() {
		this.getData();
	}

	jsonStringify(x) {
		if (x) {
			return { itemValue: JSON.stringify(x) };
		} else {
			return { itemValue: null };
		}
	}

	rejectOrg(_id) {
		this.userService.rejectApplyForOrgManager({ _id }).subscribe(
			(data) => {
				console.log(data);
				this.globalService.toast(this.translate.instant('success'), 1500, 'top');
				this.candidates = this.candidates.filter((x) => x !== _id);
			},
			(error) => {
				this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
			}
		);
		this.getData();
	}

	approveOrgManager(_id) {
		this.userService.approveApplyForOrgManager({ _id }).subscribe(
			(data) => {
				this.globalService.toast(this.translate.instant('success'), 1500, 'top');
				this.candidates = this.candidates.filter((x) => x._id !== _id);
			},
			(error) => {
				this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
			}
		);
		this.getData();
	}
}
