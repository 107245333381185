import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { tokenLoginStart } from 'src/app/store/actions/login.action';
import { Globals } from '../../../../globals';
import { AuthService } from '../../../../services/auth.service';
import { ChangeObserverService } from '../../../../services/changeObserver.service';
import { CityService } from '../../../../services/city.service';
import { UtilService } from '../../../../services/util.service';
import { GlobalService } from '../../../../services/global.service';
import { ImageProcessService } from '../../../../services/imageProcess.service';
import { JMessageService } from '../../../../services/jMessage.service';
import { SettingsService } from '../../../../services/settings.service';

@Component({
  selector: 'app-my-information-change',
  templateUrl: './myInformationChange.page.html',
  styleUrls: ['./myInformationChange.page.scss'],
})
export class MyInformationChangePage {
  @ViewChild('Select', { static: false }) selectComp;
  @ViewChildren('Select2') selectComp2s;
  @ViewChild('Select3', { static: false }) selectComp3;

  id: string;
  password: string;
  param = 'world';
  alreadyLoggedIn = false;
  newCertificate: any = { category: '请选择' };
  certificates: any = [];
  itemName;
  itemValue: any;
  uploadedImg = { data: undefined };
  selected1;

  trueOrFalse;
  selected: any = [];
  buttonDisabled = false;
  deviceTypeIsIpad = false;
  previousUrl;

  result = 'select';
  value = [];
  cityData = [];
  categoryData = [];
  myInfo;

  imageKey;

  chosenValue;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    public globals: Globals,
    public authService: AuthService,
    public utilService: UtilService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private globalService: GlobalService,
    private changeObserver: ChangeObserverService,
    private cityService: CityService,
    private imageProcess: ImageProcessService,
    public navCtrl: NavController,
    private _location: Location,
    private store: Store<AppState>,
    public jMessage: JMessageService
  ) {
    this.store
      .select('login')
      .pipe(first())
      .subscribe((res: any) => {
        this.myInfo = res.myInfo;
      });
    const parsedParam = this.activatedRoute.snapshot.paramMap;
    this.previousUrl =
      parsedParam.get('p') === 'undefined' || parsedParam.get('p') === null
        ? '/tabs/settings/myInformation'
        : parsedParam.get('p');
    this.itemName = this.activatedRoute.snapshot.paramMap.get('itemName');

    this.itemValue = this.myInfo[this.itemName];

    if (this.itemName === 'certificates') {
      if (!this.itemValue) {
        this.itemValue = [];
      } else {
        this.itemValue = JSON.parse(JSON.stringify(this.myInfo[this.itemName]));
      }
    }

    if (this.itemName === 'category') {
      if (!this.itemValue) {
        this.itemValue = [];
      } else {
        this.itemValue = JSON.parse(JSON.stringify(this.myInfo[this.itemName]));
      }
    }

    this.previousUrl =
      this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
      this.activatedRoute.snapshot.paramMap.get('p') === null
        ? '/tabs/settings/myInformation'
        : this.activatedRoute.snapshot.paramMap.get('p');
  }
  onSwipeLeft($event) {
    this.navCtrl.setDirection('back', true, 'back');
    this._location.back();
  }
  ngOnInit() {
    try {
      this.activatedRoute.url.subscribe(async () => {
        [this.categoryData, this.cityData] =
          await this.utilService.changeLanguage();
        [, this.newCertificate.category] = this.utilService.setAlias();
      });
    } catch (err) {
      console.error(err);
    }
  }
  onSwipeRight($event) {}

  onOk(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    const myString = value.map((v) => v).join(',');
    this.chosenValue = myString.includes(',全部')
      ? myString.substring(0, myString.indexOf(',全部'))
      : myString;
    this.itemValue.push(this.chosenValue);
  }

  onCityOk(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    const myString = value.map((v) => v).join(',');
    this.chosenValue = myString.includes(',全部')
      ? myString.substring(0, myString.indexOf(',全部'))
      : myString;
    this.itemValue = this.chosenValue;
  }

  onCertificateOk(result, i) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    const myString = value.map((v) => v).join(',');
    const itemValue = myString.includes(',全部')
      ? myString.substring(0, myString.indexOf(',全部'))
      : myString;
    this.itemValue[i].category = itemValue;
  }

  onNewCertificateOk(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    const myString = value.map((v) => v).join(',');
    const itemValue = myString.includes(',全部')
      ? myString.substring(0, myString.indexOf(',全部'))
      : myString;
    this.newCertificate.category = itemValue;
  }

  removeCategory(i) {
    this.itemValue.splice(i, 1);
  }

  title() {
    if (this.itemName === 'imageURL') {
      return 'headImage';
    } else if (this.itemName === 'category') {
      return 'Service Type';
    } else {
      return this.itemName;
    }
  }
  changeItemValue() {
    if (!this.itemValue && this.itemName !== 'privateAccount') {
      this.globalService.toast(
        this.translate.instant('saveEmpty'),
        1500,
        'top'
      );
    }
    if (this.itemName === 'contact') {
      if (!this.validatePhoneNumber(this.itemValue)) {
        this.globalService.toast(
          this.translate.instant('contactErr'),
          1500,
          'top'
        );
        return;
      }
    }
    if (
      (this.itemName && this.itemValue) ||
      this.itemName === 'privateAccount'
    ) {
      if (this.itemValue.imageKey) {
        delete this.itemValue.imageURL;
      }
      if (this.itemValue.certificates) {
        this.itemValue.certificates.map((certificate, i) => {
          if (certificate.imageKey) {
            delete this.itemValue[i].imageURL;
          }
        });
      }

      const change = { imageKey: null };
      if (this.itemName === 'imageURL') {
        change.imageKey = this.imageKey;
      } else {
        change[this.itemName] = this.itemValue;
      }
      this.settingsService
        .changeMyInfo({ ...this.myInfo, ...change })
        .subscribe(
          (data) => {
            this.globalService.toast(
              this.translate.instant('successfullyUpdated'),
              1500,
              'top'
            );

            this.store.dispatch(tokenLoginStart());
            this._location.back();
          },
          (err) => {
            this.globalService.toast(
              this.translate.instant('err'),
              1500,
              'top'
            );
          }
        );
    }
  }
  validatePhoneNumber(phoneNumber) {
    let regex = /^1\d{10}$/;
    return regex.test(phoneNumber);
  }
  uploadImageTrigger() {
    this.imageProcess.uploadToQiniu('uploadImage', '').then((data) => {
      console.log('qiniuuploaddata', data);
      this.itemValue = data.imageURL;
      this.imageKey = data.imageKey;
      console.log(data);

      // 更新用户在极光上的头像;
      const formdata = new FormData();
      formdata.append('file', data.uploader._fileInput.files[0]);
      this.jMessage.updateSelfAvatar(formdata);
    });
  }

  uploadCertificateImageTrigger(i) {
    this.imageProcess
      .uploadToQiniu('uploadCertificateImage', i)
      .then((data) => {
        console.log(this.itemValue);
        this.itemValue[i].imageKey = data.imageKey;
        this.itemValue[i].imageURL = data.imageURL;
      });
  }

  uploadNewCertificateImageTrigger() {
    if (this.newCertificate.id && this.newCertificate.category) {
      this.imageProcess
        .uploadToQiniu('uploadNewCertificateImage', '')
        .then((data) => {
          this.newCertificate.imageKey = data.imageKey;
          this.newCertificate.imageURL = data.imageURL;
          this.itemValue =
            this.itemValue && this.itemValue.length > 0
              ? this.itemValue.concat([this.newCertificate])
              : [this.newCertificate];
          this.newCertificate = {};
        });
    } else {
      this.globalService.toast(
        this.translate.instant('inputAllFieldsPlease'),
        1500,
        'top'
      );
    }
  }
}
