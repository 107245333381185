import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { AppState } from 'src/app/store';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { EventService } from '../../../services/event.service';
import { GlobalService } from '../../../services/global.service';
import { LikeService } from '../../../services/like.service';

@Component({
	selector: 'app-event-details',
	templateUrl: './eventdetails.page.html',
	styleUrls: ['./eventdetails.page.scss'],
})
export class EventdetailsPage implements OnInit {
	myId;
	currentUrl = '';
	previousUrl;
	event: any = {
		_id: '',
		id: '',
		review: [],
		introduction: '',
		likedBy: [],
		owner: {
			_id: '',
			nickname: '',
			imageURL: '',
		},
		URL: null,
		imageURL: '',
		shopURL: '',
		category: {
			main: '',
		},
	};
	certificates;
	url;
	alreadyLoggedIn = false;
    myInfo: any = {};
    data: Object;

	constructor(
		private http: HttpClient,
		private eventService: EventService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public authService: AuthService,
		private likeService: LikeService,
		private globalService: GlobalService,
		private utilService: UtilService,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		this.store
			.select('login')
			.pipe(first())
			.subscribe((res) => {
				this.myInfo = res.myInfo;
				this.alreadyLoggedIn = res.status === 'success';
			});
        this.myId = this.activatedRoute.snapshot.paramMap.get('myId');
        this.data = { _id: this.myId };
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/event'
				: this.activatedRoute.snapshot.paramMap.get('p');

		this.currentUrl = this.router.url;

		console.log(this.activatedRoute.snapshot.paramMap);
		this.eventService.getEventDetails(this.myId).subscribe((data: any) => {
			console.log(data);
			this.event = this.utilService.ISODateToInput(data);
            this.event.rateAvg = this.event.rateAvg.toFixed(1);

			if (data.certificates) {
				this.certificates = data.certificates;
			}
			this.url = data.eventURL;

			this.event.likedByUser = this.myInfo.likedBigEvent && this.myInfo.likedBigEvent.includes(this.event._id);
		});
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {}

	like(type, target) {
		this.likeService.like(type, target).subscribe((data) => {
			this.event = data;
		});
	}

	unlike(type, target) {
		this.likeService.unlike(type, target).subscribe((data) => {
			this.event = data;
		});
	}

	eventURL() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.event.serviceURL);
	}

	openPurchasePage() {
		//  window.open("http://" + this.eventDetails.shopURL, "_system");

		//let browser = new InAppBrowser("http://" + this.eventDetails.shopURL, '_blank');
		//let browser = new InAppBrowser('https://appear.in/' + "jfisdlfjsl",'_system');
		window.open('http://' + this.event.shopURL, '_system');
	}

	share() {
		this.globalService.share(this.event);
	}
}
