import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { formatISO,eachMinuteOfInterval,parseISO  } from 'date-fns';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { tokenLoginStart } from 'src/app/store/actions/login.action';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../../globals';
import { ServiceService } from '../../../../services/service.service';
import { AuthService } from './../../../../services/auth.service';
import { GlobalService } from './../../../../services/global.service';

@Component({
	selector: 'app-update-schedule',
	templateUrl: './updateSchedule.page.html',
	styleUrls: ['./updateSchedule.page.scss'],
})
export class UpdateSchedulePage implements OnInit {
	calendarList: any = {};
	freeTimeISO: any = [{}];

	localFreeTimeISO: any = [];

	addedCalendarSourceISO = [];
	deletedCalendarSourceISO = [];
	calendarTrigger = true;
	previousUrl;
	alreadyLoggedIn = false;
	myInfo: any = {};
    stepTime: number;

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		public authService: AuthService,
		private globalService: GlobalService,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store
			.select('login')
			.pipe(first())
			.subscribe((res) => {
				this.myInfo = res.myInfo;

				this.alreadyLoggedIn = res.status === 'success';
			});
		this.activatedRoute.queryParams.subscribe((data) => {
			this.freeTimeISO = JSON.parse(data.changedSchedule);
            this.stepTime = data.selectedTime;
			this.freeTimeISO.map((element) => {
				this.localFreeTimeISO.push({
					startTime: this.setLocalTime(element.startTime),
					endTime: this.setLocalTime(element.endTime),
				});
			});
		});
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/settings/mySchedule'
				: this.activatedRoute.snapshot.paramMap.get('p');
	}

	setLocalTime = (time) => {
		// Get the time zone set on the user's device
		const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const x = formatISO(new Date(time));
		return x.substring(0, x.lastIndexOf('+'));
	};

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {}

	addReservation(x) {
		for (let i = 0; i < this.freeTimeISO.length; i++) {
			if (i === x) {
				this.freeTimeISO.push({
					startTime: this.freeTimeISO[i].startTime,
					endTime: this.freeTimeISO[i].endTime,
				});
				this.localFreeTimeISO.push({
					startTime: this.localFreeTimeISO[i].startTime,
					endTime: this.localFreeTimeISO[i].endTime,
				});
			}
		}
	}

	cancelReservation(x) {
		for (let i = 0; i < this.freeTimeISO.length; i++) {
			if (i === x) {
				this.freeTimeISO.splice(i, 1);
				this.localFreeTimeISO.splice(i, 1);
			}
		}
	}

	makeCalendar() {
		const calendar: any = {};

		this.freeTimeISO.map((element, index) => {
			delete this.freeTimeISO[index].action;
		});

		this.localFreeTimeISO.map((element, index) => {
			delete this.localFreeTimeISO[index].action;
		});

		calendar.calendar = this.localFreeTimeISO;
		let result = [];
		calendar.calendar.map(item => {
			const arr = eachMinuteOfInterval({
				start: parseISO(item.startTime),
				end:parseISO(item.endTime)
			}, { step: this.stepTime })
			for (let time = 0; time < arr.length-1; time++){
				result.push({
					startTime: formatISO(arr[time]),
					endTime:formatISO(arr[time+1])
				})
			}
		})

		calendar.validation = this.myInfo;

		this.http.post(environment.defaultURL + '/user/calendar', {calendar:result}).subscribe(
			(data) => {
				this.globalService.toast(this.translate.instant('success'), 1500, 'top');
				this.calendarTrigger = false;
				this.freeTimeISO = [];
				this.store.dispatch(tokenLoginStart());
				this.navCtrl.navigateBack(this.previousUrl);
			},
			(err) => {
				this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
			}
		);
	}
}
