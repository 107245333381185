import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SharedteacherlistComponent } from 'src/app/components/sharedteacherlist/sharedteacherlist.component';
import { AppState } from 'src/app/store';
import { loadNextPageTeachersStart, loadTeachersStart } from 'src/app/store/actions/teachers.action';
import { SearchmodalPage } from '../../components/searchmodal/searchmodal.page';

@Component({
	selector: 'app-teacher',
	templateUrl: './teacher.page.html',
	styleUrls: ['./teacher.page.scss'],
})
export class TeacherPage implements OnInit {
	// @ViewChild(SharedteacherlistComponent, { static: true })
	@ViewChild(IonInfiniteScroll)
	infiniteScroll: IonInfiniteScroll;
	sharedteacherlistComponent: SharedteacherlistComponent;

	public menu1: any = [];
	public menu2: any = [];
	public menu3 = [];
	public menu4 = [];
	public grids = [];
	teachers$: Observable<any>;

	teachers: any;

	page = 1;
	category = null;
	city = null;
	id = undefined;
	certificates = [];
	teacherLevel = 2;
	keyword = null;
	teacherLoaded = false;
	orgId = null;
	currentUrl;
	searchString = {};

	headers = ['nickname', 'introduction', 'age', 'certificates'];

	insertValues = {
		nickname: '',
		introduction: '',
		organization: '',
		age: '',
		reviewNumber: '',
		category: '',
		likedCount: '',
		certificates: '',
		page: 1,
	};

	stringTypeHeaders = ['nickname', 'introduction', 'organization', 'certificates'];
	numberTypeHeaders = ['age'];

	constructor(private store: Store<AppState>, private router: Router, public modalController: ModalController) {
		this.currentUrl = this.router.url;
	}

	ngOnInit() {
		this.getTeachers();
		this.teachers$ = this.store.select('teachers').pipe(
			tap((res) => {
				for (const key in res.requestParams) {
					if (res.requestParams[key] != null) {
						this.insertValues[key] = res.requestParams[key];
					}
				}
			})
		);
	}

	public getTeachers() {
		this.store.dispatch(
			loadTeachersStart({
				payload: {
					page: this.page || 1,
					limit: 20,
				},
			})
		);
	}
	search(ev) {
		this.searchString = ev.target.value;
		this.getTeachers();
	}

	loadData(event) {
		//到达底部了， dispatch一下
		this.store.dispatch(loadNextPageTeachersStart());
		event.target.complete();
	}

	async presentModal() {
		console.log(this.insertValues);
		const modal = await this.modalController.create({
			component: SearchmodalPage,
			componentProps: {
				headers: this.headers,
				numberTypeHeaders: this.numberTypeHeaders,
				stringTypeHeaders: this.stringTypeHeaders,
				values: this.insertValues,
			},
		});

		modal.onDidDismiss().then((dataReturned) => {
			if (dataReturned !== undefined) {
				console.log(dataReturned.data);
				this.searchString = dataReturned.data;

				this.store.dispatch(
					loadTeachersStart({
						payload: {
							page: this.page,
							limit: 20,
							...dataReturned.data,
						},
					})
				);
			}
		});

		return await modal.present();
	}
}
