import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { loadEventsStart } from 'src/app/store/actions/events.action';
import { loadOrgsStart } from 'src/app/store/actions/orgs.action';
import { loadServicesStart } from 'src/app/store/actions/services.action';
import { loadTeachersStart } from 'src/app/store/actions/teachers.action';
import { Globals } from '../../globals';
import { CityService } from '../../services/city.service';
import { GlobalService } from '../../services/global.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-shared-menu',
  templateUrl: 'sharedmenu.component.html',
  styleUrls: ['./sharedmenu.component.scss']
})
export class SharedmenuComponent implements OnInit {
  @Input() whichPage: string;
  @Output() searchChanged: EventEmitter<any> = new EventEmitter();

  @Input() searchString: any;

  grids;
  cityData = [];

  city: any = '全国';
  category: any = '全部';
  categoryData = [];

  orgs: any;
  teachers: any;

  city$: Observable<any>;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private globalService: GlobalService,
    private cityService: CityService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private globals: Globals,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    try {
      switch (this.whichPage) {
        case '/tabs/teacher':
          this.getData('teachers');
          break;
        case '/tabs/org':
          this.getData('orgs');
          break;
        case '/tabs/service':
          this.getData('services');
          break;
        case '/tabs/event':
          this.getData('events');
          break;
        default:
          break;
      }

      this.activatedRoute.url.subscribe(async () => {
        [this.categoryData, this.cityData] =
          await this.utilService.changeLanguage();
      });
    } catch (err) {
      console.error(err);
    }
  }
  getData(route) {
    this.store.select(route).subscribe((res) => {
      if (res.requestParams.city != null) {
        this.city =
          res.requestParams.city.$regex ?? (res.requestParams.city || '全国');
      } else {
        [this.city] = this.utilService.setAlias();
      }
      if (res.requestParams.category != null) {
        this.category =
          res.requestParams.category.$regex ??
          (res.requestParams.category || '全部');
      } else {
        [, this.category] = this.utilService.setAlias();
      }
    });
  }

  onOk(result) {
    this.city = this.utilService.filterCategory(result);
    this.emitChange();
  }
  emitChange() {
    const obj = {};

    if (this.whichPage.includes('teacher')) {
      this.store.dispatch(
        loadTeachersStart({
          payload: {
            category: { $regex: this.category },
            city: { $regex: this.city }
          }
        })
      );
    } else if (this.whichPage.includes('org')) {
      this.store.dispatch(
        loadOrgsStart({
          payload: {
            category: { $regex: this.category },
            city: { $regex: this.city }
          }
        })
      );
    } else if (this.whichPage.includes('service')) {
      this.store.dispatch(
        loadServicesStart({
          payload: {
            category: { $regex: this.category },
            city: { $regex: this.city }
          }
        })
      );
    } else if (this.whichPage.includes('event')) {
      this.store.dispatch(
        loadEventsStart({
          payload: {
            category: { $regex: this.category },
            city: { $regex: this.city }
          }
        })
      );
    }
    // this.searchChanged.emit();
  }

  onCategoryOk(result) {
    this.category = this.utilService.filterCategory(result);
    this.emitChange();
  }

  handleFilter(){
    const lang = this.translate.currentLang
    switch(lang) {
        case 'en':
    }
  }
}
