import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { EventService } from '../../../services/event.service';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-my-events',
  templateUrl: './myEvents.page.html',
  styleUrls: ['./myEvents.page.scss'],
})
export class MyEventsPage implements OnInit {
  teacherDetails: any = [];
  showCommentBox;
  comment;
  rate;
  validation: any = {};
  isToday: boolean;
  page = 1;
  searchString = {};
  currentUrl;
  alreadyLoggedIn = false;
  myInfo: any = {};

  constructor(
    private http: HttpClient,
    private eventService: EventService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private sanitizer: DomSanitizer,
    public authService: AuthService,
    private globalService: GlobalService,
    private alertCtrl: AlertController,
    public globals: Globals,
    public navCtrl: NavController,
    private _location: Location,
    private store: Store<AppState>
  ) {
    // this.myInfo = globals.myInfo;
    // this.alreadyLoggedIn = globals.alreadyLoggedIn;
    this.store
      .select('login')
      .pipe(first())
      .subscribe((res) => {
        this.myInfo = res.myInfo;

        this.alreadyLoggedIn = res.status === 'success';
      });
    this.currentUrl = this.router.url;
  }

  onSwipeLeft($event) {
    this.navCtrl.setDirection('back', true, 'back');
    this.navCtrl.navigateBack('/tabs/settings');
  }

  onSwipeRight($event) {}

  ngOnInit() {
    this.loadSelectedTeacherDetails();
    this.activatedRoute.url.subscribe(() => {
      this.loadSelectedTeacherDetails();
    });
  }

  elementStatus(element) {
    return element.status;
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  loadSelectedTeacherDetails = () => {
    this.eventService
      .getMyEvents(this.searchString, this.page, null, null, this.myInfo._id)
      .subscribe((data) => {
        if (Object.keys(data).length === 0) {
          this.page -= 1;
        }
        if (this.teacherDetails.event) {
          this.teacherDetails = this.teacherDetails.concat(data);
        } else {
          this.teacherDetails = [].concat(data);
        }
        return data;
      });
  };

  doRefresh = (refresher) => {
    setTimeout(() => {
      this.loadSelectedTeacherDetails();
      refresher.complete();
    }, 1000);
  };
}
