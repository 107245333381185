import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { AppState } from 'src/app/store';
import {
	loadMyAppointmentsError,
	loadMyAppointmentsStart,
	loadMyAppointmentsSuccess,
	submitCommentError,
	submitCommentStart,
	submitCommentSuccess,
} from '../actions/myAppointments.action';

@Injectable()
export class MyAppointmentsEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private teacherService: TeacherService, // we will need this service for API calls

		private globalService: GlobalService,

		private translate: TranslateService,
		private store: Store<AppState>
	) {}

	loadMyAppointments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadMyAppointmentsStart),
			switchMap(() =>
				this.teacherService.getMyAppointments().pipe(
					map((res: any[]) => {
						return loadMyAppointmentsSuccess({
							payload: res,
						});
					}),
					catchError(() => of(loadMyAppointmentsError()))
				)
			)
		)
	);

	submitComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(submitCommentStart),
			switchMap((action) =>
				this.teacherService.submitComment(action.payload).pipe(
					map((res: any) => {
						this.globalService.toast(this.translate.instant('success'), 1500, 'middle');

						return submitCommentSuccess({
							payload: res,
						});
					}),
					catchError(() => of(submitCommentError()))
				)
			)
		)
	);
}
