import { createReducer, on } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { loadTeachersError, loadTeachersStart, loadTeachersSuccess, loadNextPageTeachersStart } from '../actions/teachers.action';
//状态名称
export const teachersKey = 'teachers';

//状态类型接口
export interface RequestParams {
	limit?: number;
	page?: number;

	category?: any;

	city?: any;

	ownerId?: any;

	reviewCount?: number;

	nickname?: string;

	introduction?: string;
}
export interface State {
	requestParams: RequestParams;
	data: any[];
	status: string;
	noMoreData: boolean;
}

// 初始状态
export const initialState: State = {
	requestParams: {
		limit: environment.perPage,
		page: 1,
	},
	data: [],
	status: 'idle',
	noMoreData: false,
};

//创建reducer函数
export const teachersReducer = createReducer(
	initialState,
	//保存 request params
	on(loadTeachersStart, (state, action) => {
		return {
			...state,
			status: 'started',
			requestParams: action.payload,
		};
	}),
	on(loadNextPageTeachersStart, (state) => {
		return {
			...state,
			status: 'started',
		};
	}),
	//保存结果
	on(loadTeachersSuccess, (state, action) => {
		return {
			...state,
			status: 'success',
			noMoreData: action.noMoreData,
			data: action.payload,
		};
	}),
	on(loadTeachersError, (state) => ({
		...state,
		status: 'fail',
	}))
);
