import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { JMessageService } from 'src/app/services/jMessage.service';
import { AppState } from 'src/app/store';
import { loadLogin, loadLoginError, loadLoginSuccess, tokenLoginStart } from '../actions/login.action';

@Injectable()
export class LoginEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private authService: AuthService, // we will need this service for API calls

		private globalService: GlobalService,

		private jMessage: JMessageService,
		private store: Store<AppState>
	) {}

	loadLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadLogin),
			//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
			switchMap((action) => {
				//只有当新的requestParams和存在于redux中的不同的时候，发送http Request
				return this.authService.login(action.validation).pipe(
					map((res) => {
						if (Array.isArray(res) && !res.length) {
							return loadLoginError();
						} else {
                            const newRes = {...res, ...action.validation}
							return loadLoginSuccess({ payload: newRes });
						}
					}),
					catchError(() => of(loadLoginError()))
				);
			})
		)
	);

	tokenLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(tokenLoginStart),
			//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
			switchMap((action) => {
				//只有当新的requestParams和存在于redux中的不同的时候，发送http Request
                return this.authService.getMe().pipe(
					map((res) => {
						return loadLoginSuccess({ payload: res });
					}),
					catchError(() => of(loadLoginError()))
				);
			})
		)
	);

	LoginSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(loadLoginSuccess),
				//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
				tap((action) => {
					const myInfo = action.payload;
					if (myInfo.token !== null) {
						if (!this.jMessage.JIM.isInit()) {
							console.log('notInit');
							this.jMessage.initJMessage().then(() => {
								this.jMessage.login(myInfo.id, myInfo.password).then(() => {
									//this.jMessage.JIM.getConversation();
									this.jMessage.onSyncConversation();
									console.log('getConversation');
									this.jMessage.onMsgReceive();
								});
							});
						} else {
							if (this.jMessage.JIM.isInit() && !this.jMessage.JIM.isLogin()) {
								console.log('notlogin');
								this.jMessage.login(myInfo.id, myInfo.password).then((data) => {
									console.log(data);
									// if (!data) {
									// 	this.jMessage.userReg(myInfo.id, myInfo.password, myInfo.nickname).then(() => {
									// 		this.jMessage.login(myInfo.id, myInfo.password).then(() => {
									// 			this.jMessage.onMsgReceive();
									// 			console.log('getConversation2');
									// 			//this.jMessage.JIM.getConversation();
									// 			this.jMessage.onSyncConversation();
									// 			// if (this.globalService.loading) {
									// 			// 	this.globalService.endLoading();
									// 			// }
									// 		});
									// 	});
									// } //如果登录失败就注册
									//this.jMessage.JIM.getConversation();
									this.jMessage.onSyncConversation();
									this.jMessage.onMsgReceive();
									console.log('getConversation3');
								});
							}
						}
					}

					//只有当新的requestParams和存在于redux中的不同的时候，发送http Request
					// return of('123').pipe(
					// 	map((res) => loadJmessageLoginSuccess({ payload: res })),
					// 	catchError(() => of(loadJmessageLoginError()))
					// );
				})
			),
		{ dispatch: false }
	);
}
