import { createAction, props } from '@ngrx/store';
import { RequestParams } from '../reducer/services.reducer';

export const loadServicesStart = createAction(
	'[Services] Load Services',
	props<{
		payload: RequestParams;
	}>()
);
export const loadNextPageServicesStart = createAction('[Services] Load Next Page Services');
export const loadServicesSuccess = createAction(
	'[Services] Load Services Success',
	props<{ payload: any[]; noMoreData: boolean }>()
);
export const loadServicesError = createAction('[Services] Load Services Error');
