import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';

@Injectable()
export class SystemEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private store: Store<AppState>,

		private platform: Platform
	) {}
}
