import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Globals } from '../globals';
import { AppState } from '../store';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
	providedIn: 'root',
})
export class LikeService {
	constructor(
		private http: HttpClient,
		private globalService: GlobalService,
		private translate: TranslateService,
		public authService: AuthService,
		public globals: Globals,
		private store: Store<AppState>
	) {}

	like(type, _id): Observable<any> {
		const likeInfo = {
			_id,
			type,
		};
		console.log(likeInfo);
		return this.http //这个接口的调用首先修改了user表里的例如likeBigevent的_id添加进去了，然后将event表里的那一项的likedBy添加进去了
			.post(environment.defaultURL + '/favorite/like', likeInfo)
			.map((data) => {
				this.globalService.toast(this.translate.instant('addedToFavorites'), 1500, 'top');
			});
	}

	unlike = (type, _id) => {
		const unlikeInfo = {
			_id,
			type,
		};
		return this.http.post(environment.defaultURL + '/favorite/unlike', unlikeInfo).map((data) => {
			this.globalService.toast(this.translate.instant('removedFromFavorites'), 1500, 'top');
		});
	};

	getLikes(subject) {
		return this.http.get(environment.defaultURL + '/favorite/favorite' + subject);
	}

	getLikedBy(subject) {
		return this.http.get(environment.defaultURL + '/favorite/favoritedBy');
	}
}
