import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { loadSystem } from 'src/app/store/actions/system.action';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { ChangeObserverService } from '../../../services/changeObserver.service';
import { GlobalService } from '../../../services/global.service';
import { ServiceService } from '../../../services/service.service';
import { TeacherService } from '../../../services/teacher.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-language-settings',
	templateUrl: './languageSettings.page.html',
	styleUrls: ['./languageSettings.page.scss'],
})
export class LanguageSettingsPage implements OnInit {
	id: string;
	password: string;
	param = 'world';
	certificates = [];
	categoryName = [];
	members: any;

	uploadedImg = { data: undefined };
	page = 1;
	searchString = {};
	currentUrl;
	language = 'cn';

	system$: Observable<any>;

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private store: Store<AppState>,

		private teacherService: TeacherService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public globals: Globals,
		public authService: AuthService,
		private userService: UserService,
		private changeObserver: ChangeObserverService,
		private globalService: GlobalService,
		public navCtrl: NavController,
		private _location: Location
	) {}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	save() {
		this.store.dispatch(loadSystem({ payload: { language: this.language } }));

		this.translate.use(this.language);
		this.globalService.toast(this.translate.instant('successfullyUpdated'), 1500, 'top');
		this._location.back();
	}

	ngOnInit() {
		this.system$ = this.store.select('system');
	}
}
