import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TeacherService } from 'src/app/services/teacher.service';
import { UserService } from 'src/app/services/user.service';
import { AppState } from 'src/app/store';
import { readConversation } from 'src/app/store/actions/conversations.action';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { ChangeObserverService } from '../../services/changeObserver.service';
import { GlobalService } from '../../services/global.service';
import { JMessageService } from '../../services/jMessage.service';

@Component({
	selector: 'app-conversation',
	templateUrl: './conversation.page.html',
	styleUrls: ['./conversation.page.scss'],
})
export class ConversationPage implements OnInit {
	// @ViewChild('content') private content: any;

	conversations = [];
	currentUrl;
	page = 1;
	category = null;
	orgId = null;

	conversations$: Observable<any>;

	login$: Observable<any>;
	login: any;

	conversation: any;

	constructor(
		private jMessage: JMessageService,
		public globals: Globals,
		public authService: AuthService,
		private globalService: GlobalService,

		private router: Router,
		private activatedRoute: ActivatedRoute,
		private changeObserver: ChangeObserverService,
		private teacherService: TeacherService,
		private navCtrl: NavController,
		private store: Store<AppState>,
		private userService: UserService
	) {
		this.currentUrl = this.router.url;
	}

	clickItem(id) {
		// this.store.dispatch(readConversation( {
		// 	id:this.login$.myInfo.id
		// }))
			//dispatch
			this.store.dispatch(
				readConversation({
					id,
					user: this.login.myInfo.id,
					needRead: 0,
				})
			);
	}

	formatTime(item) {
		return this.globalService.formatTime(item.showTime);
	}

	ngOnInit() {
		this.conversations$ = this.store.select('conversations');
		this.login$ = this.store.select('login');

		this.store.select('login').subscribe((res) => {
			this.login = res;
		});

		this.store.select('conversations').subscribe((res: any) => {
			this.conversation = res;
		});
		// debugger;

	

		//还是应该在add新的effect里面加上这个addAvatar
	}

	loadPeopleImage(event) {
		console.log('loadPeopleimage');
		event.target.src = '/assets/img/defaultImg.png';
	}

	//下拉刷新
	doRefresh(event) {
		// let obj = this
		// console.log('Begin async operation');
		// setTimeout(() => {
		//     obj.jMessage.onSyncConversation().then(() => event.target.complete())
		// }, 2000);
	}
}
