import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, AlertController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CalendarMode, Step } from 'ionic2-calendar/calendar';
import * as moment from 'moment';
import 'moment-timezone';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { ServiceService } from '../../../services/service.service';


interface btnAciton {
    text:string,
    role?:string,
    handler:()=>void
}

@Component({
	selector: 'app-my-schedule',
	templateUrl: './mySchedule.page.html',
	styleUrls: ['./mySchedule.page.scss'],
})
export class MySchedulePage implements OnInit {
	teacherId: string;
	password: string;
	param = 'world';
	mySchedule = [];
	// calendar = {
	//     mode: 'week',
	//     currentDate: new Date()
	// };
	viewTitle;
	eventSource = [];
	addedEventSource = [];
	deletedEventSource = [];
	changedCalendarSource = [];
	freeTime = [];
	alreadyLoggedIn = false;
	myInfo: any = {};

    classTime:number = 0;
    selectTime:string;

    isDisable:boolean = true;

	isToday: boolean;
	calendar = {
		mode: 'week' as CalendarMode,
		step: 30 as Step,
		currentDate: new Date(),
		dateFormatter: {
			formatMonthViewDay: (date: Date) => date.getDate().toString(),
			formatMonthViewDayHeader: (date: Date) => 'MonMH',
			formatMonthViewTitle: (date: Date) => 'testMT',
			formatWeekViewDayHeader: (date: Date) => 'MonWH',
			formatWeekViewTitle: (date: Date) => 'testWT',
			formatWeekViewHourColumn: (date: Date) => 'testWH',
			formatDayViewHourColumn: (date: Date) => 'testDH',
			formatDayViewTitle: (date: Date) => 'testDT',
		},
	};

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public authService: AuthService,
		private globalService: GlobalService,
		private alertCtrl: AlertController,
		private actionSheetController: ActionSheetController,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
        this.store
			.select('login')
			.pipe(first())
			.subscribe((res) => {
				this.myInfo = res.myInfo;

				this.alreadyLoggedIn = res.status === 'success';
			});
	}

	changeISOtoDate(data2) {
		const data3 = [];
		if (data2) {
			data2.forEach((element, index) => {
				if (element) {
					data3.push({
						_id: element._id,
						startTime: moment(element.startTime).toDate(),
						endTime: moment(element.endTime).toDate(),
					});
				}
			});
		}
		return data3;
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	async ngOnInit() {
        this.selectTime = this.translate.instant('Select class time');
        this.activatedRoute.url.pipe(
            switchMap(() => this.authService.getMe())
          ).subscribe((res) => {
            this.freeTime = this.changeISOtoDate(res.freeTime);
            this.eventSource = this.freeTime;
            this.alreadyLoggedIn = !!res;
        });
	}

	handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}

	getMySchedule() {
		return this.http.get<any>(environment.defaultURL + '/user/getMyCalendarAsTeacher').pipe(
			tap(() => this.globalService.toast(this.translate.instant('success'), 1500, 'top')),
			catchError(this.handleError('applyToJoinOrg', []))
		);
	}

	changeMode(mode) {
		this.calendar.mode = mode;
	}

	today() {
		this.calendar.currentDate = new Date();
	}

	onViewTitleChanged(title) {
		this.viewTitle = title;
	}

	onCalendarSelected(calendar) {}

	onEventSelected(event) {}

	onTimeSelected(ev) {
		if (this.calendar.mode === 'month') {
			if (this.myInfo.id) {
				this.createAlert1(ev);
			} else {
				this.globalService.toast(this.translate.instant('loginPlease'), 1500, 'top');
			}
		} else if (this.calendar.mode === 'week') {
			let alertType = 0;
			let theIndex;
			for (let i = 0; i < this.freeTime.length && alertType === 0; i++) {
				if (new Date(ev.selectedTime).getTime() === new Date(this.freeTime[i].startTime).getTime()) {
					alertType = 1;
					theIndex = i;
				}
			}
			if (alertType === 0) {
				if (this.myInfo.id) {
					this.createAlert2(ev);
				} else {
					alert('Please login');
				}
			} else if (alertType === 1) {
			}
		}
	}

    async presentActionSheet() {
        const headerMsg = this.translate.instant('Duration options');
        const cancel = this.translate.instant('Cancel');
        const timeValues = [15, 30, 60, 120];
        const buttons: btnAciton[]= timeValues.map((time) => ({
          text: time + this.translate.instant('Minutes'),
          handler: () => {
            this.handleTime(time);
          },
        }));
        buttons.push({
          text: cancel,
          role: 'cancel',
          handler: () => {},
        });
        const actionSheet = await this.actionSheetController.create({
          header: headerMsg,
          buttons: buttons,
        });
        await actionSheet.present();
    }
    handleTime(time:number){
        this.classTime = time
        this.selectTime = this.classTime + this.translate.instant ('Minutes')
        return this.selectTime
    }
	async createAlert1(ev) {
		const confirm = await this.alertCtrl.create({
			header: this.translate.instant('reservationTimeSet'),
			message: 'How many hours do you need?',
			buttons: [
				{
					text: 'choose specified hours',
					handler: () => {
						this.calendar.mode = 'week';
					},
				},
				{
					text: 'cancel',
					handler: () => {},
				},
			],
		});
		return await confirm.present();
	}

	async createAlert2(ev) {
        if (!this.classTime) {
            this.globalService.toast(this.translate.instant('Please select the length of each session first'), 1500, 'top');
            return;
        }
        const singleLessonLength = this.translate.instant('Current single lesson length is')
        const Min = this.translate.instant('Minutes')
        const reservationTimeSet = this.translate.instant('reservationTimeSet')
        const headerMsg = reservationTimeSet + singleLessonLength + this.classTime + Min

		const confirm = await this.alertCtrl.create({
			header: headerMsg,
			// message: this.translate.instant('reservationHours'),
			message: this.translate.instant('初始时间'+ev.selectedTime),
			inputs: [
                {
                    name: this.translate.instant('hours'),
                    type: 'text',
                    placeholder: this.translate.instant('inputHoursHere'),
                },
                {
                name: this.translate.instant('times'),
                type: 'text',
                placeholder: this.translate.instant('Repeat for n weeks'),
                },
            ],
			buttons: [
				{
					text: this.translate.instant('OK'),
					handler: (data) => {
						if (data) {
							let hours = Number(data[this.translate.instant('hours')]);
							let times = Number(data[this.translate.instant('times')]);

                            const isRight = hours * 60 % this.classTime === 0

                            if (isRight) {
                                this.createCalendars(ev, hours,times);
                                return null
                            }

							this.globalService.toast(this.translate.instant('Please enter the correct number of hours'),1500,'top')
						}
					},
				},
				{
					text: this.translate.instant('cancel'),
					handler: () => {},
				},
			],
		});
		return await confirm.present();
	}

	createCalendars(ev, h: number , times) {

        if(!h && !times) return
        else this.isDisable = false
        
		if (h === 99999) {
			this.freeTime.forEach((elementEvent, index) => {
				if (new Date(ev.selectedTime).getTime() === new Date(elementEvent.startTime).getTime()) {
					this.freeTime.splice(index, 1);
					this.eventSource.splice(index, 1);
					let flag = 1;
					if (this.addedEventSource.length > 0) {
						this.addedEventSource.forEach((addedElementEvent, addedIndex) => {
							if (addedElementEvent._id === elementEvent._id) {
								this.addedEventSource.splice(addedIndex, 1);
								flag = 0;
							}

							if (addedIndex === this.addedEventSource.length - 1) {
								if (flag === 1) {
									this.deletedEventSource.push({
										_id: elementEvent._id,
										action: 'delete',
										startTime: elementEvent.startTime,
										endTime: elementEvent.endTime,
									});
								}
							}
						});
					} else {
						this.deletedEventSource.push({
							_id: elementEvent._id,
							action: 'delete',
							startTime: elementEvent.startTime,
							endTime: elementEvent.endTime,
						});
					}
				}
			});
			this.eventSource = [].concat(this.eventSource);
		} else if (h > 168) {
		} else {
			const date = ev.selectedTime;
			const startTime = new Date(ev.selectedTime);
			startTime.setHours(startTime.getHours());

			const endTime = new Date(ev.selectedTime);
			endTime.setHours(endTime.getHours() + h,h%1*60);
			for (let weeknum = 0; weeknum < times; weeknum++) {
				let startdate = new Date(startTime);
				startdate.setDate(startdate.getDate() + weeknum * 7)
				let enddate = new Date(endTime);
				enddate.setDate(enddate.getDate() + weeknum * 7)
				this.addedEventSource.push({
					startTime: startdate,
					endTime: enddate,
					action: 'put',
				});
				this.eventSource = this.eventSource.concat([
					{
						startTime:startdate,
						endTime:enddate,
					},
				]);
			}
			// this.addedEventSource.push({
			// 	startTime,
			// 	endTime,
			// 	action: 'put',
			// });
			// this.eventSource = this.eventSource.concat([
			// 	{
			// 		startTime,
			// 		endTime,
			// 	},
			// ]);
		}
	}

	onCurrentDateChanged(calendar: Date) {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		//event.setHours(0, 0, 0, 0);
		//this.isToday = today.getTime() === event.getTime();
	}

	onRangeChanged(ev) {}

	setReservationDetails() {

		this.changedCalendarSource = this.addedEventSource.concat(this.deletedEventSource);
		const navigationExtras: NavigationExtras = {
			queryParams: {
				changedSchedule: JSON.stringify(this.changedCalendarSource),
                selectedTime:this.classTime
			},
		};
		this.navCtrl.navigateForward(['tabs/settings/mySchedule/updateSchedule'], navigationExtras);
	}

	// doRefresh(refresher) {
	//
	//
	//     if (this.alreadyLoggedIn === true) {
	//
	//         setTimeout(() => {
	//
	//             this.userProvider.myCalendarAsTeacher(this.myInfo)
	//                 .then(data2 => {
	//                     var data3 = []
	//                     data3 = this.changeISOtoDate(data2)
	//
	//                     if (data3.length) {
	//                         if (!this.myInfo.freeTime) this.myInfo.freeTime = []
	//                         var data4 = this.changeISOtoDate(this.myInfo.freeTime)
	//                         this.freeTime = data4
	//                         this.eventSource = data4.concat(data3)
	//                     }
	//                 })
	//             refresher.complete();
	//         }, 1000);
	//     } else {
	//
	//         setTimeout(() => {
	//             this.userProvider.alert('', 'Please Login!', ['OK'], this.alertCtrl).present()
	//             refresher.complete();
	//         }, 1000);
	//     }
	// }
}
