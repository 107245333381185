import { createAction, props } from '@ngrx/store';
import { RequestParams } from '../reducer/videos.reducer';

export const loadVideosStart = createAction(
	'[Videos] Load Videos',
	props<{
		payload: RequestParams;
	}>()
);
export const loadNextPageVideosStart = createAction('[Videos] Load Next Page Videos');
export const loadVideosSuccess = createAction(
	'[Videos] Load Videos Success',
	props<{ payload: any[]; noMoreData: boolean }>()
);
export const loadVideosError = createAction('[Videos] Load Videos Error');
