import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SharedvideolistComponent } from 'src/app/components/sharedvideolist/sharedvideolist.component';
import { Globals } from 'src/app/globals';
import { AppState } from 'src/app/store';
import { loadNextPageVideosStart, loadVideosStart } from 'src/app/store/actions/videos.action';
import { SearchmodalPage } from '../../components/searchmodal/searchmodal.page';
import { TeacherService } from '../../services/teacher.service';
import { VideoService } from '../../services/video.service';

@Component({
	selector: 'app-video',
	templateUrl: './video.page.html',
	styleUrls: ['./video.page.scss'],
})
export class VideoPage implements OnInit {
	@ViewChild(SharedvideolistComponent, { static: true })
	sharedvideolistComponent: SharedvideolistComponent;

	public menu1: any = [];
	public menu2: any = [];
	public menu3 = [];
	public menu4 = [];
	public grids = [];
	public videos = [];

	category = null;
	subCategory = null;
	id: boolean = undefined;
	certificates = [];
	keyword = null;
	videoLoaded = false;
	orgId = null;
	currentUrl;
	searchString = {};

	headers = ['id', 'introduction', 'reviewNumber', 'category', 'likedCount'];
	stringTypeHeaders = ['id', 'introduction', 'category'];
	numberTypeHeaders = ['reviewNumber', 'likedCount'];

	insertValues = {
		id: '',
		introduction: '',
		organization: '',
		age: '',
		reviewNumber: '',
		category: '',
		likedCount: '',
		certificates: '',
		page: 1,
	};
	page = 1;

	videos$: Observable<any>;

	constructor(
		private http: HttpClient,
		private teacherService: TeacherService,
		private videoService: VideoService,
		private translate: TranslateService,
		private router: Router,
		private store: Store<AppState>,
		public modalController: ModalController,
		public globals: Globals
	) {
		this.currentUrl = this.router.url;
	}

	ngOnInit() {
		this.videos$ = this.store.select('videos').pipe(
			tap((res) => {
				for (const key in res.requestParams) {
						if (res.requestParams[key] != null) {
							this.insertValues[key] = res.requestParams[key];
						}
					}
				})
		);
	}

	getVideos() {
		this.store.dispatch(
			loadVideosStart({
				payload: {
					page: this.page,
					category: this.globals.videoCategory,
					city: this.globals.videoCity,
					ownerId: this.orgId,
				},
			})
		);
	}
	loadData(event) {
		//到达底部了， dispatch一下
		this.store.dispatch(loadNextPageVideosStart());
		event.target.complete();
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: SearchmodalPage,
			componentProps: {
				headers: this.headers,
				numberTypeHeaders: this.numberTypeHeaders,
				stringTypeHeaders: this.stringTypeHeaders,
			},
		});

		modal.onDidDismiss().then((dataReturned) => {
			if (dataReturned !== null) {
				console.log(dataReturned.data);
				this.searchString = dataReturned.data;
				this.store.dispatch(
					loadVideosStart({
						payload: {
							page: this.page,
							category: dataReturned.data.category,
							city: dataReturned.data.city,
							ownerId: this.orgId,
						},
					})
				);
			}
		});

		return await modal.present();
	}
}
