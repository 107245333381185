import { createAction, props } from '@ngrx/store';
import { RequestParams } from '../reducer/orgs.reducer';

export const loadOrgsStart = createAction(
	'[Orgs] Load Orgs',
	props<{
		payload: RequestParams;
	}>()
);
export const loadNextPageOrgsStart = createAction('[Orgs] Load Next Page Orgs');
export const loadOrgsSuccess = createAction(
	'[Orgs] Load Orgs Success',
	props<{ payload: any[]; noMoreData: boolean }>()
);
export const loadOrgsError = createAction('[Orgs] Load Orgs Error');
