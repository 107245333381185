import { createAction, props } from '@ngrx/store';

export const loadMyAppointmentsStart = createAction('[MyAppointments] Load MyAppointments');
export const loadMyAppointmentsSuccess = createAction(
	'[MyAppointments] Load MyAppointments Success',
	props<{ payload: any[] }>()
);
export const loadMyAppointmentsError = createAction('[MyAppointments] Load MyAppointments Error');

export const submitCommentStart = createAction('[MyAppointments] Submit Comment Start', props<{ payload: any }>());
export const submitCommentSuccess = createAction('[MyAppointments] Submit Comment Success', props<{ payload: any }>());
export const submitCommentError = createAction('[MyAppointments] Submit Comment Error');
