// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // https://www.med-healthcare.com   http://localhost:3000
  defaultURL: 'https://www.dagongxin.cn',
  // defaultURL: 'http://192.168.1.102',
  // defaultURL: 'http://localhost:3000',
  // defaultURL: 'http://localhost',
  // defaultURL: 'http://42.192.44.66:8100',

  // defaultURL: 'http://42.192.44.66',
  // defaultURL: 'http://172.16.210.23',
  defaultLanguage: 'zh',
  perPage: 20,
  availableLanguages: [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'ja',
      name: 'Japanese'
    },
    {
      code: 'zh',
      name: 'Chinese'
    }
  ],
  sysOptions: {
    systemLanguage: 'en'
  }
  // export const environment.defaultURL = 'https://dagongxin.cn';
  // export const environment.defaultURL = 'http://42.192.44.66';
};

// export const environment.defaultURL = 'http://localhost:3001';

// export const socketURL = 'http://47.74.18.131:3001'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
