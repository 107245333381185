import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { Globals } from '../../../../globals';
import { CityService } from '../../../../services/city.service';
import { ImageProcessService } from '../../../../services/imageProcess.service';
import { VideoService } from '../../../../services/video.service';
import { AuthService } from './../../../../services/auth.service';
import { UtilService } from './../../../../services/util.service';
import { GlobalService } from './../../../../services/global.service';
import { SettingsService } from './../../../../services/settings.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-new-video',
  templateUrl: './newVideo.page.html',
  styleUrls: ['./newVideo.page.scss'],
})
export class NewVideoPage implements OnInit {
  @ViewChild('imageButton', { static: false }) selectImage;
  @ViewChild('faceImageButton', { static: false }) selectFaceImage;

  teacherId: string;
  password: string;
  category = '全部';
  categoryData = [];

  video: any = {
    video: {
      video: {
        URL: '',
      },
      category: '',
    },
    owner: {},
  };
  videoId;
  uploadedImg = { data: undefined };
  f = {
    api_key: '0ef14fa726ce34d820c5a44e57fef470',
    api_id: undefined,
    api_secret: '4Y9YXOMSDvqu1Ompn9NSpNwWQFHs1hYD',
    img: undefined,
    face_id: undefined,
    category: undefined,
  };
  year: number;
  month: number;
  day: number;
  buttonDisabled = false;
  selected1;

  previousUrl;
  currentUrl;
  deviceTypeIsIpad = false;
  alreadyLoggedIn = false;
  myInfo: any = {};

  constructor(
    private http: HttpClient,
    private cityService: CityService,
    private utilService: UtilService,

    private videoService: VideoService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private globalService: GlobalService,
    private settingsService: SettingsService,
    public platform: Platform,
    public globals: Globals,
    private imageProcess: ImageProcessService,
    public navCtrl: NavController,
    private _location: Location,
    private store: Store<AppState>
  ) {
    // this.myInfo = globals.myInfo;
    // this.alreadyLoggedIn = globals.alreadyLoggedIn;

    this.store
      .pipe(select('login'))
      .pipe(first())
      .subscribe((res) => {
        this.myInfo = res.myInfo;

        this.alreadyLoggedIn = res.status === 'success';
      });
    if (this.platform.is('ipad')) {
      this.deviceTypeIsIpad = true;
    }
    this.previousUrl =
      this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
      this.activatedRoute.snapshot.paramMap.get('p') === null
        ? '/tabs/settings'
        : this.activatedRoute.snapshot.paramMap.get('p');
  }
  
  onCategoryOk(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    const myString = value.map((v) => v).join(',');
    this.category = myString.includes(',全部')
      ? myString.substring(0, myString.indexOf(',全部'))
      : myString;
    this.video.category = this.category;
  }
  onSwipeLeft($event) {
    this.navCtrl.setDirection('back', true, 'back');
    this._location.back();
  }

  onSwipeRight($event) {}

  ngOnInit() {
    try {
        this.activatedRoute.url.subscribe(async () => {
            [this.categoryData] = await this.utilService.changeLanguage();
            [, this.category] = this.utilService.setAlias();
        });
      } catch (err) {
        console.error(err);
    }
  }

  placeholderValue() {
    if (this.video.category) {
    } else {
      return null;
    }
  }

  replaceVideo() {
    this.buttonDisabled = true;

    if (this.video.id && this.video.price) {
      this.video.action = 'put';
      const paymentInfo = JSON.parse(JSON.stringify(this.video));
      paymentInfo.price *= 100;
      paymentInfo.priceBeforeDiscount *= 100;
      const withoutImageURL = JSON.parse(JSON.stringify(paymentInfo));

      if (withoutImageURL.imageKey) {
        withoutImageURL.imageURL = '';
      }

      this.videoService.postVideo(withoutImageURL).subscribe(
        (data) => {
          this.globalService.toast(
            this.translate.instant('success'),
            1500,
            'top'
          );
          this.buttonDisabled = false;
          this.navCtrl.navigateBack(this.previousUrl);
        },
        (err) => {
          if (err.error.errorCode === 2002) {
            this.globalService.toast(
              this.translate.instant('videoNameDuplicate'),
              1500,
              'top'
            );
          }
          this.buttonDisabled = false;
        }
      );
    } else {
      this.globalService.toast(
        this.translate.instant(
          'Please login first, then input name, start time, end time and userNumberLimit!'
        ),
        1500,
        'top'
      );
      this.buttonDisabled = false;
    }
  }

  uploadImageTrigger() {
    this.imageProcess.uploadToQiniu('uploadImage', '').then((data) => {
      this.video.imageKey = data.imageKey;
      this.video.imageURL = data.imageURL;
    });
  }
}
