import { createAction, props } from '@ngrx/store';

export const likeStart = createAction(
	'[Likes] like Start',
	props<{ likeType: string; _id: string }>()
);

export const likeSuccess = createAction('[Likes] like Success');

export const likeError = createAction('[Likes] like Error');

export const unlikeStart = createAction(
	'[unLikes] unlike Start',
	props<{ unlikeType: string; _id: string }>()
);
