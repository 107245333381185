import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { loadLogin, LogOut } from 'src/app/store/actions/login.action';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { JMessageService } from '../../services/jMessage.service';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.page.html',
	styleUrls: ['./settings.page.scss'],
})
//implements OnInit
export class SettingsPage implements OnInit {
	myPoints;
	validation: any = {
		id: '',
		password: '',
	};
	_id;

    currentUrl;

	loginState$: Observable<any>;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store<AppState>,

		public navCtrl: NavController,
		public authService: AuthService,
		public jMessage: JMessageService,

		public globals: Globals,
		private globalService: GlobalService,
        private translate: TranslateService,
		public toastController: ToastController
	) {
        this.currentUrl = this.router.url;
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params && params.redirect) {
				this.navCtrl.navigateForward(params.redirect);
			}
		});
	}

	login(form) {
		const obj = this;
		const validation = form.value;
        if (validation.id === '' || validation.password === '') {
            this.globalService.toast(this.translate.instant('id,password are needed.'), 1000, 'top');
            return;
        }
		this.store.dispatch(loadLogin({ validation, token: null, myInfo: {} }));
	}

	logout() {
		this.jMessage.JIM.loginOut();
		this.store.dispatch(LogOut()); //清除token
	}

	register() {}

	async presentToast(msg) {
		const toast = await this.toastController.create({
			message: msg,
			duration: 2000,
			position: 'top',
		});
		toast.present();
	}

	gotoPayment() {
		this.router.navigate(['payment']);
	}

	ngOnInit() {
		this.loginState$ = this.store.select('login');
	}
}
