import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './components/chat/chat.component';
import { ReviewPage } from './components/review/review.page';
import { SearchmodalPage } from './components/searchmodal/searchmodal.page';
import { ConversationPage } from './tabs/conversation/conversation.page';
import { EventPage } from './tabs/event/event.page';
import { EventdetailsPage } from './tabs/event/eventdetails/eventdetails.page';
import { EventpayPage } from './tabs/event/eventdetails/eventpay/eventpay.page';
import { OrgPage } from './tabs/org/org.page';
import { OrgdetailsPage } from './tabs/org/orgdetails/orgdetails.page';
import { OrgteachersPage } from './tabs/org/orgdetails/orgteachers/orgteachers.page';
import { ServicePage } from './tabs/service/service.page';
import { MakereservationPage } from './tabs/service/servicedetails/makereservation/makereservation.page';
import { ReservationdetailsPage } from './tabs/service/servicedetails/makereservation/reservationdetails/reservationdetails.page';
import { ServicedetailsPage } from './tabs/service/servicedetails/servicedetails.page';
import { ApplyForOrgManagerPage } from './tabs/settings/apply-for-orgManager/apply-for-orgManager.page';
import { ApplyForProPage } from './tabs/settings/apply-for-pro/apply-for-pro.page';
import { ApplyForSecretaryPage } from './tabs/settings/apply-for-secretary/apply-for-secretary.page';
import { ApproveOrgPage } from './tabs/settings/approveOrg/approveOrg.page';
import { ApproveOrgManagerPage } from './tabs/settings/approveOrgManager/approveOrgManager.page';
import { ApproveProPage } from './tabs/settings/approvePro/approvePro.page';
import { ApproveSecPage } from './tabs/settings/approveSec/approveSec.page';
import { LanguageSettingsPage } from './tabs/settings/languageSettings/languageSettings.page';
import { CreateOrgPage } from './tabs/settings/manageOrg/createOrg/createOrg.page';
import { ManageOrgPage } from './tabs/settings/manageOrg/manageOrg.page';
import { ManageOrgMemberPage } from './tabs/settings/manageOrg/manageOrgMembers/manageOrgMember/manageOrgMember.page';
import { ManageOrgMembersPage } from './tabs/settings/manageOrg/manageOrgMembers/manageOrgMembers.page';
import { ReviewOrgMemberApplicationPage } from './tabs/settings/manageOrg/manageOrgMembers/reviewOrgMemberApplication/reviewOrgMemberApplication.page';
import { UpdateOrgInfoPage } from './tabs/settings/manageOrg/updateOrgInfo/updateOrgInfo.page';
import { CommentPage } from './tabs/settings/myAppointments/comment/comment.page';
import { MyAppointmentsPage } from './tabs/settings/myAppointments/myAppointments.page';
import { MyCalendarAsUserPage } from './tabs/settings/myCalendarAsUser/myCalendarAsUser.page';
import { MyEventsPage } from './tabs/settings/myEvents/myEvents.page';
import { NewEventPage } from './tabs/settings/myEvents/newEvent/newEvent.page';
import { UpdateEventPage } from './tabs/settings/myEvents/updateEvent/updateEvent.page';
import { ChangePasswordPage } from './tabs/settings/myInformation/changePassword/changePassword.page';
import { JoinOrgPage } from './tabs/settings/myInformation/joinOrg/joinOrg.page';
import { MyInformationPage } from './tabs/settings/myInformation/myInformation.page';
import { MyInformationChangePage } from './tabs/settings/myInformation/myInformationChange/myInformationChange.page';
import { MyLikesPage } from './tabs/settings/myLikes/myLikes.page';
import { MyPointsPage } from './tabs/settings/myPoints/myPoints.page';
import { StripeChargePage } from './tabs/settings/myPoints/stripeCharge/stripeCharge.page';
import { MySchedulePage } from './tabs/settings/mySchedule/mySchedule.page';
import { UpdateSchedulePage } from './tabs/settings/mySchedule/updateSchedule/updateSchedule.page';
import { MyServicesPage } from './tabs/settings/myServices/myServices.page';
import { NewServicePage } from './tabs/settings/myServices/newService/newService.page';
import { UpdateServicePage } from './tabs/settings/myServices/updateService/updateService.page';
import { MyVideosPage } from './tabs/settings/myVideos/myVideos.page';
import { NewVideoPage } from './tabs/settings/myVideos/newVideo/newVideo.page';
import { UpdateVideoPage } from './tabs/settings/myVideos/updateVideo/updateVideo.page';
import { SettingsPage } from './tabs/settings/settings.page';
import { SignUpPage } from './tabs/settings/signUp/signUp.page';
import { RetrievePasswordPage } from './tabs/settings/retrievePassword/retrievePassword.page';
import { UserAgreementPage } from './tabs/settings/userAgreement/userAgreement.page';
import { TabsPage } from './tabs/tabs.page';
import { TeacherPage } from './tabs/teacher/teacher.page';
import { Teacher3ServicesPage } from './tabs/teacher/teacherdetails/teacher3Services/teacher3Services.page';
import { TeacherCertificatePage } from './tabs/teacher/teacherdetails/teacherCertificate/teacherCertificate.page';
import { TeacherdetailsPage } from './tabs/teacher/teacherdetails/teacherdetails.page';
import { VideoPage } from './tabs/video/video.page';
import { VideodetailsPage } from './tabs/video/videodetails/videodetails.page';


const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'tabs',
				component: TabsPage,
				children: [
					{
						path: 'teacher',
						children: [
							{
								path: '',
								component: TeacherPage,
							},
							{
								path: 'component',
								component: SearchmodalPage,
							},

							{
								path: 'teacherdetails/:myId',
								children: [
									{
										path: '',
										component: TeacherdetailsPage,
									},
									{
										path: 'org/:orgId',
										component: OrgdetailsPage,
									},
									{
										path: 'review/:reservationId',
										component: ReviewPage,
									},
									{
										path: 'chat/:targetId/:targetName/:targetUuid/:targetImage',
										component: ChatComponent,
									},
									{
										path: 'teacher3Services/:serviceType',
										children: [
											{
												path: '',
												component: Teacher3ServicesPage,
											},
											// {path: 'event/:myId', component: EventdetailsPage},
											// {path: 'video/:myId', component: VideodetailsPage},
											// {path: 'service/:myId', component: ServicedetailsPage},
										],
									},
									{
										path: 'teacherCertificate',
										children: [
											{
												path: '',
												component:
													TeacherCertificatePage,
											},
										],
									},
								],
							},
						],
					},
					{
						path: 'org',
						children: [
							{
								path: '',
								component: OrgPage,
							},
							{
								path: 'component',
								component: SearchmodalPage,
							},

							{
								path: 'orgdetails/:orgId',
								children: [
									{
										path: '',
										component: OrgdetailsPage,
									},
									{
										path: 'review/:reservationId',
										component: ReviewPage,
									},
									{
										path: 'orgteachers',
										component: OrgteachersPage,
									},
								],
							},
						],
					},
					{
						path: 'video',
						children: [
							{
								path: '',
								component: VideoPage,
							},
							{
								path: 'component',
								component: SearchmodalPage,
							},

							{
								path: 'videodetails/:myId',
								children: [
									{
										path: '',
										component: VideodetailsPage,
									},
									{
										path: 'review/:reservationId',
										component: ReviewPage,
									},
								],
							},
						],
					},
					{
						path: 'service',
						children: [
							{
								path: '',
								component: ServicePage,
							},
							{
								path: 'component',
								component: SearchmodalPage,
							},

							{
								path: 'servicedetails/:myId',
								children: [
									{
										path: '',
										component: ServicedetailsPage,
									},
									{
										path: 'review/:reservationId',
										component: ReviewPage,
									},
									{
										path: 'makereservation',
										children: [
											{
												path: '',
												component: MakereservationPage,
											},
											{
												path: 'reservationdetails',
												component:
													ReservationdetailsPage,
											},
										],
									},
								],
							},
						],
					},
					{
						path: 'event',
						children: [
							{
								path: '',
								component: EventPage,
							},
							{
								path: 'component',
								component: SearchmodalPage,
							},

							{
								path: 'eventdetails/:myId',
								children: [
									{
										path: '',
										component: EventdetailsPage,
									},
									{
										path: 'review/:reservationId',
										component: ReviewPage,
									},
									{
										path: 'eventpay',
										component: EventpayPage,
									},
								],
							},
						],
					},
					{
						path: 'conversation',
						children: [
							{
								path: '',
								component: ConversationPage,
							},
							{
								path: 'component',
								component: SearchmodalPage,
							},
							{
								path: 'conversationdetails/:targetId/:targetName/:targetUuid',
								children: [
									{
										path: '',
										component: ChatComponent,
									},
								],
							},
						],
					},
					{
						path: 'settings',
						children: [
							{
								path: '',
								component: SettingsPage,
							},
							{
								path: 'myInformation',
								children: [
									{
										path: '',
										component: MyInformationPage,
									},
									{
										path: 'myInformationChange/:itemName',
										component: MyInformationChangePage,
									},
									{
										path: 'joinOrg/:itemName',
										component: JoinOrgPage,
									},
									{
										path: 'changePassword',
										component: ChangePasswordPage,
									},
								],
							},
							{
								path: 'approvePro',
								children: [
									{
										path: '',
										component: ApproveProPage,
									},
								],
							},
							{
								path: 'approveOrgManager',
								children: [
									{
										path: '',
										component: ApproveOrgManagerPage,
									},
								],
							},
							{
								path: 'approveOrg',
								children: [
									{
										path: '',
										component: ApproveOrgPage,
									},
								],
							},
							{
								path: 'approveSec',
								children: [
									{
										path: '',
										component: ApproveSecPage,
									},
								],
							},
							{
								path: 'mySchedule',
								children: [
									{
										path: '',
										component: MySchedulePage,
									},
									{
										path: 'updateSchedule',
										component: UpdateSchedulePage,
									},
								],
							},
							{
								path: 'myAppointments/:myId',
								children: [
									{
										path: '',
										component: MyAppointmentsPage,
									},
									{
										path: 'comment',
										component: CommentPage,
									},
                                    {
                                        path: 'chat/:targetId/:targetName/:targetUuid/:targetImage',
                                        component: ChatComponent,
                                    }
								]
							},
							{
								path: 'myServices',
								children: [
									{
										path: '',
										component: MyServicesPage,
									},
									{
										path: 'newService',
										component: NewServicePage,
									},
									{
										path: 'updateService/:myId',
										component: UpdateServicePage,
									},
								],
							},
							{
								path: 'myVideos',
								children: [
									{
										path: '',
										component: MyVideosPage,
									},
									{
										path: 'newVideo',
										component: NewVideoPage,
									},
									{
										path: 'updateVideo/:myId',
										component: UpdateVideoPage,
									},
								],
							},
							{
								path: 'myEvents',
								children: [
									{
										path: '',
										component: MyEventsPage,
									},
									{
										path: 'newEvent',
										component: NewEventPage,
									},
									{
										path: 'updateEvent/:myId',
										component: UpdateEventPage,
									},
								],
							},
							{
								path: 'myCalendarAsUser',
								children: [
									{
										path: '',
										component: MyCalendarAsUserPage,
									},
									{
										path: 'reviewReservation/:reservationId',
										component: ReviewPage,
									},
								],
							},
							{ path: 'myLikes', component: MyLikesPage },
							{
								path: 'myPoints',
								children: [
									{
										path: '',
										component: MyPointsPage,
									},
									{
										path: 'stripeCharge',
										component: StripeChargePage,
									},
								],
							},
							{ path: 'signUp', component: SignUpPage },
							{ path: 'retrievePassword', component: RetrievePasswordPage },
							{
								path: 'userAgreement',
								component: UserAgreementPage,
							},
							{ path: 'applyForPro', component: ApplyForProPage },
							{ path: 'applyForOrgManager', component: ApplyForOrgManagerPage },
							{
								path: 'manageOrg',
								children: [
									{
										path: '',
										component: ManageOrgPage,
									},
									{
										path: 'createOrg',
										component: CreateOrgPage,
									},
									{
										path: ':org_id/updateOrgInfo',
										children: [
											{
												path: '',
												component: UpdateOrgInfoPage,
											},
											{
												path: 'manageOrgMembers',
												children: [
													{
														path: '',
														component:
															ManageOrgMembersPage,
													},
													{
														path: 'manageOrgMember/:_id',
														component:
															ManageOrgMemberPage,
													},
													{
														path: 'reviewOrgMemberApplication/:_id',
														component:
															ReviewOrgMemberApplicationPage,
													},
												],
											},
										],
									},
								],
							},
							{
								path: 'languageSettings',
								component: LanguageSettingsPage,
							},
							{
								path: 'applyForSecretary',
								component: ApplyForSecretaryPage,
							},
						],
					},
					{
						path: '',
						redirectTo: '/tabs/teacher',
						pathMatch: 'full',
					},
				],
			},
			{
				path: '',
				redirectTo: '/tabs/settings',
				pathMatch: 'full',
			},
		],
	}
];

@NgModule({	
	imports: [
		RouterModule.forRoot(routes, { useHash: true  , preloadingStrategy: PreloadAllModules ,enableTracing: true }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
