import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { EventService } from '../../services/event.service';
import { GlobalService } from '../../services/global.service';
import { LikeService } from '../../services/like.service';
import { LikeComponent } from '../like/like.component';

@Component({
	selector: 'app-shared-event-list',
	templateUrl: 'sharedeventlist.component.html',
})
export class SharedeventlistComponent implements OnInit {
	@ViewChild(IonInfiniteScroll, { static: true })
	likeComponent: LikeComponent;
	infiniteScroll: IonInfiniteScroll;

	@Input() searchString: any;

	@Input() orgId: any;

	@Input() events: any;

	@Input() myInfo: any;

	page = 1;

	noMoreData = false;
	currentUrl;
	alreadyLoggedIn = false;

	constructor(
		private router: Router,
		private eventService: EventService,
		private globalService: GlobalService,
		private likeService: LikeService,
		public authService: AuthService,
		public globals: Globals,
		private translate: TranslateService,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.doit()
		console.log(this.events);

		this.currentUrl = this.router.url;
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		const n = this.currentUrl.split('/').length;
		if (n > 3) {
			this._location.back();
		}
	}

	onSwipeRight($event) {}

	// async doit(){
	//   await this.events
	//   console.log(this.events)
	// }
	ngOnInit() {}

	loadData(event) {}

	toggleInfiniteScroll() {
		this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
	}
}
