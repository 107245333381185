import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { environment } from '../../../environments/environment';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
@Component({
	selector: 'app-review',
	templateUrl: './review.page.html',
	styleUrls: ['./review.page.scss'],
})
export class ReviewPage implements OnInit {
	showReviewBox = false;
	showReviewButton = false;
	reviewObjectId = undefined;
	previousUrl;
	currentUrl;
	serviceType = undefined;
	reservation_id;
	reviewData = {
		discussion_id: '',
		parent_id: '',
		created: '',
		rate: 5,
		text: '',
		reservation_id: '',
	};
	rate = 5;
	// review={};
	reviewText = null;
	hasReviewWithId;
	alreadyLoggedIn = false;
	myInfo: any = {};

	constructor(
		public authService: AuthService,
		private globalService: GlobalService,
		private activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private translate: TranslateService,

		private store: Store<AppState>,
		public globals: Globals
	) {
		this.store.select('login').subscribe((res) => {
			this.myInfo = res.myInfo;
			res.status === 'success'
				? (this.alreadyLoggedIn = true)
				: (this.alreadyLoggedIn = false);
		});
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		const parsedParam = this.activatedRoute.snapshot.paramMap;
		this.reviewObjectId = parsedParam.get('reviewObjectId');
		this.serviceType = parsedParam.get('serviceType');
		this.reservation_id = parsedParam.get('reservationId');
		this.previousUrl =
			parsedParam.get('p') === 'undefined' ||
			parsedParam.get('p') === null
				? '/tabs/settings'
				: parsedParam.get('p');
	}

	ngOnInit() {
		this.http
			.get<any>(
				environment.defaultURL +
					'/reviewByReservationId?_id=' +
					this.reservation_id
			)
			.subscribe(
				(data) => {
					if (data && data._id) {
						this.hasReviewWithId = data._id;
					}
					this.reviewText = data.text;
					this.rate = data.rate;
				},
				(err) => {
					console.log(err);
				}
			);
	}

	writeReview() {
		if (!this.hasReviewWithId) {
			const reviewBody = {
				review: {
					discussion_id: this.reviewObjectId,
					// parent_id : this.reviewData?.parent_id,
					reservation_id: this.reservation_id,
					rate: this.rate,
					text: this.reviewText,
				},
			};

			this.http
				.post(environment.defaultURL + '/reviewAndPay', reviewBody)
				.subscribe(
					(data) => {
						this.globalService.toast(
							this.translate.instant('reviewed'),
							1500,
							'top'
						);
						this.reviewData.text = null;
						this.showReviewBox = !this.showReviewBox;
					},
					(err) => {
						console.log(err);
					}
				);
		} else {
			const reviewBody = {
				review: {
					// parent_id : this.reviewData?.parent_id,
					_id: this.hasReviewWithId,
					rate: this.rate,
					text: this.reviewText,
				},
			};

			this.http
				.put(environment.defaultURL + '/review', reviewBody)
				.subscribe(
					(data) => {
						this.globalService.toast(
							this.translate.instant('reviewed'),
							1500,
							'top'
						);
						this.reviewData.text = null;
						this.showReviewBox = !this.showReviewBox;
					},
					(err) => {
						console.log(err);
					}
				);
		}
	}
}
