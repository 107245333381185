import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { loadManageOrgsStart } from 'src/app/store/actions/manageOrgs.action';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { ChangeObserverService } from '../../../services/changeObserver.service';
import { GlobalService } from '../../../services/global.service';
import { OrgService } from '../../../services/org.service';
import { ServiceService } from '../../../services/service.service';
import { TeacherService } from '../../../services/teacher.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-manage-org',
  templateUrl: './manageOrg.page.html',
  styleUrls: ['./manageOrg.page.scss'],
})
export class ManageOrgPage implements OnInit {
  id: string;
  password: string;
  param = 'world';
  certificates = [];
  categoryName = [];
  members: any;

  uploadedImg = { data: undefined };
  page = 1;
  searchString = {};
  currentUrl;

  constructor(
    private http: HttpClient,
    private serviceService: ServiceService,
    private teacherService: TeacherService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private sanitizer: DomSanitizer,
    public globals: Globals,
    public authService: AuthService,
    private userService: UserService,
    private orgService: OrgService,
    private store: Store<AppState>,

    private changeObserver: ChangeObserverService,
    private globalService: GlobalService,
    public navCtrl: NavController,
    private _location: Location
  ) {
    this.store.select('manageOrgs').subscribe((res: any) => {
      this.members = res.data;
    });
    // this.orgService.orgsSecretaryOf().subscribe((res: any[]) => {
    // 	this.members = res;
    // 	console.log(this.members);
    // });
    // this.orgService.orgsManagerOf().subscribe((res: any[]) => {
    // 	const orgsNotApproved = res.filter(
    // 		(x) => x.userLevel !== globals.userLevelOrg
    // 	);
    // 	this.members = this.members?.concat(orgsNotApproved);
    // 	console.log(res);
    // 	console.log(orgsNotApproved);
    // 	console.log(this.members);
    // });
  }

  onSwipeLeft($event) {
    this.navCtrl.setDirection('back', true, 'back');
    this._location.back();
  }

  onSwipeRight($event) {}

  ngOnInit() {
    this.activatedRoute.url.subscribe(() => {
        this.store.dispatch(loadManageOrgsStart())
    });
  }
}
