import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { loadEventsStart } from 'src/app/store/actions/events.action';
import { loadServicesStart } from 'src/app/store/actions/services.action';
import { loadVideosStart } from 'src/app/store/actions/videos.action';
import { EventService } from '../../../../services/event.service';
import { ServiceService } from '../../../../services/service.service';
import { TeacherService } from '../../../../services/teacher.service';
import { VideoService } from '../../../../services/video.service';

@Component({
	selector: 'app-teacher-3-services',
	templateUrl: './teacher3Services.page.html',
	styleUrls: ['./teacher3Services.page.scss'],
})
export class Teacher3ServicesPage implements OnInit {
	page = 1;
	previousUrl;
	currentUrl;

	allTeacherVideos: any = [];
	allTeacherServices: any = [];
	allTeacherBigEvents: any = [];

	teacher3Services: any = [];
	teacherId;
	serviceType;
	searchString = {};
	category = null;
	subCategory = null;
	orgId = null;
	services$: Observable<any>;
	videos$: Observable<any>;
	events$: Observable<any>;

	constructor(
		private http: HttpClient,
		private teacherService: TeacherService,
		private serviceService: ServiceService,
		private videoService: VideoService,
		private eventService: EventService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private store: Store<AppState>,
		private navCtrl: NavController
	) {
		this.teacherId = this.activatedRoute.snapshot.paramMap.get('myId');
		this.searchString = { ownerId: this.teacherId };
		this.serviceType = this.activatedRoute.snapshot.paramMap.get('serviceType');
		console.log(this.serviceType);
		this.currentUrl = this.router.url;
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/teachers'
				: this.activatedRoute.snapshot.paramMap.get('p');

		//获取这个教师的服务

		this.store.dispatch(
			loadServicesStart({
				payload: {
					ownerId: this.teacherId,
				},
			})
		);

		//获取这个教师的events
		this.store.dispatch(
			loadEventsStart({
				payload: {
					ownerId: this.teacherId,
				},
			})
		);

		//获取这个教师的video
		this.store.dispatch(
			loadVideosStart({
				payload: {
					ownerId: this.teacherId,
				},
			})
		);
	}

	ngOnInit() {
		this.services$ = this.store.select('services');
		this.videos$ = this.store.select('videos');
		this.events$ = this.store.select('events');
	}
}
