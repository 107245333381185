import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { TeacherService } from 'src/app/services/teacher.service';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { loadTeachersError, loadTeachersStart, loadTeachersSuccess, loadNextPageTeachersStart } from '../actions/teachers.action';
import { RequestParams } from '../reducer/teachers.reducer';

@Injectable()
export class TeachersEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private teacherService: TeacherService, // we will need this service for API calls
		private store: Store<AppState>
	) {}

	loadTeachers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadTeachersStart),
			map((action) => action.payload),
			switchMap((payload) =>
				this.teacherService.get(payload).pipe(
					map((res: any[]) => {
						let limit = payload['limit'] || environment.perPage;
						return loadTeachersSuccess({
							payload: res,
							noMoreData: res.length < limit,
						});
					}),
					catchError(() => of(loadTeachersError()))
				)
			)
		)
	);

	loadNextPageTeachers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadNextPageTeachersStart),
			withLatestFrom(this.store.select('teachers')),
			//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
			switchMap(([action, oldTeachersRedux]) => {
				let requestParams: RequestParams = oldTeachersRedux.requestParams;
				let page: number = requestParams['page'] + 1;
				let limit: number = oldTeachersRedux['limit'] || environment.perPage;
				let partialTeachers: any[] = oldTeachersRedux['data'].slice(0, (page - 1) * limit);

				//如果此时发来的是分页请求且已经判断没有数据了，则不再发送请求.否则正常数据
				return oldTeachersRedux.noMoreData === true
					? EMPTY
					: this.teacherService.get({ ...requestParams, page }).pipe(
							map((res: any[]) => {
								//返回小于limit的时候，nomoredata为true
								return loadTeachersSuccess({
									payload: [...partialTeachers, ...res],
									noMoreData: res.length < limit,
								});
							}),
							catchError(() => of(loadTeachersError()))
					  );
			})
		)
	);
}
