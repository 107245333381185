import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LikeService } from 'src/app/services/like.service';
import { AppState } from 'src/app/store';
import { likeStart, unlikeStart } from 'src/app/store/actions/likes.action';

@Component({
  selector: 'like',
  templateUrl: 'like.component.html',
})
export class LikeComponent implements OnInit {
  @Input() searchString: any;
  @Input() debounceTime = 300;
  @Input() type: any;

  @Input() data: any;
  @Input() liked: any;

  login$: Observable<any>;
  InfoType: string;
  timer: any = null;
  constructor(
    private likeService: LikeService,
    private store: Store<AppState>,
    private location: Location
  ) {}

  ngOnInit() {
    this.login$ = this.store.select('login');
    this.InfoType =
      'liked' + this.type.replace(/\b[a-z]/g, (l) => l.toUpperCase());
  }

  like($event) {
    this.debounce(() => {
      this.store.dispatch(
        likeStart({
          likeType: this.type,
          _id: this.data._id,
        })
      );
    }, 500);
    $event.stopPropagation();
    $event.preventDefault();
  }
  debounce(fn, delay) {
    // 清除定时器
    clearTimeout(this.timer);
    // 新的定时器值
    this.timer = setTimeout(fn, delay);
  }

  unlike($event) {
    this.debounce(() => {
      this.store.dispatch(
        unlikeStart({
          unlikeType: this.type,
          _id: this.data._id,
        })
      );
    }, 500);
    $event.stopPropagation();
    $event.preventDefault();
  }
}
