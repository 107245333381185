import { createReducer, on } from '@ngrx/store';
import {
	addMyInfoLike,
	ChangeMyInfo,
	loadLogin,
	loadLoginError,
	loadLoginSuccess,
	LogOut,
	removeMyInfoLike,
	tokenLoginStart,
} from '../actions/login.action';

//状态名称
export const LoginKey = 'login';

//状态类型接口
export interface State {
	token: any;
	myInfo: any;
	status: string;
}

// 初始状态
export const initialState: State = {
	token: null,
	myInfo: {},
	status: 'idle',
};

//创建reducer函数
export const reducer = createReducer(
	initialState,
	on(loadLogin, (state: any, action): any => ({
		...state,
		token: action.token,
		myInfo: action.myInfo,
		status: 'start',
	})),
	on(loadLoginSuccess, (state: any, action): any => {
        return ({
		...state,
		status: 'success',
		token: action.payload.token,
		myInfo: {
            ...state.myInfo,
            ...action.payload
        },
	})}),
	on(loadLoginError, (state: any, action): any => ({
		...state,
		status: 'fail',
		token: null,
		myInfo: {},
	})),
	on(tokenLoginStart, (state, action): any => {
        return ({
		...state,
		status: 'start',
	})}),
	on(LogOut, (state: any, action): any => ({
		...state,
		token: null,
		myInfo: {},
		status: 'idle',
	})),
	on(ChangeMyInfo, (state: any, action): any => ({
		...state,
		myInfo: {
			...state.myInfo,
			...action.payload,
		},
	})),
	on(addMyInfoLike, (state, action) => {
		let myInfo = JSON.parse(JSON.stringify(state.myInfo));
		myInfo[action.filedName].push(action.targetId);
		return {
			...state,
			myInfo: {
				...myInfo,
			},
		};
	}),
	on(removeMyInfoLike, (state, action) => {
		let myInfo = JSON.parse(JSON.stringify(state.myInfo));
		myInfo[action.filedName] = myInfo[action.filedName].filter(
			(item) => !(item == action.targetId)
		);
		return {
			...state,
			myInfo: {
				...myInfo,
			},
		};
	})
);
