import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare let Wechat: any;
declare let cordova: any;

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  loading;

  constructor(
    private http: HttpClient,
    private toastController: ToastController,
    public loadingController: LoadingController,
    public translate: TranslateService,
    public alertController: AlertController
  ) {}

  async presentLoading(msg) {
    this.loading = await this.loadingController.create({
      message: this.translate.instant(msg),
      duration: 5000,
    });
    await this.loading.present();

    console.log('Loading started!');
  }

  endLoading() {
    this.loading.dismiss();
    console.log('Loading dismissed!');
  }

  // var london = moment.utc(time1).tz("Europe/London").format('MMMM Do YYYY, h:mm:ss a');
  // var sydney = moment.tz("Australia/Sydney").format('MMMM Do YYYY, h:mm:ss a');
  // var beijing = moment.utc(time1).tz("China/Beijing").format('MMMM Do YYYY, h:mm:ss a');
  // var tokyo = moment.tz("Asia/Tokyo").format('MMMM Do YYYY, h:mm:ss a');

  // localTime(time){
  //     return moment.utc(time).tz("Europe/London")
  // }

  public formatTime(time) {
    return moment(time).toDate();
  }

  async toast(message, duration, position) {
    const toast = await this.toastController.create({
      message,
      duration,
      position,
    });

    return await toast.present();
  }

  getOrganizations(): Observable<any> {
    return this.http
      .get<any>(environment.defaultURL + '/user/getOrganizations')
      .pipe(
        tap((_) => console.log('login')),
        catchError(this.handleError('login', []))
      );
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  share = (service) => {
    Wechat.share(
      {
        message: {
          title: service.owner
            ? service.owner.nickname + service.introduction
            : service.nickname + service.introduction,
          description: service.introduction,
          thumb: service.imageURL,
          mediaTagName: 'TEST-TAG-001',
          messageExt: '这是第三方带的测试字段',
          messageAction: '<action>dotalist</action>',
          media: {
            type: Wechat.Type.WEBPAGE,
            webpageUrl:
              'http://localhost:8100' +
              '/tabs/service/servicedetails/' +
              service._id,
          },
        },
        scene: Wechat.Scene.TIMELINE, // share to Timeline
      },
      () => {
        alert('Success');
      },
      (reason) => {
        alert('Failed: ' + reason);
      }
    );
  };

  // getMySecretaries() {
  //   return this.http.get(environment.defaultURL + '/user/secretary');
  // this.http.get(environment.defaultURL + '/user/secretary')
  //     .subscribe(data => {
  //           this.globalService.toast(this.translate.instant("success"), 1500, 'top')
  //         },
  //         (err) => {
  //           console.log(err)
  //           if(err) this.globalService.toast(this.translate.instant(err.toString()), 1500, 'top')
  //         })
  // }

  timeReformat = (createTime) =>
    createTime.getMonth() +
    1 +
    '-' +
    createTime.getDate() +
    ' ' +
    createTime.getHours() +
    ':' +
    (createTime.getMinutes() < 10
      ? '0' + createTime.getMinutes()
      : createTime.getMinutes());

  async confirmationAlert(header: string, message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve;
    });
    // let that = this;
    const alert = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('cancel'),
          handler: () => resolveFunction(false),
        },
        {
          text: this.translate.instant('OK'),
          handler: () => resolveFunction(true),
        },
      ],
    });
    await alert.present();
    return promise;
  }
}
