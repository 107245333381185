import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SharedorglistComponent } from 'src/app/components/sharedorglist/sharedorglist.component';
import { AppState } from 'src/app/store';
import { loadNextPageOrgsStart, loadOrgsStart } from 'src/app/store/actions/orgs.action';
import { SearchmodalPage } from '../../components/searchmodal/searchmodal.page';
import { OrgService } from '../../services/org.service';

@Component({
	selector: 'app-org',
	templateUrl: './org.page.html',
	styleUrls: ['./org.page.scss'],
})
export class OrgPage implements OnInit {
	// @ViewChild(SharedorglistComponent, { static: true })
	@ViewChild(IonInfiniteScroll)
	infiniteScroll: IonInfiniteScroll;

	sharedorglistComponent: SharedorglistComponent;

	public menu1: any = [];
	public menu2: any = [];
	public menu3 = [];
	public menu4 = [];
	public grids = [];
	public orgs: any;

	page = 1;
	category = null;
	subCategory = null;
	id = undefined;
	certificates = [];
	orgLevel = 2;
	keyword = null;
	orgLoaded = false;
	orgId = null;
	currentUrl;
	searchString = {};

	orgs$: Observable<any>;

	headers = [
		'nickname',
		'introduction',
		'organization',
		'age',
		'reviewNumber',
		'likedCount',
		'certificates',
	];
	insertValues = {
		nickname: '',
		introduction: '',
		organization: '',
		age: '',
		reviewNumber: '',
		category: '',
		likedCount: '',
		certificates: '',
		page: 1,
	};

	stringTypeHeaders = [
		'nickname',
		'introduction',
		'organization',
		'certificates',
	];
	numberTypeHeaders = ['age', 'reviewNumber', 'likedCount'];

	constructor(
		private http: HttpClient,
		private orgService: OrgService,
		private translate: TranslateService,
		private router: Router,

		private store: Store<AppState>,
		public modalController: ModalController
	) {
		this.currentUrl = this.router.url;
	}
	searchChangedHandler() {
		this.sharedorglistComponent.getOrgs();
	}
	ngOnInit() {
		this.getOrgs();
		this.orgs$ = this.store.select('orgs').pipe(
			tap((res) => {
				for (const key in res.requestParams) {
					if (res.requestParams[key] != null) {
						this.insertValues[key] = res.requestParams[key];
					}
				}
			})
		);
	}

	getOrgs() {
		this.store.dispatch(
			loadOrgsStart({
				payload: {
					// search: JSON.stringify(this.searchString),
					page: this.page,
					limit: 20,
					// category: this.category,
					// city: null,
					// memberOf: this.orgId,
				},
			})
		);
	}

	loadData(event) {
		//到达底部了， dispatch一下
		this.store.dispatch(loadNextPageOrgsStart());
		event.target.complete();
	}

	openOrgDetailsPage(org) {}

	search(ev) {
		const val = ev.target.value;

		console.log(val);
	}

	async presentModal() {
		const oldSearchString = this.searchString;
		const modal = await this.modalController.create({
			component: SearchmodalPage,
			componentProps: {
				headers: this.headers,
				numberTypeHeaders: this.numberTypeHeaders,
				stringTypeHeaders: this.stringTypeHeaders,
				values: this.insertValues,
			},
		});

		modal.onDidDismiss().then((dataReturned) => {
			if (dataReturned !== undefined) {
				this.searchString = dataReturned.data;
				this.store.dispatch(
					loadOrgsStart({
						payload: {
							// search: JSON.stringify(this.searchString),
							page: this.page,
							limit: 20,

							...dataReturned.data,
						},
					})
				);
				this.infiniteScroll.disabled = false;
				// this.getOrgs()
			}
		});

		return await modal.present();
	}
}
