import { createReducer } from '@ngrx/store';

//状态名称
export const likesKey = 'likes';

//状态类型接口
export interface State {}

// 初始状态
export const initialState: State = {};

//创建reducer函数
export const likesReducer = createReducer(initialState);
