import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CityService } from 'src/app/services/city.service';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(
    private platform: Platform,
    private cityService: CityService,
    private translate: TranslateService
  ) {}

  isMobile() {
    return this.platform.is('mobile');
  }
  makeQueryParams(queryParams: any) {
    const Median = { ...queryParams };
    //queryParams里面不让修改？？？？ 之所以用这个中间值是因为，直接发送object参数会调用tostring（）变成[Object Object]

    for (const key in Median) {
      if (Median[key] && Median[key].constructor === Object) {
        Median[key] = JSON.stringify(Median[key]);
      }
    }

    for (const key in Median) {
      if (
        [
          null,
          undefined,
          '',
          '全部',
          '{"$regex":"全国"}',
          '{"$regex":"全部"}'
        ].includes(Median[key])
      ) {
        delete Median[key];
      }
    }

    const params: HttpParams = ({} = new HttpParams({
      fromObject: Median
    }));

    // Object.keys(queryParams).forEach((key) => {
    // 	return (
    // 		queryParams[key] &&
    // 		(params = params.append(key, queryParams[key]))
    // 	);
    // });

    // const paramsKeysAux = params.keys();
    // paramsKeysAux.forEach((key) => {
    // 	const value = params.get(key);
    // 	if ([null, undefined, '', '全部', '{"$regex":"全国"}', '{"$regex":"全部"}'].includes(value)) {
    // 		params.map.delete(key);
    // 	}
    // });
    return params;
  }

  ISODateToInput(event: any) {
    event.startTime = moment
      .utc(event.startTime)
      .local()
      .toISOString(true)
      .substring(0, 16);
    event.endTime = moment
      .utc(event.endTime)
      .local()
      .toISOString(true)
      .substring(0, 16);
    return event;
  }

  async changeLanguage() {
    try {
      let category: any[] = [],
        location: any[] = [];
      let categoryPath: string = '',
        locationPath: string = '';
      const lang = this.translate.currentLang;
      switch (lang) {
        case 'ja':
          categoryPath = '../../assets/data/category-jp.json';
          locationPath = '../../assets/data/location-jp.json';
          break;
        case 'en':
          categoryPath = '../../assets/data/category-en.json';
          locationPath = '../../assets/data/location-en.json';
          break;
        default:
          categoryPath = '../../assets/data/category.json';
          locationPath = '../../assets/data/location.json';
      }
      category = await this.cityService.initMultiLevelList(categoryPath);
      location = await this.cityService.initMultiLevelList(locationPath);

      return [category, location];
    } catch (err) {
      console.error(err);
    }
  }

  setAlias() {
    let city = '',
      category = '';
    const lang = this.translate.currentLang;
    switch (lang) {
      case 'en':
        city = 'National';
        category = 'All';
        break;
      case 'ja':
        city = '全国';
        category = 'すべて';
        break;
      default:
        city = '全国';
        category = '全部';
    }
    return [city, category];
  }

  filterCategory(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    const myString = value.map((v) => v).join(',');
    let handledStr = '';
    handledStr = this.handleFilter(handledStr, myString);
    return handledStr;
  }

  handleFilter(str, myString) {
    const lang = this.translate.currentLang;
    switch (lang) {
      case 'en':
        str = myString.includes(',All')
          ? myString.substring(0, myString.indexOf(',All'))
          : myString;
        break;
      case 'ja':
        str = myString.includes(',すべて')
          ? myString.substring(0, myString.indexOf(',すべて'))
          : myString;
        break;
      default:
        str = myString.includes(',全部')
          ? myString.substring(0, myString.indexOf(',全部'))
          : myString;
    }
    const lastCommaIndex = str.lastIndexOf(',');
    str = str.substring(lastCommaIndex + 1).trim();
    return str;
  }
}
