import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../../globals';
import { AuthService } from '../../../../services/auth.service';
import { ChangeObserverService } from '../../../../services/changeObserver.service';
import { GlobalService } from '../../../../services/global.service';
import { OrgService } from '../../../../services/org.service';
import { ServiceService } from '../../../../services/service.service';
import { TeacherService } from '../../../../services/teacher.service';
import { UserService } from '../../../../services/user.service';

@Component({
	selector: 'app-manage-org-members',
	templateUrl: './manageOrgMembers.page.html',
	styleUrls: ['./manageOrgMembers.page.scss'],
})
export class ManageOrgMembersPage implements OnInit {
	id: string;
	password: string;
	param = 'world';
	certificates = [];
	categoryName = [];
	members;
	candidates;
	secretaries;

	uploadedImg = { data: undefined };
	page = 1;
	searchString = {};

	previousUrl;
	org_id;
	memberType = 'candidates';

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private teacherService: TeacherService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public globals: Globals,
		public authService: AuthService,
		private userService: UserService,
		private orgService: OrgService,

		private changeObserver: ChangeObserverService,
		private globalService: GlobalService,
		public navCtrl: NavController,
		private _location: Location
	) {
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/settings'
				: this.activatedRoute.snapshot.paramMap.get('p');
		this.org_id = this.activatedRoute.snapshot.paramMap.get('org_id');
		console.log(this.org_id);

		this.orgService.orgSecretaries(this.org_id).subscribe((res: any[]) => {
			this.secretaries = res;
			console.log(this.secretaries);
		});

		this.orgService.orgMembers(this.org_id).subscribe((res: any[]) => {
			this.members = res;
			console.log(this.members);
		});

		this.orgService.orgCandidates(this.org_id).subscribe((res: any[]) => {
			this.candidates = res;
			console.log(this.candidates);
		});
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {}

	clickSegment(name) {}
}
