import { Injectable, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WeChatService {
  isWeChat: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isWeChat = isPlatformBrowser(platformId) && !!window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i);
  }
}
