import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { ChangeMyInfo } from 'src/app/store/actions/login.action';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { ServiceService } from '../../../services/service.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-my-information',
	templateUrl: './myInformation.page.html',
	styleUrls: ['./myInformation.page.scss'],
})
export class MyInformationPage implements OnInit {
	id: string;
	password: string;
	param = 'world';
	certificates = [];
	categoryName = [];

	uploadedImg = { data: undefined };
	alreadyLoggedIn = false;
	login$: Observable<any>;

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public authService: AuthService,
		private userService: UserService,
		public globals: Globals,
		public navCtrl: NavController,

		private store: Store<AppState>,

		private _location: Location
	) {
		//和那个getme是不是一样的？是的
		this.login$ = this.store.select('login');
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ngOnInit() {
        this.activatedRoute.url.subscribe(()=>{
            this.authService.getMe().subscribe((res) => {
                this.store.dispatch(ChangeMyInfo({payload:{...res}}));
            })
        });
    }

	jsonStringify(x) {
		if (x) {
			return { itemValue: JSON.stringify(x) };
		} else {
			return { itemValue: null };
		}
	}
}
