import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment-timezone';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { ServiceService } from '../../../services/service.service';

declare let Wechat: any;
declare let cordova: any;


@Component({
	selector: 'app-my-points',
	templateUrl: './myPoints.page.html',
	styleUrls: ['./myPoints.page.scss'],
})
export class MyPointsPage implements OnInit {
	points;
	currentUrl = null;
	alreadyLoggedIn = false;
	myInfo: any = {};

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private sanitizer: DomSanitizer,
		public authService: AuthService,
		private globalService: GlobalService,
		private alertCtrl: AlertController,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store
			.select('login')
			.pipe(first())
			.subscribe((res) => {
				this.myInfo = res.myInfo;
				this.alreadyLoggedIn = res.status === 'success';
			});
		// console.log(this.activatedRoute.snapshot.queryParams.payOk);
		// if (this.activatedRoute.snapshot.queryParams.payOk === "true") {
		// 	this.pay()
		// } else if (this.activatedRoute.snapshot.queryParams.payOk === "payFalse") {
		// 	alert("支付失败")
		// } else {
		// }
		
	}

	changeISOtoDate(data2) {
		const data3 = [];
		if (data2) {
			data2.forEach((element, index) => {
				if (element) {
					data3.push({
						_id: element._id,
						startTime: moment(element.startTime).toDate(),
						endTime: moment(element.endTime).toDate(),
					});
				}
			});
		}
		return data3;
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ngOnInit() {}

	openWechatPayment() {
		if (this.points <= 0) {
			alert('inputTheAmountFirstPlease');
			return;
		}	

		const paymentInfo = {
			amount: this.points * 100,
			channel: 'wx',
		};

		Wechat.isInstalled(
			(installed) => {
				console.log('installed');
			},
			(reason) => {
				alert('failed to check installed: ' + reason);
			}
		);

		if (this.points > 0) {
			this.http.post<any>(environment.defaultURL + '/wechat/prePay', paymentInfo).subscribe((data) => {  // 这里是jsapi的微信支付调用 ， 在后台调用了微信
				const params = {
					appid: data.appId,
					partnerid: data.partnerId,
					prepayid: data.prepayId,
					noncestr: data.nonceStr,
					timestamp: data.timeStamp + '',
					sign: data.sign,
				};

				Wechat.sendPaymentRequest(   // 这是干啥的？Wechat
					params,
					(result) => {
						alert('Wechat payment succcess');
						console.log('Wechat pay success: ' + result);
					},
					(reason) => {
						console.log('Wechat pay error');
						console.log(reason);
						alert('Wechat payment failed: ' + reason);
					}
				);
			});
		}
	}

	openAlipayment() {   //here transfer wechat pay

		const AlipaymentInfo = {
			amount: this.points,
		};

		let that:any = window;
		if (this.points <= 0 || !this.points) {
			alert('inputTheAmountFirstPlease');
		} else {
			let orderId = Date.now();//这里用当前时间戳做订单号（后面使用你自己真实的订单号）
			let money = this.points * 100;//订单总金额（单位分）
			let payData = { orderId: orderId, money: money , userId : this.myInfo._id };
			let payDataStr = JSON.stringify(payData);//因为要吧参数传递给小程序，所以这里需要转为字符串
			const url = `../wePay/wePay?payDataStr=${payDataStr}`;
			that.wx.miniProgram.navigateTo({
				url:url
			})
		}

		// if (this.points > 0) {
		// 	this.http
		// 		.post<any>(environment.defaultURL + '/chargeNative/orderInfo?amount=' + this.points, AlipaymentInfo)
		// 		.subscribe((data) => {
				
		// 			cordova.plugins.alipay.payment(
		// 				data.orderInfo,
		// 				(success) => {
		// 					console.log('Alipay result: ' + success);
		// 					if (success.resultStatus === '9000') {
		// 						alert('Alipay success');
		// 					} else {
		// 						alert('Alipay failed: ' + success.resultStatus);
		// 					}
		// 				},
		// 				(error) => {
		// 					console.log('Alipay error:');
		// 					console.log(error);
		// 					alert('Alipay error');
		// 				}
		// 			);
		// 		});
		// }
	}


	payment(point,type) {	
		let that:any = window;
		let orderId = Date.now();//这里用当前时间戳做订单号（后面使用你自己真实的订单号）
		let money = point * 100;//订单总金额（单位分）
		let payData = { orderId: orderId, money: money , userId : this.myInfo._id , type };
		let payDataStr = JSON.stringify(payData);//因为要吧参数传递给小程序，所以这里需要转为字符串
		const url = `../wePay/wePay?payDataStr=${payDataStr}`;
		that.wx.miniProgram.navigateTo({
			url:url
		})
		
	}	
}
