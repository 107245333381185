import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { ChangeObserverService } from '../../../services/changeObserver.service';
import { GlobalService } from '../../../services/global.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-apply-for-pro',
	templateUrl: './apply-for-pro.page.html',
	styleUrls: ['./apply-for-pro.page.scss'],
})
export class ApplyForProPage implements OnInit {
	previousUrl;
	currentUrl;
	certificates = null;
	alreadyLoggedIn = false;
	myInfo: any = {};

	constructor(
		public authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private globalService: GlobalService,
		private changeObserver: ChangeObserverService,
		private userService: UserService,

		private translate: TranslateService,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store.select('login').subscribe((res: any) => {
			this.myInfo = JSON.parse(JSON.stringify(res.myInfo));
			this.alreadyLoggedIn = res.status === 'success';
		});
		console.log(this.myInfo);
		const parsedParam = this.activatedRoute.snapshot.paramMap;
		this.previousUrl =
			parsedParam.get('p') === 'undefined' || parsedParam.get('p') === null
				? '/tabs/settings/myInformation'
				: parsedParam.get('p');
		this.currentUrl = this.router.url;
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ngOnInit() {
		//getme这块的修改？？
		// this.authService.getMe().subscribe((res) => {
		// 	if (res.token) {
		// 		this.alreadyLoggedIn = true;
		// 		this.myInfo = res;
		// 	}
		// });
	}

	applyForPro() {
		this.userService.applyForPro(this.myInfo).subscribe((data) => {
			console.log(data);
			this.globalService.toast(this.translate.instant('successfullyUpdated'), 1500, 'top');
			this.navCtrl.setDirection('back', true, 'back');
			this.navCtrl.navigateBack([this.previousUrl]);
		},
        (err)=> {
            this.globalService.toast(this.translate.instant('err'), 1500, 'top');
        });
	}
}
