import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromConversations from './reducer/conversations.reducer';
import * as fromEvents from './reducer/events.reducer';
import * as fromJemessage from './reducer/jMessage.reducer';
import * as fromLikes from './reducer/likes.reducer';
import * as fromLogin from './reducer/login.reducer';
import * as fromManageOrgs from './reducer/manageOrgs.reducer';
import * as fromMyAppointments from './reducer/myAppointments.reducer';
import * as fromOrgs from './reducer/orgs.reducer';
import * as fromServices from './reducer/services.reducer';
import * as fromSystem from './reducer/system.reducer';
import * as fromTeachers from './reducer/teachers.reducer';
import * as fromVideos from './reducer/videos.reducer';

// store 中存储的状态类型接口
export interface AppState {
	[fromLogin.LoginKey]: fromLogin.State;
	[fromJemessage.MsgKey]: any;
	[fromTeachers.teachersKey]: fromTeachers.State;
	[fromEvents.eventsKey]: fromEvents.State;
	[fromMyAppointments.myAppointmentsKey]: fromMyAppointments.State;
	[fromConversations.conversationsKey]: fromConversations.State;
	[fromOrgs.orgsKey]: fromOrgs.State;
	[fromServices.servicesKey]: fromServices.State;
	[fromVideos.videosKey]: fromVideos.State;
	[fromSystem.systemKey]: fromSystem.State;
	[fromManageOrgs.manageOrgsKey]: fromManageOrgs.State;
	[fromLikes.likesKey]: fromLikes.State;
}

//状态名字和reducer对应的关系
export const reducers: ActionReducerMap<AppState> = {
	[fromLogin.LoginKey]: fromLogin.reducer,
	[fromJemessage.MsgKey]: fromJemessage.reducer,
	[fromTeachers.teachersKey]: fromTeachers.teachersReducer,
	[fromEvents.eventsKey]: fromEvents.eventsReducer,
	[fromMyAppointments.myAppointmentsKey]: fromMyAppointments.myAppointmentsReducer,
	[fromConversations.conversationsKey]: fromConversations.conversationsReducer,
	[fromOrgs.orgsKey]: fromOrgs.orgsReducer,
	[fromServices.servicesKey]: fromServices.servicesReducer,
	[fromVideos.videosKey]: fromVideos.videosReducer,
	[fromSystem.systemKey]: fromSystem.systemReducer,
	[fromManageOrgs.manageOrgsKey]: fromManageOrgs.reducer,
	[fromLikes.likesKey]: fromLikes.likesReducer,
};

export const MetaReducers: MetaReducer<AppState>[] = [];
