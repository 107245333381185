import { createReducer, on } from '@ngrx/store';
import { addMessage, loadMsg } from '../actions/jMessage.action';

//状态名称
export const MsgKey = 'msglist';

//状态类型接口

// 初始状态
export const initialState = {};

//创建reducer函数
export const reducer = createReducer(
	initialState,
	on(loadMsg, (state, action): any => ({
		...state,
		...action.msglist,
	})),

	// on(addMessages, (state, action): any => {
	// 	const oldMsgsRedux = state[action.id] ?? [];
	// 	const newMsgs = action.allMsgs.filter(
	// 		(value) =>
	// 			oldMsgsRedux.findIndex(
	// 				(t) => t.content === value.content && t.target_id === value.target_id && t.from_id === value.from_id
	// 			) === -1
	// 	);
	// 	console.log(newMsgs);
	// 	return {
	// 		...state,
	// 		[action.id]: [...oldMsgsRedux, ...newMsgs],
	// 	};
	// }),
	on(addMessage, (state, action): any => ({
		...state,
		[action.myInfoId]: state[action.myInfoId] ? [...state[action.myInfoId], action.msg] : [action.msg],
	}))
);
// if (!state[action.myInfoId]) {
// 	return {
// 		...state,
// 		[action.myInfoId]: { [action.id]: [{ ...action.msg }] },
// 	};
// } else if (!state[action.myInfoId][action.id]) {
// 	return {
// 		...state,
// 		[action.myInfoId]: { [action.id]: [{ ...action.msg }] },
// 	};
// } else {
// 	const list = [...state[action.myInfoId][action.id]];
// 	list.push(action.msg);
// 	return {
// 		...state,
// 		[action.myInfoId]: { [action.id]: list },
// 	};
// }
