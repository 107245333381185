import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
	public myInfo: any = {};
	public alreadyLoggedIn = false;
	public userLevelOrg = 1;

	public userLevelOrgManager = 1;

	public userLevelNormalToOrg = 5;

	public userLevelNormalToOrgManager = 5;

	public userLevelNormal = 10;
	public userLevelNormalToPro = 15;
	public userLevelPro = 20;
	public userLevelNormalToSecretary = 25;
	public userLevelSecretary = 30;
	public userLevelAdmin = 99;
	public screenWidth = 0;

	public conversations: any = [];
	public messages = {};
	public alreadyLoggedInJM = false;
	public language = '';
	public defaultLanguage = 'zh';

	//notStart, initializing, initialized, loggedIn
	jMessageStatus = 'notStart';

	buttonDisabled = false;
	interval = 1;
	public orgCategory = '';
	public orgCity = '';
	public teacherCategory = '';
	public teacherCity = '';
	public serviceCategory = '';
	public serviceCity = '';
	public eventCategory = '';
	public eventCity = '';
	public videoCategory = '';
	public videoCity = '';
}
