import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { ChangeObserverService } from '../../../services/changeObserver.service';
import { GlobalService } from '../../../services/global.service';
import { OrgService } from '../../../services/org.service';
import { ServiceService } from '../../../services/service.service';
import { TeacherService } from '../../../services/teacher.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-approve-org',
  templateUrl: './approveOrg.page.html',
  styleUrls: ['./approveOrg.page.scss'],
})
export class ApproveOrgPage implements OnInit {
  id: string;
  password: string;
  param = 'world';
  certificates = [];
  categoryName = [];
  candidates: any;
  userLevelOrgs: any;

  uploadedImg = { data: undefined };
  page = 1;
  searchString = {};
  userType = 'candidates';

  constructor(
    private http: HttpClient,
    private serviceService: ServiceService,
    private teacherService: TeacherService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private sanitizer: DomSanitizer,
    public globals: Globals,
    public authService: AuthService,
    private userService: UserService,
    private orgService: OrgService,
    private changeObserver: ChangeObserverService,
    private globalService: GlobalService,
    public navCtrl: NavController,
    private _location: Location
  ) {
    const userLevelNormalToOrgSearchString = {
      userLevel: this.globals.userLevelNormalToOrg,
    };
    const userLevelOrgSearchString = {
      userLevel: this.globals.userLevelOrg,
    };

    this.orgService
      .adminGetOrg(
        userLevelNormalToOrgSearchString,
        this.page,
        null,
        null,
        null
      )
      .subscribe((res: any[]) => {
        this.candidates = res;
      });

    this.orgService
      .adminGetOrg(userLevelOrgSearchString, this.page, null, null, null)
      .subscribe((res: any[]) => {
        this.userLevelOrgs = res;
      });
  }

  onSwipeLeft($event) {
    this.navCtrl.setDirection('back', true, 'back');
    this.navCtrl.navigateBack('/tabs/settings');
  }

  onSwipeRight($event) {}

  ngOnInit() {}

  jsonStringify(x) {
    if (x) {
      return { itemValue: JSON.stringify(x) };
    } else {
      return { itemValue: null };
    }
  }

  rejectOrg(_id) {
    const userLevelNormalToOrgSearchString = {
      userLevel: this.globals.userLevelNormalToOrg,
    };
    const userLevelOrgSearchString = {
      userLevel: this.globals.userLevelOrg,
    };

    this.orgService.rejectOrgCreation({ _id }).subscribe(
      (data) => {
        console.log(data);
        this.globalService.toast(
          this.translate.instant('success'),
          1500,
          'top'
        );
        this.orgService
          .adminGetOrg(
            userLevelNormalToOrgSearchString,
            this.page,
            null,
            null,
            null
          )
          .subscribe((res: any[]) => {
            this.candidates = res;
          });

        this.orgService
          .adminGetOrg(userLevelOrgSearchString, this.page, null, null, null)
          .subscribe((res: any[]) => {
            this.userLevelOrgs = res;
          });
        this.candidates = this.candidates.filter((x) => x !== _id);
      },
      (error) => {
        this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
      }
    );
  }

  approveOrg(_id) {
    const userLevelNormalToOrgSearchString = {
      userLevel: this.globals.userLevelNormalToOrg,
    };
    const userLevelOrgSearchString = {
      userLevel: this.globals.userLevelOrg,
    };
    this.orgService.approveOrgCreation({ _id }).subscribe(
      (data) => {
        this.globalService.toast(
          this.translate.instant('success'),
          1500,
          'top'
        );
        this.orgService
          .adminGetOrg(
            userLevelNormalToOrgSearchString,
            this.page,
            null,
            null,
            null
          )
          .subscribe((res: any[]) => {
            this.candidates = res;
          });

        this.orgService
          .adminGetOrg(userLevelOrgSearchString, this.page, null, null, null)
          .subscribe((res: any[]) => {
            this.userLevelOrgs = res;
          });
        this.candidates = this.candidates.filter((x) => x._id !== _id);
      },
      (error) => {
        this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
      }
    );
  }
}
