import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment-timezone';
import { Observable, of } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { ServiceService } from '../../../services/service.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-my-calendar-as-user',
	templateUrl: './myCalendarAsUser.page.html',
	styleUrls: ['./myCalendarAsUser.page.scss'],
})
export class MyCalendarAsUserPage implements OnInit {
	teacherId: string;
	password: string;

	myCalendarAsUser: any = [];
	showcancel = true;
	currentURL;
	alreadyLoggedIn = false;
	myInfo: any = {};
	backparams: any = {};
	
	servicesParams:any={}
	eventsParams:any={}
	
	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public authService: AuthService,
		private globalService: GlobalService,
		private userService: UserService,
		private alertController: AlertController,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private utilService: UtilService,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store.select('login').subscribe((res) => {
			console.log(res);
			this.myInfo = res.myInfo;
			this.alreadyLoggedIn = res.status === 'success';
		});
		this.currentURL = this.router.url;

		this.backparams = JSON.parse(this.activatedRoute.snapshot.paramMap.get('backparams')) 
        if (this.backparams?.status === 'ServiceSuccess') {
            this.servicesParams.startTime  = moment(this.backparams.startTime).toString();
			this.servicesParams.endTime = moment(this.backparams.endTime).toString();
			this.servicesParams.service = this.backparams.service;
			this.servicesParams.contact = this.backparams.contact
            this.makeAppointment()
		}
		if (this.backparams?.status === 'EventSuccess') {
			this.eventsParams.event = this.backparams.bigEvent;
			this.eventsParams.contact = this.backparams.contact
			this.eventnewPayment()
		}
		debugger

	}

	eventnewPayment() {
	console.log('paymentStart');
      this.http
        .post(environment.defaultURL + '/reservation/eventPurchase', {
          bigEvent: this.eventsParams.event,
          contact: this.eventsParams.contact,
        })
        .subscribe(
			(data) => {
			this.userService.myCalendarAsUser().subscribe((data2) => {
				this.myCalendarAsUser = data2;
				this.myCalendarAsUser = this.myCalendarAsUser.map((x) => (x = this.utilService.ISODateToInput(x)));
			});
            this.globalService.toast(
              this.translate.instant('success'),
              1500,
              'top'
            );
            this._location.back();
          },
          (err) => {
			console.log(err);
			this.userService.myCalendarAsUser().subscribe((data2) => {
				this.myCalendarAsUser = data2;
				this.myCalendarAsUser = this.myCalendarAsUser.map((x) => (x = this.utilService.ISODateToInput(x)));
			});
            if (err.error) {
              this.globalService.toast(
                this.translate.instant(err.error.message),
                1500,
                'top'
              );
            }
          }
        );
	}

	makeAppointment() {
		this.http
		.post(
			environment.defaultURL + '/reservation/servicePurchase',
			this.servicesParams
		)
		.subscribe(
			(data) => {
				this.globalService.toast(
					this.translate.instant('success'),
					1500,
					'top'
				);
				this.userService.myCalendarAsUser().subscribe((data2) => {
					this.myCalendarAsUser = data2;
					this.myCalendarAsUser = this.myCalendarAsUser.map((x) => (x = this.utilService.ISODateToInput(x)));
				});
				this._location.back();
			},
			(err) => {
				console.log(err);
				this.userService.myCalendarAsUser().subscribe((data2) => {
					this.myCalendarAsUser = data2;
					this.myCalendarAsUser = this.myCalendarAsUser.map((x) => (x = this.utilService.ISODateToInput(x)));
				});
				if (err.error) {
					this.globalService.toast(
						this.translate.instant(err.error.message),
						1500,
						'top'
					);
				}
			}
		);
    }

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ngOnInit() {
		debugger
		this.userService.myCalendarAsUser().subscribe((data2) => {
			this.myCalendarAsUser = data2;
			console.log(data2)
			debugger
			this.myCalendarAsUser = this.myCalendarAsUser.map((x) => (x = this.utilService.ISODateToInput(x)));
			console.log(this.myCalendarAsUser)
			debugger
		});
	}

	handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}

	showContactInfo(element, user) {
		const data: any = {};
		data.user = user;
		data.eventId = element._id;
		// this.navCtrl.push(ContactInfo, data);
	}

	openJobOfferDetailsPage(user) {
		// this.navCtrl.push(JobOfferDetails, user);
	}

	openTeacherDetailsPage(user) {
		// this.navCtrl.push(TeacherDetails, {'teacher': user});
	}

	reservationDone(element) {
		const flag = false;
		return flag;
	}

	openDetailsPage(service) {
		if (service.serviceType === 'bigEvent') {
		}
		//   this.navCtrl.push(BigEventDetails, service);
		else if (service.serviceType === 'service') {
		}
		//   this.navCtrl.push(ServiceDetails, service);
		// else this.navCtrl.push(JobDetails, service);
	}

	openReviewReservationPage(element) {
		// this.navCtrl.push(ReviewReservation, element);
	}

	openServicePage(element) {
		// this.navCtrl.push(ServiceDetails, element.service);
	}

	openEventPage(element) {
		// this.navCtrl.push(BigEventDetails, element.bigEvent);
	}

	showcancelOrNot(element) {
		const flag = true;

		return flag;
	}

	async presentAlertConfirm(element) {
		const alert = await this.alertController.create({
			header: this.translate.instant('clientCancel'),
			message: this.translate.instant('clientCancelNotification'),
			buttons: [
				{
					text: this.translate.instant('cancel'),
					role: 'cancel',
					handler: () => {},
				},
				{
					text: this.translate.instant('OK'),
					handler: () => {
						this.http
							.post(environment.defaultURL + '/reservation/clientCancelReservation', {
								_id: this.myInfo._id,
								reservation_id: element._id,
								reason: 'not satisfied',
							})
							.subscribe(
								(data2) => {
									if (data2) {
										this.globalService.toast(this.translate.instant('clientCancel'), 1500, 'top');
										element.status = 'clientCancel';
									}
								},
								(err) => {
									if (this.testJSON(err._body)) {
										const errJson1 = JSON.parse(err._body);
										if (errJson1.message) {
											this.globalService.toast(
												this.translate.instant(errJson1.message),
												1500,
												'top'
											);
										}
									}
								}
							);
					},
				},
			],
		});
		await alert.present();
	}

	clientCancelReservation(element) {
		if (this.myInfo) {
			if (this.myInfo.id) {
				this.presentAlertConfirm(element);
			} else {
				this.globalService.toast(this.translate.instant('loginPlease'), 1500, 'top');
			}
		} else {
			this.globalService.toast(this.translate.instant('loginPlease'), 1500, 'top');
		}
	}

	elementStatus(element) {
		const now = moment();
		if (now > moment(element.startTime) && element.status === 'open') {
			return 'overtime';
		} else {
			return element.status;
		}
	}

	async presentAlertCancell(element) {
		const alert = await this.alertController.create({
			header: this.translate.instant('forceCancel'),
			message: this.translate.instant('forceCancelNotification'),
			buttons: [
				{
					text: this.translate.instant('cancel'),
					role: 'cancel',
					handler: () => {},
				},
				{
					text: this.translate.instant('OK'),
					handler: () => {
						this.http
							.post(environment.defaultURL + '/reservation/forceCancelReservation', {
								_id: this.myInfo._id,
								reservation_id: element._id,
								reason: 'not satisfied',
							})
							.subscribe(
								(data2) => {
									if (data2) {
										element.status = 'forceCancel';
										this.globalService.toast(this.translate.instant('forceCancel'), 1500, 'top');
									}
								},
								(err) => {
									if (this.testJSON(err._body)) {
										const errJson1 = JSON.parse(err._body);
										if (errJson1.message) {
											this.globalService.toast(
												this.translate.instant(errJson1.message),
												1500,
												'top'
											);
										}
									}
								}
							);
					},
				},
			],
		});
		await alert.present();
	}

	forceCancelReservation(element) {
		if (this.myInfo) {
			if (this.myInfo.id) {
				this.presentAlertCancell(element);
			} else {
				this.globalService.toast(this.translate.instant('loginPlease'), 1500, 'top');
			}
		} else {
			this.globalService.toast(this.translate.instant('loginPlease'), 1500, 'top');
		}
	}

	testJSON(text) {
		if (typeof text !== 'string') {
			return false;
		}
		try {
			JSON.parse(text);
			return true;
		} catch (error) {
			return false;
		}
	}

	isOverTime(element) {
		const now = moment();
		if (now > moment(element.startTime)) {
			return true;
		} else {
			return false;
		}
	}
}
