import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { LikeService } from '../../services/like.service';
import { VideoService } from '../../services/video.service';

@Component({
	selector: 'app-shared-video-list',
	templateUrl: 'sharedvideolist.component.html',
})
export class SharedvideolistComponent implements OnInit {
	@ViewChild(IonInfiniteScroll, { static: true })
	infiniteScroll: IonInfiniteScroll;

	@Input() searchString: any;
	@Input() orgId: any;
	@Input() videos: any;

	page = 1;
	// videos: any = [];
	noMoreData = false;
	currentUrl;
	alreadyLoggedIn = false;
	myInfo: any = {};

	constructor(
		private router: Router,
		private videoService: VideoService,
		private globalService: GlobalService,
		private likeService: LikeService,
		public authService: AuthService,
		public globals: Globals,
		private translate: TranslateService,
		public navCtrl: NavController,
		private _location: Location
	) {
		this.currentUrl = this.router.url;
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		const n = this.currentUrl.split('/').length;
		if (n > 3) {
			this._location.back();
		}
	}

	onSwipeRight($event) {}

	// async doit(){
	//   await this.videos
	//   console.log(this.videos)
	// }
	ngOnInit() {
		// this.getVideos();
	}

	loadData(event) {
		setTimeout(() => {
			console.log('Done');
			if (!this.noMoreData) {
				this.page += 1;
				this.getVideos();
			}
			console.log(this.videos);
			event.target.complete();

			// App logic to determine if all data is loaded
			// and disable the infinite scroll
			if (this.videos.length === 1000) {
				event.target.disabled = true;
			}
		}, 500);
	}

	like(type, target) {
		// this.likeService.like(type, target).subscribe(data => {
		//     // this.teacher = data
		//     // this.teachers = this.teachers.map(teacher =>  teacher._id === data._id?data:teacher)
		// });
	}

	unlike(type, target) {
		// this.likeService.unlike(type, target).subscribe(data => {
		// });
	}

	getVideos() {}

	toggleInfiniteScroll() {
		this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
	}


}
