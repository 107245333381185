import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { Globals } from '../globals';
import { AppState } from '../store';
import { loadConversationsStart } from '../store/actions/conversations.action';
import { addMessage } from '../store/actions/jMessage.action';
import { ChangeObserverService } from './changeObserver.service';
import { GlobalService } from './global.service';
import { TeacherService } from './teacher.service';

declare let JMessage: any;

@Injectable()
export class JMessageService {
	public JIM = new JMessage();
	public messages;
	public conversations;
	public alreadyLoggedInJM = false;
	public currentUrl;
	private appkey = 'a7476ca7478ea74049bf2ebc';
	private masterkey = 'bb1874b703a6a4db2e3a9202';
	private random_str = '022cd9fd995849b58b3ef0e943421ed9';

	public myInfo: any;

	public newMsgs: any[] = [];

	constructor(
		private changeObserver: ChangeObserverService,
        public translate: TranslateService,
		private store: Store<AppState>,
		public globals: Globals,
		public globalService: GlobalService,
		private teacherService: TeacherService
	) {
		// initialize the jMessage moved to app component
		this.store.select('conversations').subscribe((res) => {
			this.conversations = res;
		});

		this.store.select('login').subscribe((res) => {
			this.myInfo = res.myInfo;
		});
	}

	initJMessage() {
		this.globals.jMessageStatus = 'initializing';
		const timestamp = new Date().getTime();
		const str =
			'appkey=' +
			this.appkey +
			'&timestamp=' +
			timestamp +
			'&random_str=' +
			this.random_str +
			'&key=' +
			this.masterkey;
		const signature = Md5.hashStr(str);
		console.log('init start');
        debugger
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.init({
				appkey: obj.appkey,
				random_str: obj.random_str,
				signature,
				timestamp,
				flag: 1, //enable onSyncConversation
			})
				.onSuccess((data) => {
					console.log('success');
					console.log(obj.JIM.isInit());
					obj.globals.jMessageStatus = 'initialized';

					resolve(data);
				})
				.onFail((data) => {
					console.log('error:' + JSON.stringify(data));
				});
		});
	}

	clearUnreadMessage(target_id) {
		//添加新的conversation的reducer改变needReed

		// this.globals.conversations.map((x, i) => {
		// 	if (x.id === target_id) {
		// 		this.globals.conversations[i].needRead = 0;
		// 	}
		// });
		if (this.conversations[this.myInfo.id] == undefined) {
			return;
		} //这里错了，一会看一下？
		const con = JSON.parse(JSON.stringify(this.conversations[this.myInfo.id]));

		con.map((x, i) => {
			if (x.id === target_id) {
				x.needRead = 0;
			}
		});

		// console.log(this.globals.conversations);
		this.sortSaveConversations(con);
	}

	userReg(username: string, password: string, nickname: string) {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.register({
				username,
				password: password + 'liuchaowudi',
				nickname,
				//'is_md5' : '<is_md5>可选参数，密码是否为md5加密的密码，默认为false
			})
				.onSuccess((data) => {
					if (data.code === 0) {
						// console.log('注册成功');
						resolve(data);
					} else {
						//更多错误请参照错误代码列表
						console.log('注册失败' + data);
					}
				})
				.onFail((data) => {
					console.log('error:' + JSON.stringify(data));
				});
		});
	}

	login(username: string, password: string) {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.login({
				username,
				password: password,
				//'is_md5' : '<is_md5>可选参数，密码是否为md5加密的密码，默认为false
			})
				.onSuccess((data) => {
					if (data.code === 0) {
						// console.log('登陆成功，会返回用户名');
						console.log('ok');
						obj.alreadyLoggedInJM = true;

						resolve(data);
					} else if (data.code === 880104) {
						//更多错误请参照错误代码列表
						resolve(null);
						console.log('登录失败，密码错误');
					}
				})
				.onFail((data) => {
					//need to endpresenting and logout
                    if (data.code === 880104) {
                        obj.loginOut();
                        obj.globalService.toast('JMessage登录失败，密码错误', 1500, 'top');
                    } else {
                        obj.loginOut();
                        obj.globalService.toast(obj.translate.instant('err'), 1500, 'top')
                    }
					resolve(null);
					console.log('error:' + JSON.stringify(data));
				});
		});
	}

	getUserInfo(username: string): any {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.getUserInfo({
				username,
				appkey: this.appkey,
				//'is_md5' : '<is_md5>可选参数，密码是否为md5加密的密码，默认为false
			})
				.onSuccess((data) => {
					if (data.code === 0) {
						// console.log('成功，会返回用户');
						resolve(data);
					} else if (data.code === 880104) {
						//更多错误请参照错误代码列表
						console.log('失败，错误');
					}
				})
				.onFail((data) => {
					console.log('error:' + JSON.stringify(data));
				});
		});
	}

	loginOut() {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.loginOut();
		});
	}

	/**
	 *
	 *
	 * 修改密码
	 *
	 *
	 *
	 * @param oldPwd
	 * @param nPwd
	 * @returns
	 */
	updateSelfPwd(oldPwd: string, nPwd: string) {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.updateSelfPwd({
				old_pwd: oldPwd + 'liuchaowudi',
				new_pwd: nPwd + 'liuchaowudi',
				// 'is_md5' : ''
			})
				.onSuccess((data) => {
					// do something
					if (data.code === 0) {
						console.log('修改成功');
						resolve(data);
					} else {
						//更多错误请参照错误代码列表
						console.log('注册失败' + data);
					}
				})
				.onFail((data) => {
					// do something
				});
		});
	}

	/**
	 *
	 *
	 * 更新用户信息
	 *
	 *
	 *
	 * @param nickname
	 * @param sex
	 * @returns
	 */
	updateSelfInfo(avatar: string) {
		console.log(avatar);
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.updateSelfInfo({
				// 'birthday' : '',
				// 'signature' : '',
				extras: { avatar },
				// 'region' : '',
				// 'address' : ''
			})
				.onSuccess((data) => {
					console.log(data);
					resolve(data);
					// do something
				})
				.onFail((data) => {
					// do something
					console.log(data);
				});
		});
	}

	/**
	 *
	 *
	 * 获得对话列表
	 *
	 *
	 *
	 * @returns
	 */
	onGetConversation() {
		this.JIM.getConversation()
			.onSuccess((res: any) => {
				//消息列表页面
				const data = res.conversations.map((x) => ({
					id: x.username,
					name: x.nickName,
					unread_msg_count: x.unread_msg_count,
					showTime: x.mtime,
					avatar: x.avatar,
				}));

				if (data && data.length) {
					this.store.dispatch(loadConversationsStart({ payload: { data, allMsgs: [], id: this.myInfo.id } }));
				}
			})
			.onFail((data) => {
				console.log('error:' + JSON.stringify(data));
			});
	}

	/**
	 *
	 *
	 * sync离线消息
	 *
	 *
	 *
	 * @returns
	 */
	//////map msg
	onSyncConversation() {
		this.JIM.onSyncConversation((res) => {
			console.log(res);
			const allMsgs = res
				.map((x) => x.msgs)
				.flat(1)
				.map((y) => ({ ...y.content, msg_id: y.msg_id }))
				.map((msg) => this.refactorMsg(msg));

			//消息列表页面
			const refactoredCon = res
				.map((x) => x.msgs)
				.map((eachArray) => {
					const maxCtimeArray = eachArray.map((eachObj) => eachObj.ctime_ms);
					const max = Math.max(...maxCtimeArray);
					const maxCtimeObj = eachArray.find((o) => o.ctime_ms === max);

					const id =
						this.myInfo.id === maxCtimeObj.content.from_id
							? maxCtimeObj.content.target_id
							: maxCtimeObj.content.from_id;
					const name =
						this.myInfo.id === maxCtimeObj.content.from_id
							? maxCtimeObj.content.target_name
							: maxCtimeObj.content.from_name;
					const showMsg =
						maxCtimeObj.content.msg_type === 'text'
							? maxCtimeObj.content.msg_body.text
							: maxCtimeObj.content.msg_type;

					return {
						id,
						name,
						needRead: res.find((x) => x.from_username === id)?.unread_msg_count || 0,
						showMsg,
						showTime: maxCtimeObj.ctime_ms,
					};
				});
			const data = refactoredCon;
			const id = this.myInfo.id;
			console.log(data);
			if (data && data.length) {
				const ids = data.filter((item) => item.avatar === undefined || '').map((item) => item.id);
				console.log(ids);
				if (ids.length > 0) {
					this.store.dispatch(loadConversationsStart({ payload: { data, allMsgs, id, ids } }));
				}
			}
			// this.store.dispatch(addAvatar({ payload: refactoredPayload, MyinfoId: this.login.myInfo.id }));

			if (data && data.length) {
				this.store
					.select('msglist')
					.pipe(first())
					.subscribe((oldMsgsRedux: any) => {
						this.newMsgs = !oldMsgsRedux[this.myInfo.id]
							? allMsgs
							: allMsgs.filter(
									(value) =>
										oldMsgsRedux[this.myInfo.id].findIndex(
											(t) =>
												t.content === value.content &&
												t.target_id === value.target_id &&
												t.from_id === value.from_id
										) === -1
							  );
					});

				this.newMsgs.forEach((msg) =>
					this.store.dispatch(
						addMessage({
							id: msg.from_id === this.myInfo.id ? msg.target_id : msg.from_id,
							msg,
							myInfoId: this.myInfo.id,
						})
					)
				);
			}
		});
	}
	// console.log('onsync start');
	// return new Promise((resolve, reject) => {
	//     console.log('jSync');

	//     obj.JIM.onSyncConversation(
	//         (data) => {
	//             console.log('data');
	//             // sync离线消息
	//             // console.log(data);
	//             const allMsgArray = [];
	//             const msgs = data.map((x) => x.msgs);

	//             const msgArrayFromSync = msgs //首先将收到的离线消息和现在帐号的myInfo对比
	//                 .flat()
	//                 .filter((x) => x.target_id !== obj.myInfo.id); //（myInfo替换成login里面的）

	//             const con = obj.conversations[obj.myInfo.id] ?? [];
	//             const showTimeArray = con.map((x) => x.showTime); //将当前最新的showtime取出来
	//             const storedNewestTime = Math.max(...showTimeArray);
	//             const messages = [];
	//             const newMsgArray = [];
	//             //将从极光拿到的离线消息和本机conversation最新的showtime做对比，找出是离线的消息
	//             msgArrayFromSync.forEach((x, i) => {
	//                 if (x.content.create_time > storedNewestTime) {
	//                     const msg = x.content;
	//                     msg.content =
	//                         msg !== undefined && msg.msg_body !== undefined
	//                             ? msg.msg_body.text
	//                             : undefined;
	//                     if (msg.content !== undefined || null) {
	//                         messages.push(msg); //将离线消息拿出来放到messages数组里
	//                     }
	//                 }
	//                 if (i === msgArrayFromSync.length - 1) {
	//                     messages.forEach((msg, o) => {
	//                         console.log(msg);
	//                         const msgPojo = obj.refactorMsg(msg);
	//                         //将消息体处理一下，抽出file和img , 将其添加到新的newMsgArray
	//                         newMsgArray.push(msgPojo);
	//                         //在这里将添加的msglist，msg的线头
	//                         this.store.dispatch(
	//                             addMessage({
	//                                 id: msg.from_id,
	//                                 msg: msgPojo,
	//                                 myInfoId: obj.myInfo.id,
	//                             })
	//                         );
	//                         if (o === messages.length - 1) {
	//                             //如果这是最后一条离线消息了,给新数组先按发送时间排序，再putMsgToConversations（将新的离线消息放到每一个conversation里）
	//                             console.log(newMsgArray);

	//                             newMsgArray.sort(
	//                                 (a, b) => b.showTime - a.showTime
	//                             ); //不需要再次赋值,直接就可进行排序
	//                             console.log(newMsgArray);

	//                             obj.putMsgToConversations(newMsgArray);
	//                         }
	//                     });
	//                 }
	//             });
	//             resolve(allMsgArray);
	//         },
	//         (err) => {
	//             console.log(err);
	//             resolve(null);
	//         }
	//     );
	//     //needFix
	// });

	//死循环了，因为subscrib对吧，，以前没有添加上，这次添加上了
	// }

	/**
	 *
	 *
	 * 获得好友列表
	 *
	 *
	 *
	 * @returns
	 */
	getFriendList() {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.getFriendList()
				.onSuccess((data) => {
					resolve(data.friend_list);
				})
				.onFail((data) => {
					console.log(data);
				});
		});
	}

	/**
	 *
	 *
	 * 添加好友请求
	 *
	 *
	 *
	 * @param username
	 * @param type
	 * @param why
	 * @returns
	 */
	addFriend(username: string, type: number, why: string) {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.addFriend({
				target_name: username,
				from_type: type,
				why,
			})
				.onSuccess((data) => {
					// do something
					resolve(data);
				})
				.onFail((data) => {
					// do something
					console.log(data);
				});
		});
	}

	/**
	 *
	 *
	 * 消息监听方法
	 *
	 *
	 *
	 * @returns
	 */
	onMsgReceive() {
		const obj = this;
		obj.JIM.onMsgReceive((data) => {
			// 聊天消息处理
			const messages = data.messages;
			const newMsgArray = [];

			for (const o in messages) {
				//会发很多条过来吗？ 这个if无论怎么判断都true
				if (o) {
					const msg = { ...messages[o].content, msg_id: messages[o].msg_id };
					const msgPojo = obj.refactorMsg(msg);
					newMsgArray.push(msgPojo);

					this.store.dispatch(
						addMessage({
							id: msg.from_id,
							msg: msgPojo,
							myInfoId: obj.myInfo.id,
						})
					);
				}
			}

			obj.onMsgReceive(); //循环调用？
		});
	}

	refactorMsg(msg) {
		if (msg.msg_type === 'file') {
			return {
				create_time: msg.create_time,
				from_id: msg.from_id,
				from_name: msg.from_name,
				content: 'file:https://fmedia.im.jiguang.cn/' + msg.msg_body.media_id,
				target_id: msg.target_id,
				target_name: msg.target_name,
				msg_id: msg.msg_id,
			};
		}
		return {
			create_time: msg.create_time,
			from_id: msg.from_id,
			from_name: msg.from_name,
			content: msg.msg_body.text ?? 'url:https://fmedia.im.jiguang.cn/' + msg.msg_body.media_id,
			target_id: msg.target_id,
			target_name: msg.target_name,
			msg_id: msg.msg_id,
		};
	}

	sendSinglePic = (toUser, content, imageUrl, target_name) => {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.sendSinglePic({
				target_username: toUser,
				image: content,
				// 'appkey' : '<targetAppkey>',
				// 'extras' : 'json object'
			})
				.onSuccess((data, message) => {
					const msg = { ...message.content, msg_id: message.msg_id };
					console.log(msg);
					if (msg.msg_body.media_id) {
						obj.getResource(msg).then((imageURLData: any) => {
							const imageURL = imageURLData ? (imageURLData.url ? imageURLData.url : '') : '';
							msg.msg_body.text = 'url:' + imageURL;
							const msgPojo = obj.refactorMsg(msg);
							Object.assign(msgPojo, { imageUrl, target_name });
							this.store.dispatch(
								addMessage({
									id: msg.target_id,
									msg: msgPojo,
									myInfoId: obj.myInfo.id,
									sendOrNot: 'send',
								})
							);
							obj.putMsgToConversations([msgPojo]);
						});
					} else {
						const msgPojo = obj.refactorMsg(msg);
						Object.assign(msgPojo, { imageUrl, target_name });
						this.store.dispatch(
							addMessage({
								id: msg.target_id,
								msg: msgPojo,
								myInfoId: obj.myInfo.id,
							})
						);
						obj.putMsgToConversations([msgPojo]);
						resolve(data.code);
					}
				})
				.onFail(() => {});
		});
	};
	/**
	 *
	 *
	 * 发送文本消息
	 *
	 *
	 *
	 * @param toUser
	 * @param toUserNickName
	 * @param content
	 * @returns
	 */
	sendSingleMsg(toUser, content, imageUrl, target_name) {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.sendSingleMsg({
				target_username: toUser,
				content,
				// 'appkey' : '<targetAppkey>',
				// 'extras' : 'json object'
			})
				.onSuccess((data, message) => {
					const msg = { ...message.content, msg_id: message.msg_id };
					const msgPojo = obj.refactorMsg(msg);
					Object.assign(msgPojo, { imageUrl, target_name });
					this.store.dispatch(
						addMessage({
							id: msg.target_id,
							msg: msgPojo,
							myInfoId: obj.myInfo.id,
							sendOrNot: 'send',
						})
					);

					// obj.putMsgToConversations([msgPojo]);
					resolve(data.code);
				})
				.onFail((data) => {
                    this.globalService.toast(data.message,1500,'top')
                });
		});
	}

	/**
	 *
	 *
	 * 上传头像
	 *
	 *
	 *
	 * @param img
	 * @returns
	 */
	updateSelfAvatar(img) {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.updateSelfAvatar({
				avatar: img,
			})
				.onSuccess((data) => {
					// do something
					console.log(data);
					resolve(data);
				})
				.onFail((data) => {
					// do something
					resolve(null);
					console.log(data);
				});
		});
	}

	getResource(msg) {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.getResource({
				media_id: msg.msg_body.media_id,
			})
				.onSuccess((data) => {
					// do something
					console.log(data);
					resolve(data);
				})
				.onFail((data) => {
					// do something
					resolve(null);
					console.log(data);
				});
		});
	}

	putMsgToConversations(newMsgArray): any {
		const obj = this;

		console.log(newMsgArray);
		// console.log(this.globals.messages);

		return new Promise<any[]>((resolve) => {
			let conversations = [];
			if (obj.conversations[obj.myInfo.id]) {
				conversations = JSON.parse(JSON.stringify([...obj.conversations[obj.myInfo.id]]));
			}

			//刷新会话列表

			const namesNeedUpdateImage = [];

			//sometimes conversations doesn't have the msg. we need to create msg manually
			// 是判断自己往外发的吗？
			const msgArrayIds = newMsgArray.map((x) => (x.from_id === obj.myInfo.id ? x.target_id : x.from_id));

			const msgArrayNames = newMsgArray.map((x) =>
				x.from_name === obj.myInfo.nickname ? x.target_name : x.from_name
			);

			let conversationIds = conversations.length !== 0 ? conversations.map((x) => x.id) : [];
			console.log(msgArrayIds);
			console.log(conversationIds); //[]
			msgArrayIds.forEach((msgId, i) => {
				console.log(!conversationIds.includes(msgId)); //false
				console.log(i);
				if (!conversationIds.includes(msgId)) {
					//如果conversation原来不存在这条消息新创建
					conversationIds = [...conversationIds, msgId];
					obj.teacherService.getTeacherSimpleInfo(msgId).subscribe((res: any) => {
						const a = [...obj.conversations[obj.myInfo.id]];
						//这里的请求发出去慢了，都执行到else了，才执行到这
						a.push({
							msgArr: [],
							id: msgId,
							name: msgArrayNames[i] || msgArrayIds[i],
							needRead: 1,
							showMsg: newMsgArray[i].content,
							showTime: newMsgArray[i].create_time,
							avatar: res.imageURL,
							targetUuid: res._id,
						});
						this.sortSaveConversations(a);
					});
				} else {
					// let a = JSON.parse(JSON.stringify(conversations));
					const idx = conversationIds.indexOf(msgId);
					console.log(conversations[idx]);
					if (conversations[idx]?.id === newMsgArray[i].from_id) {
						conversations[idx].needRead = 1;
						if (newMsgArray[i].create_time > conversations[idx]?.showTime) {
							conversations[idx].showMsg = newMsgArray[i].content;
							conversations[idx].showTime = newMsgArray[i].create_time;
						}
						if (!conversations[idx]?.targetUuid) {
							namesNeedUpdateImage.push(msgId);
						}
					} else if (conversations[idx]?.id === newMsgArray[i].target_id) {
						conversations[idx].needRead = 0;
						if (newMsgArray[i].create_time > conversations[idx].showTime) {
							conversations[idx].showMsg = newMsgArray[i].content;
							conversations[idx].showTime = newMsgArray[i].create_time;
						}
					}
					// if (i === msgArrayIds.length - 1) {
					// 	this.sortSaveConversations(conversations);
					// }
				}
			});
			this.sortSaveConversations(conversations);

			// 	const namesNeedUpdate = [...new Set(namesNeedUpdateImage)];
			// 	namesNeedUpdate && namesNeedUpdate.length > 0
			// 		? namesNeedUpdate.forEach((msgId, i) => {
			// 			const searchString = { id: { $in: msgId } };
			// 			obj.teacherService
			// 				.get(searchString, 1, null, null, null)
			// 				.subscribe((res: any[]) => {
			// 					conversations.map((x) => {
			// 						if (x.id === msgId && res[0] && res[0].id) {
			// 							x.avatar = res[0].imageURL;
			// 							x.targetUuid = res[0]._id;
			// 						}
			// 						return x;
			// 					});
			// 					console.log(conversations);
			// 					if (i === namesNeedUpdate.length - 1) {
			// 						this.sortSaveConversations(conversations);
			// 					}
			// 				});
			// 		})
			// 		: this.sortSaveConversations(conversations);
		});
	}

	sortSaveConversations(conversations) {
		//排序保存对话，conversation的线头,所有对msglist的改变最后都会到这里
		// const conversationkey = this.myInfo.id;
		// conversations.sort((x, y) => y.showTime - x.showTime);
		// this.store.dispatch(
		// 	loadConversation({
		// 		conversation: {
		// 			[conversationkey]: conversations,
		// 		},
		// 	})
		// );
	}

	//发送单聊文件
	sendSingleFile(toUser, content, imageUrl, target_name) {
		const obj = this;
		return new Promise((resolve) => {
			obj.JIM.sendSingleFile({
				target_username: toUser,
				file: content,
				// 'appkey' : '<targetAppkey>',
				// 'extras' : 'json object'
			})
				.onSuccess((data, message) => {
					const msg = { ...message.content, msg_id: message.msg_id };
					console.log(msg);
					if (msg.msg_body.media_id) {
						obj.getResource(msg).then((imageURLData: any) => {
							const imageURL = imageURLData ? (imageURLData.url ? imageURLData.url : '') : '';
							msg.msg_body.text = 'url:' + imageURL;
							const msgPojo = obj.refactorMsg(msg);
							Object.assign(msgPojo, { imageUrl, target_name });
							this.store.dispatch(
								addMessage({
									id: msg.target_id,
									msg: msgPojo,
									myInfoId: obj.myInfo.id,
									sendOrNot: 'send',
								})
							);

							obj.putMsgToConversations([msgPojo]);
						});
					} else {
						const msgPojo = obj.refactorMsg(msg);
						Object.assign(msgPojo, { imageUrl, target_name });
						this.store.dispatch(
							addMessage({
								id: msg.target_id,
								msg: msgPojo,
								myInfoId: obj.myInfo.id,
							})
						);

						obj.putMsgToConversations([msgPojo]);
						resolve(data.code);
					}
				})
				.onFail(() => {});
		});
	}
}
