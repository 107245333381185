import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
import { UtilService } from 'src/app/services/util.service';
import { WeChatService } from 'src/app/services/WeChat.service';
import { environment } from 'src/environments/environment';
import { EventService } from '../../../../services/event.service';
import { TeacherService } from '../../../../services/teacher.service';
import { AuthService } from './../../../../services/auth.service';
import { GlobalService } from './../../../../services/global.service';
import { SettingsService } from './../../../../services/settings.service';

@Component({
  selector: 'app-event-pay',
  templateUrl: './eventpay.page.html',
  styleUrls: ['./eventpay.page.scss'],
})
export class EventpayPage implements OnInit {
  teacherDetails: any = [];
  alreadyLoggedIn = false;
  showCommentBox;
  comment;
  rate;
  myInfo: any = {};

  eventSource = [];
  changedCalendarSource = [];
  addedEventSource = [];
  deletedEventSource = [];
  viewTitle;

  isToday: boolean;
  dayEventSource = [];

  page = 1;
  teacher;

  calendar = {
    mode: 'month',
    currentDate: new Date(),
    dateFormatter: {
      formatMonthViewDay: (date: Date) => date.getDate().toString(),
      formatMonthViewDayHeader: (date: Date) => 'MonMH',
      formatMonthViewTitle: (date: Date) => 'testMT',
      formatWeekViewDayHeader: (date: Date) => 'MonWH',
      formatWeekViewTitle: (date: Date) => 'testWT',
      formatWeekViewHourColumn: (date: Date) => 'testWH',
      formatDayViewHourColumn: (date: Date) => 'testDH',
      formatDayViewTitle: (date: Date) => 'testDT',
    },
  };
  previousUrl;
  currentUrl;
  eventOwnerId;
  myId;
  event: any = {
    _id: '',
    id: '',
    review: [],
    introduction: '',
    likedBy: [],
    owner: {
      nickname: '',
      imageURL: '',
    },
    URL: null,
    imageURL: '',
    shopURL: '',
    category: {
      main: '',
    },
  };
  reservationPayment: any = {};

  backparams: any = {};
  constructor(
    private http: HttpClient,
    private weChatService: WeChatService,
    private teacherService: TeacherService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private globalService: GlobalService,
    private settingsService: SettingsService,
    public platform: Platform,
    public alertCtrl: AlertController,
    private eventService: EventService,
    private utilService: UtilService,
    private navCtrl: NavController,
    private _location: Location
  ) {
    console.log(this.activatedRoute.snapshot.paramMap);
    this.myId = this.activatedRoute.snapshot.paramMap.get('myId');
    this.eventOwnerId =
      this.activatedRoute.snapshot.paramMap.get('eventOwnerId');
    // console.log(this.activatedRoute.snapshot.paramMap)
    this.backparams = JSON.parse(decodeURIComponent(this.activatedRoute.snapshot.paramMap.get('backparams'))) 
    if (this.backparams?.status === 'EventSuccess') {
      this.event = this.backparams.bigEvent
      this.reservationPayment.contact = this.backparams.contact
      this.newPayment()
    }
    
    this.previousUrl =
      this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
      this.activatedRoute.snapshot.paramMap.get('p') === null
        ? '/tabs/event'
        : this.activatedRoute.snapshot.paramMap.get('p');
    this.currentUrl = this.router.url;

    this.eventService.getEventDetails(this.myId).subscribe((data: any) => {
      this.event = this.utilService.ISODateToInput(data);
    });
  }

  openTeacherDetailsPage(teacher) {}
  ngOnInit() {
    this.authService.getMe().subscribe((res) => {
      console.log(res);
      if (res.token) {
        this.alreadyLoggedIn = true;
        this.myInfo = res;
        if (this.myInfo.contact) {
          this.reservationPayment.contact = this.myInfo.contact;
        }
      }
    });
  }

  
  wechatPayment() {
    if (!this.reservationPayment.contact) {
      this.globalService.toast(
        this.translate.instant('inputContactPlease'),
        1500,
        'top'
      );
      return;
    } else {
        if (!this.validatePhoneNumber(this.reservationPayment.contact)) {
             this.globalService.toast(
                 this.translate.instant('contactErr'),
                 1500,
                 'top'
             );
             return;
        }
     };
    
    if (Number(this.event.price) === 0) {
      this.payment();
      return;
    }
    if (this.event.client.indexOf(this.myInfo._id) !== -1) {
        this.globalService.toast(
          this.translate.instant('User already booked this bigEvent'),
          1500,
          'top'
        );
        return;
    }

    if (this.event.client.length === this.event.clientLimit) {
        this.globalService.toast(
            this.translate.instant('No vacant seat'),
            1500,
            'top'
          );
        return;
    }
    let that:any = window;
		let orderId = Date.now();//这里用当前时间戳做订单号（后面使用你自己真实的订单号）
    let money = this.event.price;//订单总金额（单位分）
    

    let payData = {
      orderId: orderId,
      money: money,
      userId: this.myId,
      type: 'event',
      bigEvent: this.event,
      contact: this.reservationPayment.contact,
    };
    console.log(payData,'paydata')
    let payDataStr = JSON.stringify(payData);//参数传递给小程序，转为字符串
    console.log(JSON.parse(payDataStr))
    const url = `../wePay/wePay?payDataStr=${encodeURIComponent(payDataStr)}`;
    if (!this.weChatService.isWeChat) {
        this.globalService.toast(this.translate.instant('wechatpay'), 1500, 'top')
    } else {
        that.wx.miniProgram.navigateTo({
            url:url
        })
    }
  }

  payment() {
    console.log('paymentStart');
    console.log(this.reservationPayment.contact) //联系方式
    if (!this.reservationPayment.note) {
      this.reservationPayment.note = ' ';
    }

    if (this.reservationPayment.contact) {
      this.http
        .post(environment.defaultURL + '/reservation/eventPurchase', {
          bigEvent: this.event,
          contact: this.reservationPayment.contact,
        })
        .subscribe(
          (data) => {
            this.globalService.toast(
              this.translate.instant('success'),
              1500,
              'top'
            );
            this._location.back();
          },
          (err) => {
            console.log(err);
            if (err.error) {
              this.globalService.toast(
                this.translate.instant(err.error.message),
                1500,
                'top'
              );
            }
          }
        );
    } else {
      this.globalService.toast(
        this.translate.instant('inputContactPlease'),
        1500,
        'top'
      );

      // this.nav.push(MyInformationChange, {'name': 'contact', 'value': ''});
    }
  }
    validatePhoneNumber(phoneNumber) {
        let regex = /^1\d{10}$/;
        return regex.test(phoneNumber);
    }
  newPayment() {
    console.log('paymentStart');
    console.log(this.reservationPayment.contact) //联系方式
    if (!this.reservationPayment.note) {
      this.reservationPayment.note = ' ';
    }

    if (this.reservationPayment.contact) {
      this.http
        .post(environment.defaultURL + '/reservation/eventPurchase', {
          bigEvent: this.event,
          contact: this.reservationPayment.contact,
        })
        .subscribe(
          (data) => {
            this.globalService.toast(
              this.translate.instant('success'),
              1500,
              'top'
            );
            this._location.back();
          },
          (err) => {
            console.log(err);
            if (err.error) {
              this.globalService.toast(
                this.translate.instant(err.error.message),
                1500,
                'top'
              );
            }
          }
        );
    } else {
      this.globalService.toast(
        this.translate.instant('inputContactPlease'),
        1500,
        'top'
      );

      // this.nav.push(MyInformationChange, {'name': 'contact', 'value': ''});
    }
  }
}
