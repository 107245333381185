import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { AppState } from 'src/app/store';
import {
	loadConversationsError,
	loadConversationsStart,
	loadConversationsSuccess,
	updateConversionStart,
} from '../actions/conversations.action';
import { addMessage } from '../actions/jMessage.action';

@Injectable()
export class ConversationsEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private userService: UserService, // we will need this service for API calls
		private store: Store<AppState>
	) {}

	loadConversationsStart$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(loadConversationsStart),
			switchMap((action) => {
				console.log(action);
				return this.userService.getHeadImages({ ids: action.payload.ids }).pipe(
					map((idWithImages: any) => {
						console.log(idWithImages);
						return loadConversationsSuccess({
							payload: {
								myInfoId: action.payload.id,
								idWithIMages: idWithImages.filter((item) => item.imageURL),
							},
						});
					}),
					catchError(() => of(loadConversationsError()))
				);
			})
		);
	});

	//当addMessage发生的时候，就dispatch addConversationItem.
	addConversationItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(addMessage),
			map((payload) => updateConversionStart(payload))
		);
	});
}
