import { createReducer, on } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { loadVideosError, loadVideosStart, loadVideosSuccess, loadNextPageVideosStart } from '../actions/videos.action';
//状态名称
export const videosKey = 'videos';

//状态类型接口
export interface RequestParams {
	limit?: number;
	page?: number;

	category?: any;

	city?: any;

	ownerId?: any;

	reviewCount?: number;

	nickname?: string;

	introduction?: string;
}
export interface State {
	requestParams: RequestParams;
	data: any[];
	status: string;
	noMoreData: boolean;
}

// 初始状态
export const initialState: State = {
	requestParams: {
		limit: environment.perPage,
		page: 1,
	},
	data: [],
	status: 'idle',
	noMoreData: false,
};

//创建reducer函数
export const videosReducer = createReducer(
	initialState,
	//保存 request params
	on(loadVideosStart, (state, action) => {
		return {
			...state,
			status: 'started',
			requestParams: action.payload,
		};
	}),
	on(loadNextPageVideosStart, (state) => {
		return {
			...state,
			status: 'started',
		};
	}),
	//保存结果
	on(loadVideosSuccess, (state, action) => {
		return {
			...state,
			status: 'success',
			noMoreData: action.noMoreData,
			data: action.payload,
		};
	}),
	on(loadVideosError, (state) => ({
		...state,
		status: 'fail',
	}))
);
