import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';
import { ChangeObserverService } from '../../../services/changeObserver.service';
import { GlobalService } from '../../../services/global.service';
import { OrgService } from '../../../services/org.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-apply-for-secretary',
	templateUrl: './apply-for-secretary.page.html',
	styleUrls: ['./apply-for-secretary.page.scss'],
})
export class ApplyForSecretaryPage implements OnInit {
	alreadyLoggedIn = false;
	myInfo: any = {};
	previousUrl;
	currentUrl;
	certificates = null;

	constructor(
		public authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private globalService: GlobalService,
		private changeObserver: ChangeObserverService,
		private userService: UserService,
		private orgService: OrgService,

		private translate: TranslateService,
		public navCtrl: NavController,
		private _location: Location
	) {
		const parsedParam = this.activatedRoute.snapshot.paramMap;
		this.previousUrl =
			parsedParam.get('p') === 'undefined' || parsedParam.get('p') === null
				? '/tabs/settings/myInformation'
				: parsedParam.get('p');
		this.currentUrl = this.router.url;
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ngOnInit() {
		this.authService.getMe().subscribe((res) => {
			if (res.token) {
				this.alreadyLoggedIn = true;
				this.myInfo = res;
			}
		});
	}

	applyForSecretary() {
		this.orgService.applyForSecretary(this.myInfo).subscribe((data) => {
			console.log(data);
			this.globalService.toast(this.translate.instant('successfullyUpdated'), 1500, 'top');
			this.router.navigate([this.previousUrl]);
		},
        (err)=> {
            this.globalService.toast(this.translate.instant('err'), 1500, 'top');
        });
	}
}
