import { createReducer, on } from '@ngrx/store';
import {
	loadMyAppointmentsError,
	loadMyAppointmentsStart,
	loadMyAppointmentsSuccess,
	submitCommentError,
	submitCommentStart,
	submitCommentSuccess,
} from '../actions/myAppointments.action';

export const myAppointmentsKey = 'myAppointments';

//状态类型接口
export interface RequestParams {
	limit?: number;
	page?: number;

	category?: any;

	city?: any;

	ownerId?: any;

	reviewCount?: number;

	nickname?: string;

	introduction?: string;
}
export interface State {
	data: any[];
	status: string;
}

// 初始状态
export const initialState: State = {
	data: [],
	status: 'idle',
};

//创建reducer函数
export const myAppointmentsReducer = createReducer(
	initialState,
	//保存 request params
	on(loadMyAppointmentsStart, (state) => ({
		...state,
		status: 'started',
	})),
	//保存结果
	on(loadMyAppointmentsSuccess, (state, action) => ({
		...state,
		status: 'success',
		data: action.payload,
	})),
	on(loadMyAppointmentsError, (state) => ({
		...state,
		status: 'fail',
	})),
	//保存 request params
	on(submitCommentStart, (state) => ({
		...state,
		status: 'started',
	})),
	//保存结果
	on(submitCommentSuccess, (state, action) => {
		const newData = state.data.map((x) =>
			x._id === action.payload._id
				? {
						...x,
						advice: action.payload.advice,
						prescription: action.payload.prescription,
						condition: action.payload.condition,
				  }
				: x
		);
		return {
			...state,
			status: 'success',
			data: newData,
		};
	}),
	on(submitCommentError, (state) => ({
		...state,
		status: 'fail',
	}))
);
