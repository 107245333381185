import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CalendarMode, Step } from 'ionic2-calendar/calendar';
import * as moment from 'moment';
import 'moment-timezone';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { Globals } from '../../../../globals';
import { TeacherService } from '../../../../services/teacher.service';
import { AuthService } from './../../../../services/auth.service';
import { GlobalService } from './../../../../services/global.service';
import { SettingsService } from './../../../../services/settings.service';

@Component({
    selector: 'app-makere-servation',
    templateUrl: './makereservation.page.html',
    styleUrls: ['./makereservation.page.scss'],
})
export class MakereservationPage implements OnInit {
    teacherDetails: any = [];
    showCommentBox;
    comment;
    rate;

    eventSource = [];
    changedCalendarSource = [];
    addedEventSource = [];
    deletedEventSource = [];
    viewTitle;

    isToday: boolean;
    dayEventSource = [];

    page = 1;
    teacher;

    calendar = {
        mode: 'month' as CalendarMode,
        step: 30 as Step,
        currentDate: new Date(),
        dateFormatter: {
            formatMonthViewDay: (date: Date) => date.getDate().toString(),
            formatMonthViewDayHeader: (date: Date) => 'MonMH',
            formatMonthViewTitle: (date: Date) => 'testMT',
            formatWeekViewDayHeader: (date: Date) => 'MonWH',
            formatWeekViewTitle: (date: Date) => 'testWT',
            formatWeekViewHourColumn: (date: Date) => 'testWH',
            formatDayViewHourColumn: (date: Date) => 'testDH',
            formatDayViewTitle: (date: Date) => 'testDT',
        },
    };
    previousUrl;
    currentUrl;
    serviceOwnerId;
    myId;
    alreadyLoggedIn = false;
    myInfo: any = {};

    constructor(
        private http: HttpClient,
        private teacherService: TeacherService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private globalService: GlobalService,
        private settingsService: SettingsService,
        public platform: Platform,
        public alertCtrl: AlertController,
        public globals: Globals,
        public navCtrl: NavController,
        private _location: Location,
        private store: Store<AppState>
    ) {
        // this.myInfo = globals.myInfo;
        // this.alreadyLoggedIn = globals.alreadyLoggedIn;
        this.store
            .pipe(select('login'))
            .pipe(first())
            .subscribe((res) => {
                this.myInfo = res.myInfo;
                this.alreadyLoggedIn = res.status === 'success';
            });
        console.log(this.activatedRoute.snapshot.paramMap);
        this.myId = this.activatedRoute.snapshot.paramMap.get('myId');
        this.serviceOwnerId = this.activatedRoute.snapshot.paramMap.get(
            'serviceOwnerId'
        );
        this.previousUrl =
            this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
            this.activatedRoute.snapshot.paramMap.get('p') === null
                ? '/tabs/service'
                : this.activatedRoute.snapshot.paramMap.get('p');
        console.log(this.previousUrl);

        console.log('/tabs/service/servicedetails/' + this.serviceOwnerId);
        this.currentUrl = this.router.url;

        this.teacherService.getTeacherDetails(this.serviceOwnerId).subscribe(
            (res2: any) => {
                console.log(res2);
                const data2 = this.changeISOtoDate(res2.freeTime);
                this.eventSource = [].concat(data2);
            },
            (err) => {
                if (err.error) {
                    this.globalService.toast(
                        this.translate.instant(err.error.message),
                        1500,
                        'top'
                    );
                }
            }
        );
    }

    onSwipeLeft($event) {
        this.navCtrl.setDirection('back', true, 'back');
        this._location.back();
    }

    onSwipeRight($event) {}

    ngOnInit() {}

    changeISOtoDate(data2) {
        const data3 = [];
        data2.forEach((element, index) => {
            if (element) {
                if (!element._id) {
                    element._id = this.generateId();
                }
                data3.push({
                    _id: element._id,
                    startTime: moment(element.startTime).toDate(),
                    endTime: moment(element.endTime).toDate(),
                });
            }
        });
        return data3;
    }

    generateId() {
        const ObjectId = (
            m = Math,
            d = Date,
            h = 16,
            s = (seconds) => m.floor(seconds).toString(h)
        ) =>
            s(d.now() / 1000) +
            ' '.repeat(h).replace(/./g, () => s(m.random() * h));
        return ObjectId;
    }

    changeMode(mode) {
        this.calendar.mode = mode;
    }

    today() {
        this.calendar.currentDate = new Date();
    }

    onViewTitleChanged(title) {
        this.viewTitle = title;
    }

    onCalendarSelected(calendar) {}

    onEventSelected(event) {}

    async presentAlertConfirm1(ev) {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('makereservation?'),
            message: '',
            buttons: [
                {
                    text: this.translate.instant('OK'),
                    handler: () => {
                        const hours = Number(99999);
                        this.createCalendars(ev, hours);
                    },
                },
                {
                    text: this.translate.instant('cancel'),
                    handler: () => {},
                },
            ],
        });
        await alert.present();
    }

    async presentAlertConfirm2(ev) {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('cancellReservation?'),
            message: 'cancelReservation?',
            buttons: [
                {
                    text: this.translate.instant('OK?'),
                    handler: () => {
                        this.createCalendars(ev, 100000);
                    },
                },
                {
                    text: 'cancel',
                    handler: () => {},
                },
            ],
        });
        await alert.present();
    }

    onTimeSelected(ev) {
        if (this.calendar.mode === 'month') {
        } else if (this.calendar.mode === 'week') {
            let alertType = 0;
            let theIndex;
            for (
                let i = 0;
                i < this.eventSource.length && alertType === 0;
                i++
            ) {
                if (
                    ev.selectedTime.getTime() ===
                    this.eventSource[i].startTime.getTime()
                ) {
                    if (this.eventSource[i].title === '✔') {
                        alertType = 2;
                    } else {
                        alertType = 1;
                    }
                    theIndex = i;
                }
            }

            if (alertType === 1) {
                this.presentAlertConfirm1(ev);
            } else if (alertType === 2) {
                this.presentAlertConfirm2(ev);
            }
        }
    }

    createCalendars(ev, h: number) {
        if (h < 100000) {
            this.eventSource.forEach((elementEvent, index) => {
                if (
                    ev.selectedTime.getTime() ===
                    elementEvent.startTime.getTime()
                ) {
                    const startDate = moment(ev.selectedTime.getTime());
                    const endData = moment(elementEvent.endTime.getTime());
                    this.addedEventSource.push({
                        _id: elementEvent._id,
                        startTime: startDate,
                        endTime: endData,
                    });
                    this.eventSource[index].title = '✔';
                }
            });
            this.eventSource = [].concat(this.eventSource);
            this.setReservationDetails();
        } else if (h === 100000) {
            this.eventSource.forEach((elementEvent, index) => {
                if (
                    ev.selectedTime.getTime() ===
                    elementEvent.startTime.getTime()
                ) {
                    this.eventSource[index].title = '';
                }
            });
            this.addedEventSource.forEach((addedEvent, addedIndex) => {
                if (
                    ev.selectedTime.getTime() === addedEvent.startTime.getTime()
                ) {
                    this.addedEventSource.splice(addedIndex, 1);
                }
            });
        }
    }

    onCurrentDateChanged(calendar: Date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    }

    onRangeChanged(ev) {}

    setReservationDetails() {
        this.changedCalendarSource = []
            .concat(this.addedEventSource)
            .concat(this.deletedEventSource);

        // if (this.changedCalendarSource.length > 0)
        //   this.nav.push(MakeAppointmentDetails, {
        //     addedEventSource: this.addedEventSource,
        //     teacher: this.teacher,
        //     serviceDetails: this.serviceDetails
        //   });
    }

    doRefresh(refresher) {
        if (this.alreadyLoggedIn === true) {
            setTimeout(() => {
                this.teacherService.myCalendarAsTeacher().subscribe((data2) => {
                    let data3 = [];
                    data3 = this.changeISOtoDate(data2);
                    if (data3[0]) {
                        if (!this.myInfo.eventSource) {
                            this.myInfo.eventSource = []; //新建reducer
                        }
                        this.eventSource = this.myInfo.eventSource.concat(
                            data3
                        );
                    }
                });
                refresher.complete();
            }, 1000);
        } else {
            setTimeout(() => {
                this.globalService.toast(
                    this.translate.instant('loginPlease'),
                    1500,
                    'top'
                );
                refresher.complete();
            }, 1000);
        }
    }

    filterItems(selectedDate) {
        this.dayEventSource = [];

        this.eventSource.forEach((elementEvent, index) => {
            if (
                moment(selectedDate.date).month() ===
                    moment(elementEvent.startTime).month() &&
                moment(selectedDate.date).date() ===
                    moment(elementEvent.startTime).date()
            ) {
                this.dayEventSource.push(elementEvent);
            }
        });
    }

    checkDetails(ev) {
        //this.calendar.mode = "week"

        this.addedEventSource.push({
            _id: ev._id,
            startTime: ev.startTime,
            endTime: ev.endTime,
        });
        console.log(this.addedEventSource);

        this.setReservationDetails();
    }
}
