import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { AppState } from 'src/app/store';
import { Globals } from '../../../../globals';
import { CityService } from '../../../../services/city.service';
import { EventService } from '../../../../services/event.service';
import { ImageProcessService } from '../../../../services/imageProcess.service';
import { AuthService } from './../../../../services/auth.service';
import { GlobalService } from './../../../../services/global.service';
import { SettingsService } from './../../../../services/settings.service';
import { first } from 'rxjs/operators';

declare let Qiniu: any;

@Component({
  selector: 'app-update-event',
  templateUrl: './updateEvent.page.html',
  styleUrls: ['./updateEvent.page.scss'],
})
export class UpdateEventPage implements OnInit {
  @ViewChild('imageButton', { static: false }) selectImage;
  @ViewChild('faceImageButton', { static: false }) selectFaceImage;

  PointDefiner: any;
  Warper: any;
  Point: any;
  Animator: any;
  teacherId: string;
  password: string;
  category = '全部';
  categoryData = [];

  event: any = {
    event: {
      video: {
        URL: '',
      },
      category: '',
    },
    owner: {},
  };
  eventId;
  uploadedImg = { data: undefined };
  f = {
    api_key: '0ef14fa726ce34d820c5a44e57fef470',
    api_id: undefined,
    api_secret: '4Y9YXOMSDvqu1Ompn9NSpNwWQFHs1hYD',
    img: undefined,
    face_id: undefined,
    category: undefined,
  };
  year: number;
  month: number;
  day: number;
  buttonDisabled = false;
  deleteButtonDisabled = true;
  selected1;

  previousUrl;
  currentUrl;
  deviceTypeIsIpad = false;
  alreadyLoggedIn = false;
  myInfo: any = {};

  constructor(
    private http: HttpClient,
    private eventService: EventService,
    private cityService: CityService,

    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private globalService: GlobalService,
    private settingsService: SettingsService,
    private utilService: UtilService,
    public platform: Platform,
    public globals: Globals,
    private imageProcess: ImageProcessService,
    public navCtrl: NavController,
    private _location: Location,
    private store: Store<AppState>
  ) {
    // this.myInfo = globals.myInfo;
    // this.alreadyLoggedIn = globals.alreadyLoggedIn;
    this.store
      .pipe(select('login'))
      .pipe(first())
      .subscribe((res) => {
        this.myInfo = res.myInfo;

        this.alreadyLoggedIn = res.status === 'success';
      });
    if (this.platform.is('ipad')) {
      this.deviceTypeIsIpad = true;
    }
    this.eventId = this.activatedRoute.snapshot.paramMap.get('myId');
    this.previousUrl =
      this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
      this.activatedRoute.snapshot.paramMap.get('p') === null
        ? '/tabs/settings'
        : this.activatedRoute.snapshot.paramMap.get('p');
  }
  onCategoryOk(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    const myString = value.map((v) => v).join(',');
    this.category = myString.includes(',全部')
      ? myString.substring(0, myString.indexOf(',全部'))
      : myString;
    this.event.category = this.category;
    console.log(this.event);
  }
  onSwipeLeft($event) {
    this.navCtrl.setDirection('back', true, 'back');
    this._location.back();
  }

  onSwipeRight($event) {}

  ngOnInit() {
    try {
      if (this.eventId !== undefined) {
        this.eventService.getEventDetails(this.eventId).subscribe((res) => {
          this.event = res;
          this.selected1 = this.event.category;
          this.event.price /= 100;
          this.event.priceBeforeDiscount /= 100;
          console.log(this.event);
          this.event = this.utilService.ISODateToInput(this.event);
          this.category = this.event.category;
          if (this.event.client.length === 0) {
            this.deleteButtonDisabled = false;
          }
        });
      }
      this.activatedRoute.url.subscribe(async () => {
        [this.categoryData] = await this.utilService.changeLanguage();
        [, this.category] = this.utilService.setAlias();
      });
    } catch (err) {
      console.error(err);
    }
  }

  placeholderValue() {
    if (this.event.category) {
    } else {
      return null;
    }
  }

  replaceEvent() {
    this.buttonDisabled = true;
    if (
      this.event.id &&
      this.event.price &&
      this.event.startTime &&
      this.event.endTime
    ) {
      //  var pricePerHour = this.validation.pricePerHour || 1000
      //  var duration = moment.duration(moment(this.event.endTime).diff(moment(this.event.startTime)));
      //  var hours = duration.asHours();
      if (this.event.endTime <= this.event.startTime) {
        this.globalService.toast(
          this.translate.instant(
            'Please change the event start time and end time'
          ),
          1500,
          'top'
        );
        this.buttonDisabled = false;
      } else {
        this.event.action = 'put';
        const paymentInfo = JSON.parse(JSON.stringify(this.event));
        paymentInfo.price = 100 * paymentInfo.price;
        paymentInfo.priceBeforeDiscount = 100 * paymentInfo.priceBeforeDiscount;
        const withoutImageURL = JSON.parse(JSON.stringify(paymentInfo));

        if (withoutImageURL.imageKey) {
          withoutImageURL.imageURL = '';
        }
        console.log(withoutImageURL);
        this.eventService.putEvent(withoutImageURL).subscribe(
          (data) => {
            this.buttonDisabled = false;
            this.globalService.toast(
              this.translate.instant('success'),
              1500,
              'top'
            );
            this.navCtrl.navigateBack(this.previousUrl);
          },
          (err) => {
            if (err.error.errorCode === 2002) {
              this.globalService.toast(
                this.translate.instant('eventName'),
                1500,
                'top'
              );
              this.buttonDisabled = false;
            }
          }
        );
      }
    } else {
      this.globalService.toast(
        this.translate.instant(
          'Please login first, then input name, start time, end time and price!'
        ),
        1500,
        'top'
      );
      this.buttonDisabled = false;
    }
  }

  delete() {
    this.deleteButtonDisabled = false;
    if (this.eventId) {
      this.eventService.deleteEvent(this.eventId).subscribe(
        (data) => {
          this.globalService.toast(
            this.translate.instant('success'),
            1500,
            'top'
          );
          this.buttonDisabled = false;
          this.navCtrl.navigateBack(this.previousUrl);
        },
        (err) => {
          this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
          this.buttonDisabled = false;
        }
      );
    } else {
      this.globalService.toast(this.translate.instant('err'), 1500, 'top');
      this.deleteButtonDisabled = true;
    }
  }

  uploadImageTrigger() {
    this.imageProcess.uploadToQiniu('uploadImage', '').then((data) => {
      this.event.imageKey = data.imageKey;
      this.event.imageURL = data.imageURL;
    });
  }
}
