import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../../services/event.service';
import { ServiceService } from '../../../../services/service.service';
import { TeacherService } from '../../../../services/teacher.service';
import { VideoService } from '../../../../services/video.service';

@Component({
	selector: 'app-teacher-certificate',
	templateUrl: './teacherCertificate.page.html',
	styleUrls: ['./teacherCertificate.page.scss'],
})
export class TeacherCertificatePage implements OnInit {
	page = 1;
	previousUrl;
	currentUrl;

	allTeacherVideos: any = [];
	allTeacherServices: any = [];
	allTeacherBigEvents: any = [];

	teacherCertificate: any = [];
	teacherId;
	serviceType;
	searchString = {};
	category = null;
	subCategory = null;
	orgId = null;
	certificate = {
		id: '',
		imageURL: '',
	};

	constructor(
		private http: HttpClient,
		private teacherService: TeacherService,
		private serviceService: ServiceService,
		private videoService: VideoService,
		private eventService: EventService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private navCtrl: NavController,
		private _location: Location
	) {
		this.activatedRoute.queryParams.subscribe((data) => {
			this.certificate = JSON.parse(data.certificate);
		});

		this.currentUrl = this.router.url;
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/teachers'
				: this.activatedRoute.snapshot.paramMap.get('p');
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {}
}
