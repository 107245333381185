import { Location, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { formatISO } from 'date-fns';
import * as moment from 'moment';
import 'moment-timezone';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../../../globals';
import { GlobalService } from '../../../../../services/global.service';
import { WeChatService } from '../../../../../services/WeChat.service';

@Component({
    selector: 'app-reservation-details',
    templateUrl: './reservationdetails.page.html',
    styleUrls: ['./reservationdetails.page.scss'],
})
export class ReservationdetailsPage implements OnInit {
    serviceId;
    previousUrl;
    currentUrl;

    chatroomId;
    serviceOrTeacher;
    service;
    calendarList: any = {};
    teacher;
    serviceCharge: any = {};

    appointmentISO: any = [];

    localAppointmentISO: any = [];

    serviceType;
    addedCalendarSourceISO = [];
    deletedCalendarSourceISO = [];
    calendarTrigger = true;
    totalPrice;
    minTime = '2021';
    maxTime = '2025';
    myId;
    startTime;
    endTime;
    eventId;
    alreadyLoggedIn = false;
    myInfo: any = {};
  backparams: any = {};

    contact;

    constructor(
        private activatedRoute: ActivatedRoute,
        private weChatService: WeChatService,
        private router: Router,
        private userService: UserService,
        public authService: AuthService,
        private translate: TranslateService,
        private globalService: GlobalService,
        private http: HttpClient,
        public globals: Globals,
        public navCtrl: NavController,
        private _location: Location,
        private store: Store<AppState>
    ) {
        this.store
            .pipe(select('login'))
            .pipe(first())
            .subscribe((res) => {
                this.myInfo = res.myInfo;
                this.contact = this.contact ?? this.myInfo.contact;
                this.alreadyLoggedIn = res.status === 'success';
            });
        console.log(this.activatedRoute.snapshot.paramMap);
        this.myId = this.activatedRoute.snapshot.paramMap.get('myId');
        this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
        this.startTime = this.activatedRoute.snapshot.paramMap.get('startTime');
        this.endTime = this.activatedRoute.snapshot.paramMap.get('endTime');
        this.previousUrl =
            this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
            this.activatedRoute.snapshot.paramMap.get('p') === null
                ? '/tabs/service'
                : this.activatedRoute.snapshot.paramMap.get('p');
        this.currentUrl = this.router.url;
            this.appointmentISO = [
            {
                _id: this.eventId,
                startTime: this.startTime,
                endTime: this.endTime,
            },
        ];
        this.localAppointmentISO = [
            {
                _id: this.eventId,
                startTime: this.setLocalTime(this.startTime),
                endTime: this.setLocalTime(this.endTime),
            },
        ];
        
        this.backparams = JSON.parse(this.activatedRoute.snapshot.paramMap.get('backparams')) 
        if (this.backparams?.status === 'ServiceSuccess') {
            this.appointmentISO[0].startTime = this.backparams.startTime;
            this.appointmentISO[0].endTime = this.backparams.endTime;
            this.startTime = this.serviceCharge.startTime = this.backparams.startTime;
            this.endTime  = this.serviceCharge.endTime = this.backparams.endTime;
            this.serviceCharge.service = this.backparams.service 
            this.serviceCharge.contact = this.backparams.contact;
            this.contact = this.backparams.contact;
            this.makeAppointment()
        }

        
        
    }

    setLocalTime = (time) => {
        // Get the time zone set on the user's device
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const x = formatISO(new Date(time));
        console.log(x.substring(0, x.lastIndexOf('+')));
        return x.substring(0, x.lastIndexOf('+'));
    };

    onSwipeLeft($event) {
        this.navCtrl.setDirection('back', true, 'back');
        this._location.back();
    }

    onSwipeRight($event) {}

    ngOnInit() {}

    cancelReservation(x) {
        for (let i = 0; i < this.appointmentISO.length; i++) {
            if (i === x) {
                this.appointmentISO.splice(i, 1);
            }
        }
    }

    wechatPayment() {
        if (!this.contact) {
            this.globalService.toast(
                this.translate.instant('needContactInfo'),
                1500,
                'top'
            );
            return;
        } else {
           if (!this.validatePhoneNumber(this.contact)) {
                this.globalService.toast(
                    this.translate.instant('contactErr'),
                    1500,
                    'top'
                );
                return;
           }
        };
        let that:any = window;
        let orderId = Date.now();//这里用当前时间戳做订单号（后面使用你自己真实的订单号）
        let payData = {
            orderId: orderId,
            startTime: moment(this.appointmentISO[0].startTime).toString(),  
            endTime:  moment(this.appointmentISO[0].endTime).toString(),
            money: 2000,
            type: 'services',
            service: { _id: this.myId },
            contact: this.contact,
        };
        console.log(payData,'paydata')
        let payDataStr = JSON.stringify(payData);//参数传递给小程序，转为字符串
        console.log(JSON.parse(payDataStr))
        const url = `../wePay/wePay?payDataStr=${encodeURIComponent(payDataStr)}`;
        
        if (!this.weChatService.isWeChat) {
            this.globalService.toast(this.translate.instant('wechatpay'), 1500, 'top')
        } else {
            that.wx.miniProgram.navigateTo({
                url:url
            })
        }
    }

    makeAppointment() {
        console.log('clicked');

        this.serviceCharge.startTime = this.appointmentISO[0].startTime;
        this.serviceCharge.endTime = this.appointmentISO[0].endTime;

        this.serviceCharge.service = { _id: this.myId };
        console.log(this.serviceCharge.contact, this.contact, "here")
        if (!this.contact && this.backparams.contact) {
            this.contact = this.backparams.contact
        }
        if (!this.contact) {
            this.globalService.toast(
                this.translate.instant('needContactInfo'),
                1500,
                'top'
            );
        } else {
            // this.serviceCharge.contact = this.contact;
            console.log(this.serviceCharge);
            console.log(environment.defaultURL);
            this.http
                .post(
                    environment.defaultURL + '/reservation/servicePurchase',
                    this.serviceCharge
                )
                .subscribe(
                    (data) => {
                        this.globalService.toast(
                            this.translate.instant('success'),
                            1500,
                            'top'
                        );
                        this._location.back();
                    },
                    (err) => {
                        console.log(err);
                        if (err.error) {
                            this.globalService.toast(
                                this.translate.instant(err.error.message),
                                1500,
                                'top'
                            );
                        }
                    }
                );
        }
    }

    testJSON(text) {
        if (typeof text !== 'string') {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    validatePhoneNumber(phoneNumber) {
        let regex = /^1\d{10}$/;
        return regex.test(phoneNumber);
    }

    recaculateTotalDefaultPrice() {
        this.totalPrice = 0;
        const duration = moment.duration(
            moment(this.appointmentISO[0].endTime).diff(
                moment(this.appointmentISO[0].startTime)
            )
        );
        const hours = duration.asHours();
        const price = this.service.price * hours;
        this.totalPrice += price;
        this.serviceCharge.startTime = this.appointmentISO[0].startTime;
        this.serviceCharge.endTime = this.appointmentISO[0].endTime;
    }

    ionViewWillLeave() {
        // this.events.publish('calendar', this.appointmentISO);
    }
}
