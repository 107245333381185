import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare let JMessage: any;

@Injectable()
export class ChangeObserverService {
	dataObserved = new BehaviorSubject<any>('');
	currentEvent = this.dataObserved.asObservable();

	constructo() {}

	publish(param): void {
		this.dataObserved.next(param);
	}
}
