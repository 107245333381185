import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { CityService } from 'src/app/services/city.service';
import { AppState } from 'src/app/store';
import { Globals } from '../../../../globals';
import { AuthService } from '../../../../services/auth.service';
import { ChangeObserverService } from '../../../../services/changeObserver.service';
import { GlobalService } from '../../../../services/global.service';
import { ImageProcessService } from '../../../../services/imageProcess.service';
import { OrgService } from '../../../../services/org.service';
import { UserService } from '../../../../services/user.service';
import { UtilService } from '../../../../services/util.service';

@Component({
  selector: 'app-update-org-info',
  templateUrl: './updateOrgInfo.page.html',
  styleUrls: ['./updateOrgInfo.page.scss'],
})
export class UpdateOrgInfoPage implements OnInit {
  @ViewChild('imageButton', { static: false }) selectImage;

  previousUrl;
  currentUrl;
  certificates = null;
  alreadyLoggedIn = false;
  myInfo;
  orgInfo: any = {};
  uploadedImg = { data: undefined };
  buttonDisabled = false;
  selected1;

  deviceTypeIsIpad = false;
  members: any = [];
  org_id;
  category;
  categoryData;

  constructor(
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private router: Router,
    private globalService: GlobalService,
    private changeObserver: ChangeObserverService,
    public platform: Platform,
    private userService: UserService,
    private orgService: OrgService,
    private cityService: CityService,

    private translate: TranslateService,
    public globals: Globals,
    private imageProcess: ImageProcessService,
    public navCtrl: NavController,
    private _location: Location,
    private store: Store<AppState>
  ) {
    // this.myInfo = globals.myInfo;
    // this.alreadyLoggedIn = globals.alreadyLoggedIn;
    this.store
      .select('login')
      .pipe(first())
      .subscribe((res) => {
        this.myInfo = res.myInfo;
        this.alreadyLoggedIn = res.status === 'success';
      });
    this.store.select('manageOrgs').subscribe((res: any) => {
      this.members = res.data;
    });

    const parsedParam = this.activatedRoute.snapshot.paramMap;
    this.previousUrl =
      parsedParam.get('p') === 'undefined' || parsedParam.get('p') === null
        ? '/tabs/settings/myInformation'
        : parsedParam.get('p');
    this.currentUrl = this.router.url;
    if (this.platform.is('ipad')) {
      this.deviceTypeIsIpad = true;
    }

    this.org_id = this.activatedRoute.snapshot.paramMap.get('org_id');

    this.members.forEach((member) => {
      if (member._id === this.org_id) {
        this.orgInfo = JSON.parse(JSON.stringify(member));
        this.category = this.orgInfo.category;
      }
    });
  }

  public trackItem(index, item) {
    return item.trackId;
  }
  onSwipeLeft($event) {
    this.navCtrl.setDirection('back', true, 'back');
    this.navCtrl.navigateBack('/tabs/settings');
  }

  onSwipeRight($event) {}

  ngOnInit() {
    try {
      this.activatedRoute.url.subscribe(async () => {
        [this.categoryData] = await this.utilService.changeLanguage();
      });
    } catch (err) {
      console.error(err);
    }
  }

  tryUpdateOrg() {
    if (this.orgInfo.category === '全部') {
      this.globalService.toast(
        this.translate.instant('chooseCategoryPlease'),
        1500,
        'top'
      );
    } else {
      if (this.orgInfo.imageKey) {
        delete this.orgInfo.imageURL;
      }

      this.orgService.tryCreateOrg(this.orgInfo).subscribe(
        (data) => {
          this.globalService.toast(
            this.translate.instant('successfullyUpdated'),
            1500,
            'top'
          );
          this._location.back();
        },
        (e) => {
          if (e.error.errorCode === 6015)
            this.globalService.toast(
              this.translate.instant(e.error.message),
              1500,
              'top'
            );
        }
      );
    }
  }

  onCategoryOk(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    const myString = value.map((v) => v).join(',');
    this.category = myString.includes(',全部')
      ? myString.substring(0, myString.indexOf(',全部'))
      : myString;
    this.orgInfo.category = this.category;
  }

  placeholderValue() {
    if (this.orgInfo.category) {
    } else {
      return null;
    }
  }

  uploadImageTrigger() {
    this.imageProcess.uploadToQiniu('uploadImage', '').then((data) => {
      this.orgInfo.imageKey = data.imageKey;
      this.orgInfo.imageURL = data.imageURL;
    });
  }
}
