import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class TeacherService {
	public perpage = 10;
	queryString;

	constructor(private http: HttpClient, private utilService: UtilService) {}

	get(queryParams: any) {
		const params: HttpParams = this.utilService.makeQueryParams(queryParams);
		return this.http.get(environment.defaultURL + '/user', {
			params,
		});
	}

	adminGetUser(search, page: number, category, city, orgId) {
		this.queryString = this.perpage + '&page=' + page;
		if (category) {
			search.category = category;
		}

		if (city) {
			search.city = city;
		}
		console.log(search);
		if (search && search.startTime) {
			this.queryString += '&startTime=' + search.startTime;
			delete search.startTime;
		}
		if (search && search.endTime) {
			this.queryString += '&endTime=' + search.endTime;
			delete search.endTime;
		}
		if (search) {
			this.queryString += '&search=' + JSON.stringify(search);
		}
		if (orgId) {
			this.queryString += '&orgId=' + orgId;
		}

		console.log(this.queryString);
		return this.http.get(environment.defaultURL + '/user/adminGetUser?limit=' + this.queryString);
	}

	getTeacherDetails(_id) {
		return this.http.get(environment.defaultURL + '/userDetails?_id=' + _id);
	}

	getTeacherSimpleInfo(id) {
		return this.http.get(environment.defaultURL + '/simpleInformation?id=' + id);
	}

	myCalendarAsTeacher() {
		return this.http.get(environment.defaultURL + '/user/getMyCalendarAsTeacher');
	}

	addComment(comment) {
		return this.http.post(environment.defaultURL + '/user/addComment', comment);
	}

	getMyAppointments() {
		return this.http.get<any>(environment.defaultURL + '/user/getMyCalendarAsTeacher');
	}

	submitComment(requestData) {
		return this.http.post(environment.defaultURL + '/reservation', { ...requestData, type: 'comment' });
	}
}
