import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../../globals';
import { ServiceService } from '../../../../services/service.service';
import { AuthService } from './../../../../services/auth.service';
import { GlobalService } from './../../../../services/global.service';

@Component({
    selector: 'app-review-reservation',
    templateUrl: './reviewReservation.page.html',
    styleUrls: ['./reviewReservation.page.scss'],
})
export class ReviewReservationPage implements OnInit {
    reviews: any = [];
    reviewText;
    rate = 4;
    showReviewBox = false;
    showReviewButton = false;
    page = 1;
    infiniteScrollEnd = false;
    reservation;
    myId;
    currentUrl;
    previousUrl;
    alreadyLoggedIn = false;
    myInfo: any = {};

    constructor(
        private http: HttpClient,
        private serviceService: ServiceService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private globalService: GlobalService,
        public globals: Globals,
        public navCtrl: NavController,
        private _location: Location,
        private store: Store<AppState>
    ) {
        // this.myInfo = globals.myInfo;
        // this.alreadyLoggedIn = globals.alreadyLoggedIn;
        this.store
            .pipe(select('login'))
            .pipe(first())
            .subscribe((res) => {
                this.myInfo = res.myInfo;

                this.alreadyLoggedIn = res.status === 'success';
            });
        this.myId = this.activatedRoute.snapshot.paramMap.get('myId');
        this.previousUrl =
            this.activatedRoute.snapshot.paramMap.get('p') === 'undefined'
                ? '/tabs/settings/myCalendarAsUser'
                : this.activatedRoute.snapshot.paramMap.get('p');

        this.currentUrl = this.router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
                console.log(this.previousUrl);
                console.log(this.activatedRoute.snapshot.paramMap);
            }
        });
    }

    onSwipeLeft($event) {
        this.navCtrl.setDirection('back', true, 'back');
        this._location.back();
    }

    onSwipeRight($event) {}

    ngOnInit() {
        this.authService.getMe().subscribe(
            (res) => {
                console.log(res);
                if (res.token) {
                    this.alreadyLoggedIn = true;
                    this.myInfo = res;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    review() {
        const reviewData = {
            review: {
                reservation: this.reservation._id,
                author: this.myInfo,
                text: this.reviewText,
            },
            startTime: this.reservation.startTime,
            endTime: this.reservation.endTime,
        };

        this.http
            .post(environment.defaultURL + '/reviewAndPay', reviewData)
            .subscribe(
                (data) => {
                    this.globalService.toast(
                        this.translate.instant('success'),
                        1500,
                        'middle'
                    );
                    this.reviewText = null;
                    this.showReviewBox = !this.showReviewBox;
                },
                (err) => {
                    this.globalService.toast(
                        this.translate.instant('err'),
                        1500,
                        'middle'
                    );
                }
            );
    }

    onRateChange(e) {
        console.log(e);
    }
}
