import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Globals } from '../globals';
import { AppState } from '../store';

@Component({
	selector: 'app-tabs',
	templateUrl: 'tabs.page.html',
	styleUrls: ['tabs.page.scss'],
})
export class TabsPage {
	routeParamPages: string[] = ['tabs'];
	screenWidth = { value: 0 };

	login$: Observable<any>;

    isHide:boolean = true

	current_tab = 'settings'; // Set this as you default page name

	constructor(
		private router: Router,
		private platform: Platform,
		private store: Store<AppState>,
		public globals: Globals
	) {
		this.navEvents();
		console.log('tabs');
		this.screenWidth.value = window.screen.width;
		this.globals.screenWidth = window.screen.width;
		this.login$ = this.store.select('login');
	}

	setCurrentTab(ev: any) {
		this.current_tab = ev.tab;
	}

	public hideTabs() {
		const tabBar = document.getElementById('myTabBar');
		if (tabBar !== null && tabBar.style.display !== 'none') {
			tabBar.style.display = 'none';
		}
	}

	public showTabs() {
		const tabBar = document.getElementById('myTabBar');
		if (tabBar !== null && tabBar.style.display !== 'flex') {
			tabBar.style.display = 'flex';
		}
	}

	private navEvents() {
		this.router.events
			.pipe(filter((e) => e instanceof NavigationEnd))
			.subscribe((e: any) => {
				this.showHideTabs(e);
			});
	}

	private showHideTabs(e: any) {
		const urlArray = e.url.split('/');
		const pageUrlParent = urlArray[urlArray.length - 2];
		const pageUrl = urlArray[urlArray.length - 1];
		const page = pageUrl.split('?')[0];
		const shouldHide =
			pageUrlParent && this.routeParamPages.indexOf(pageUrlParent) === -1;
        switch (shouldHide) {
            case true:
                this.hideTabs();
                this.isHide = !shouldHide;
                break;
            case false:
                this.showTabs();
                this.isHide = !shouldHide;
                break;
            default:
                this.showTabs();
                break;
        }
		// try {
		// 	setTimeout(
		// 		() => (shouldHide ? this.hideTabs() : this.showTabs()),
		// 		300
		// 	);
		// } catch (err) {}
	}

	//   listenLangChange() {
	//     this.translate.onLangChange
	//         .subscribe(() => {
	//             // 写法一
	//             for(let tab of this.tabs) {
	//                 tab.title = this.translate.instant(`tabsPage.${tab.name}`);
	//             }
	//             // 写法二
	//             let titles = this.translate.instant("tabsPage");
	//             for(let tab of this.tabs) {
	//                 tab.title = titles[ tab.name ];
	//             }
	//         });
	// }
}
