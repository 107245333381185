import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { VideoService } from 'src/app/services/video.service';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { loadVideosError, loadVideosStart, loadVideosSuccess, loadNextPageVideosStart } from '../actions/videos.action';
import { RequestParams } from '../reducer/videos.reducer';

@Injectable()
export class VideosEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private videoService: VideoService, // we will need this service for API calls
		private store: Store<AppState>
	) {}

	loadVideos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadVideosStart),
			map((action) => action.payload),
			switchMap((payload) =>
				this.videoService.get(payload).pipe(
					map((res: any[]) => {
						let limit = payload['limit'] || environment.perPage;
						return loadVideosSuccess({
							payload: res,
							noMoreData: res.length < limit,
						});
					}),
					catchError(() => of(loadVideosError()))
				)
			)
		)
	);

	loadNextPageVideos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadNextPageVideosStart),
			withLatestFrom(this.store.select('videos')),
			//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
			switchMap(([action, oldVideosRedux]) => {
				let requestParams: RequestParams = oldVideosRedux.requestParams;
				let page: number = requestParams['page'] + 1;
				let limit: number = oldVideosRedux['limit'] || environment.perPage;
				let partialVideos: any[] = oldVideosRedux['data'].slice(0, (page - 1) * limit);

				//如果此时发来的是分页请求且已经判断没有数据了，则不再发送请求.否则正常数据
				return oldVideosRedux.noMoreData === true
					? EMPTY
					: this.videoService.get({ ...requestParams, page }).pipe(
							map((res: any[]) => {
								//返回小于limit的时候，nomoredata为true
								return loadVideosSuccess({
									payload: [...partialVideos, ...res],
									noMoreData: res.length < limit,
								});
							}),
							catchError(() => of(loadVideosError()))
					  );
			})
		)
	);
}
