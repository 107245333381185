import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GestureCtrlService } from '../../../services/gesture-ctrl.service';
import { GlobalService } from '../../../services/global.service';
import { LikeService } from '../../../services/like.service';
import { OrgService } from '../../../services/org.service';

@Component({
	selector: 'app-org-details',
	templateUrl: './orgdetails.page.html',
	styleUrls: ['./orgdetails.page.scss'],
})
export class OrgdetailsPage implements OnInit {
	@ViewChild('content') content: ElementRef;

	openOrgReviewPage;
	orgId = null;
	currentUrl;
	previousUrl;
	user;
	toUser;
	screenWidth = { value: 0 };

	org: any = {
		imageURL: '',
		likedBy: [],
		likedCount: 0,
		organization: '',
		nickname: '',
		sex: '',
		age: '',
		reviewNumber: 0,
		introduction: '',
		_id: '',
		id: '',
	};
	certificates = [];
	alreadyLoggedIn = false;
	myInfo: any = {};

    data: Object;

	constructor(
		private http: HttpClient,
		private orgService: OrgService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		public authService: AuthService,
		private router: Router,

		private likeService: LikeService,
		private globalService: GlobalService,
		public globals: Globals,
		public gestureCtrlService: GestureCtrlService,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store.select('login').subscribe((res) => {
			this.myInfo = res.myInfo;
			this.alreadyLoggedIn = res.status === 'success';
		});
        this.orgId = this.activatedRoute.snapshot.paramMap.get('orgId');
        this.data = { _id: this.orgId };
		console.log(this.activatedRoute.snapshot.paramMap);
		console.log(this.orgId);

		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/org'
				: this.activatedRoute.snapshot.paramMap.get('p');
		console.log(this.previousUrl);
		this.currentUrl = this.router.url;
		console.log(this.currentUrl);

		this.orgService.getOrgDetails(this.orgId).subscribe((res: any) => {
			this.org = res;
			console.log(res);
			this.toUser = JSON.stringify({
				_id: res._id,
				imageURL: res.imageURL,
				id: res.id,
			});
			if (res.certificates) {
				this.certificates = res.certificates;
			}

			this.org.likedByUser = this.myInfo.likedTeacher && this.myInfo.likedTeacher.includes(this.org._id);
		});
	}

	openOrgCertificate(e) {}
	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {
		const res = this.myInfo;
		this.user = JSON.stringify({
			_id: res._id,
			imageURL: res.imageURL,
			id: res.id,
		});
	}

	ionViewDidEnter() {
		// console.log('getsture');
		// console.log(this.content);
		// const result = this.gestureCtrlService.useSwiperGesture(this.content);
		// console.log(result);
		// result === 'right' ? this._location.back() : null;
	}

	//        var timestamp = new Date().getTime();

	like(type, target) {
		this.likeService.like(type, target).subscribe((data) => {
			this.org = data;
		});
	}

	unlike(type, target) {
		this.likeService.unlike(type, target).subscribe((data) => {
			this.org = data;
		});
	}

	share() {
		this.globalService.share(this.org);
	}

	goChat(org) {
		this.router.navigate([
			'/tabs/org/orgdetails/',
			org._id,
			'chat',
			org.id,
			org.nickname,
			org._id,
			{ p: this.currentUrl },
		]);
	}
}
