import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AppState } from '../store';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private authService: AuthService,
		public toastController: ToastController,
		private store: Store<AppState>
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.store.select('login').pipe(
			first(),
			switchMap((login: any) => {
				const token = login.myInfo.token;
				if (token) {
					request = request.clone({
						setHeaders: {
							Authorization: token,
							'x-sc-token': token,
							'sc-token': 'token',
						},
					});
				}

				if (!request.headers.has('Content-Type')) {
					request = request.clone({
						setHeaders: {
							'content-type': 'application/json',
						},
					});
				}

				request = request.clone({
					headers: request.headers.set('Accept', 'application/json'),
				});

				return next.handle(request).pipe(
					map((event: HttpEvent<any>) => {
						if (event instanceof HttpResponse) {
							// console.log('event--->>>', event);
						}
						return event;
					}),
					catchError((error: HttpErrorResponse) => {
						if (error.status === 401) {
							if (error.error.success === false) {
								this.presentToast(' failed');
							} else {
								// this.router.navigate(['login']);
								this.authService.logout();
							}
						}
						return throwError(error);
					})
				);
			})
		);
	}

	async presentToast(msg) {
		const toast = await this.toastController.create({
			message: msg,
			duration: 2000,
			position: 'top',
		});
		toast.present();
	}
}
