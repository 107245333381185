import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config, LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { JPush } from '@jiguang-ionic/jpush/ngx';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { Globals } from './globals';
import { AuthService } from './services/auth.service';
import { GlobalService } from './services/global.service';
// import { JMessagePlugin } from '@jiguang-ionic/jMessage/ngx';
import { JMessageService } from './services/jMessage.service';
import { loadSystem } from './store/actions/system.action';

declare let $: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
	loader;
	rootPage;
	platformIs: string;
	public registrationId: string;
	myInfo;

	loginState: any;
	sequence = 0;

	constructor(
		private platform: Platform,
		private store: Store<AppState>,
		public translate: TranslateService,
		private jpush: JPush,
		public jMessageService: JMessageService,
		public authService: AuthService,

		public toastController: ToastController,
		public loadingController: LoadingController,
		public navCtrl: NavController,
		private config: Config,
		private router: Router,
		private globalService: GlobalService,
		public globals: Globals
	) {
		platform.ready().then(() => {
			// statusBar.styleDefault();
			// this.splashScreen.hide();
			// this.backgroundMode.enable();
			translate.use(environment.defaultLanguage);
			this.store.dispatch(loadSystem({ payload: { isMobile: this.platform.is('mobile') } }));
			this.store.select('system').subscribe((system) => {
				const language = system.language;
				if (language) {
					translate.use(language);
					environment.sysOptions.systemLanguage = this.globals.language;
				} else if (platform.is('cordova') === true) {
					// (<any>window).cordova.plugins.certificates.trustUnsecureCerts(true);
					// globalization.getPreferredLanguage().then((result) => {
					// 	const whichLanguage = this.getSuitableLanguage(
					// 		result.value
					// 	);
					// 	translate.use(whichLanguage);
					// 	environment.sysOptions.systemLanguage = whichLanguage;
					// });
				} else {
					const browserLanguage = translate.getBrowserLang() || environment.defaultLanguage;
					const lang = this.getSuitableLanguage(browserLanguage);
					translate.use(lang);
					environment.sysOptions.systemLanguage = lang;
				}
			});

			this.jpush.setApplicationIconBadgeNumber(0);
			this.jpush.resetBadge();

			this.setAlias(this.loginState.myInfo.password);

			document.addEventListener('visibilitychange', () => {
				const obj = this;
				if (
					document.visibilityState === 'visible' &&
					this.loginState.myInfo.token &&
					this.loginState.myInfo.id &&
					this.loginState.myInfo.password
				) {
					if (!obj.jMessageService.JIM.isInit()) {
						console.log('start initilialized interval');
						this.globalService.presentLoading('initializeJmsg');
						console.log(obj.jMessageService.JIM.isInit());
						// console.log("start initialize jMessage")
						this.jMessageService.initJMessage().then((d1) => {
							console.log('initialized');
							if (!obj.jMessageService.JIM.isLogin()) {
								console.log('logined');
								this.jMessageService
									.login(this.loginState.myInfo.id, this.loginState.myInfo.password)
									.then((data) => {
										console.log('jMessage reinitialized and logged');
										if (obj.globalService.loading) {
											obj.globalService.endLoading();
										}
										this.jMessageService.onMsgReceive();
										this.jMessageService.onSyncConversation();
									});
							} else {
								this.jMessageService.onMsgReceive();
								this.jMessageService.onSyncConversation();
								if (obj.globalService.loading) {
									obj.globalService.endLoading();
								}
							}
						});
					} else if (obj.jMessageService.JIM.isInit() && !obj.jMessageService.JIM.isLogin()) {
						obj.jMessageService.login(this.loginState.myInfo.id, this.loginState.myInfo.password).then((d) => {
                            obj.jMessageService.onMsgReceive();
							obj.jMessageService.onSyncConversation();

							console.log('jMessage initialized early, now it is logined',d);
							d && this.globalService.toast(this.translate.instant('jMessage logined'), 1500, 'top');
						});
					} else {
						obj.jMessageService.onMsgReceive();
						obj.jMessageService.onSyncConversation();
					}
				}
			});

			document.addEventListener(
				'jpush.openNotification',
				(event: any) => {
					let content;
					if (platform.is('android')) {
						content = event.alert;
					} else {
						// iOS
						if (event.aps === undefined) {
							// 本地通知
							content = event.content;
						} else {
							// APNS
							content = event.aps.alert;
						}
					}
					alert('open notification: ' + JSON.stringify(event));
				},
				false
			);

			document.addEventListener(
				'jpush.receiveLocalNotification',
				(event: any) => {
					// iOS(*,9) Only , iOS(10,*) 将在 jpush.openNotification 和 jpush.receiveNotification 中触发。
					let content;
					if (platform.is('android')) {
					} else {
						content = event.content;
					}
					alert('receive local notification: ' + JSON.stringify(event));
				},
				false
			);
		});

		this.store.select('login').subscribe((res) => {
			this.loginState = res;
		});
	}

	tagResultHandler = (result) => {
		const sequence: number = result.sequence;
		const tags: Array<string> = result.tags == null ? [] : result.tags;
		alert('Success!' + '\nSequence: ' + sequence + '\nTags: ' + tags.toString());
	};

	aliasResultHandler = (result) => {
		const sequence: number = result.sequence;
		const alias: string = result.alias;
		alert('Success!' + '\nSequence: ' + sequence + '\nAlias: ' + alias);
	};

	errorHandler = (err) => {
		const sequence: number = err.sequence;
		const code = err.code;
		console.log(err);
		//alert("Error!" + "\nSequence: " + sequence + "\nCode: " + code);
	};
	ngAfterViewInit() {
		// This element never changes.
		const ionapp = document.getElementsByTagName('ion-app')[0];

		window.addEventListener('ionKeyboardDidShow', async (event) => {
			// Move ion-app up, to give room for keyboard
			const { kbHeight } = (event as CustomEvent).detail;
			const viewportHeight: number = $(window).height();
			const inputFieldOffsetFromBottomViewPort: number =
				viewportHeight - $(':focus')[0].getBoundingClientRect().bottom;
			const inputScrollPixels = kbHeight - inputFieldOffsetFromBottomViewPort;

			// Set margin to give space for native keyboard.
			ionapp.style['margin-bottom'] = kbHeight.toString() + 'px';

			// But this diminishes ion-content and may hide the input field...
			if (inputScrollPixels > 0) {
				// ...so, get the ionScroll element from ion-content and scroll correspondingly
				// The current ion-content element is always the last.
				//If there are tabs or other hidden ion-content elements, they will go above.
				const ionScroll = await $('ion-content').last()[0].getScrollElement();
				setTimeout(() => {
					$(ionScroll).animate(
						{
							scrollTop: ionScroll.scrollTop + inputScrollPixels,
						},
						300
					);
				}, 300); // Matches scroll animation from css.
			}
		});
		window.addEventListener('keyboardDidHide', () => {
			// Move ion-app down again
			// Scroll not necessary.
			ionapp.style['margin-bottom'] = '0px';
		});
	}

	getRegistrationID() {
		this.jpush.getRegistrationID().then((rId) => {
			this.registrationId = rId;
		});
	}

	setTags() {
		this.jpush
			.setTags({ sequence: this.sequence++, tags: ['Tag1', 'Tag2'] })
			.then(this.tagResultHandler)
			.catch(this.errorHandler);
	}

	addTags() {
		this.jpush
			.addTags({ sequence: this.sequence++, tags: ['Tag3', 'Tag4'] })
			.then(this.tagResultHandler)
			.catch(this.errorHandler);
	}

	checkTagBindState() {
		this.jpush
			.checkTagBindState({ sequence: this.sequence++, tag: 'Tag1' })
			.then((result) => {
				const sequence = result.sequence;
				const tag = result.tag;
				const isBind = result.isBind;
				alert('Sequence: ' + sequence + '\nTag: ' + tag + '\nIsBind: ' + isBind);
			})
			.catch(this.errorHandler);
	}

	deleteTags() {
		this.jpush
			.deleteTags({ sequence: this.sequence++, tags: ['Tag4'] })
			.then(this.tagResultHandler)
			.catch(this.errorHandler);
	}

	getAllTags() {
		this.jpush
			.getAllTags({ sequence: this.sequence++ })
			.then(this.tagResultHandler)
			.catch(this.errorHandler);
	}

	cleanTags() {
		this.jpush
			.cleanTags({ sequence: this.sequence++ })
			.then(this.tagResultHandler)
			.catch(this.errorHandler);
	}

	setAlias(alias) {
		this.jpush
			.setAlias({ sequence: this.sequence++, alias })
			.then(this.aliasResultHandler)
			.catch(this.errorHandler);
	}

	getAlias() {
		this.jpush
			.getAlias({ sequence: this.sequence++ })
			.then(this.aliasResultHandler)
			.catch(this.errorHandler);
	}

	deleteAlias() {
		this.jpush
			.deleteAlias({ sequence: this.sequence++ })
			.then(this.aliasResultHandler)
			.catch(this.errorHandler);
	}

	addLocalNotification() {
		if ((this.platformIs = 'android')) {
			this.jpush.addLocalNotification(0, 'Hello JPush', 'JPush', 1, 5000);
		} else {
			this.jpush.addLocalNotificationForIOS(5, 'Hello JPush', 1, 'localNoti1');
		}
	}

	getSuitableLanguage(language) {
		language = language.substring(0, 2).toLowerCase();
		return environment.availableLanguages.some((x) => x.code === language) ? language : environment.defaultLanguage;
	}

	async presentToast(msg) {
		const toast = await this.toastController.create({
			message: msg,
			duration: 2000,
			position: 'top',
		});
		toast.present();
	}

	ngOnInit() {
		const obj = this;
		obj.authService.getMe().subscribe((res) => {
			console.log(res);
			if (res.token && res.id && obj.loginState.myInfo.password) {
				if (!obj.jMessageService.JIM.isInit() && obj.globals.jMessageStatus !== 'initializing') {
					obj.jMessageService.initJMessage().then((d1) => {
						obj.jMessageService.login(res.id, obj.loginState.myInfo.password).then((d) => {
							obj.jMessageService.onMsgReceive();
							// obj.jMessageService.onGetConversation();

							obj.jMessageService.onSyncConversation();

							console.log('jMessage initialized and logined');
							d && this.globalService.toast(
								this.translate.instant('jMessage initialized and logined'),
								1500,
								'top'
							);
						});
					});
				} else if (obj.jMessageService.JIM.isInit() && !obj.jMessageService.JIM.isLogin()) {
					obj.jMessageService.login(res.id, obj.loginState.myInfo.password).then((d) => {
						obj.jMessageService.onMsgReceive();
						obj.jMessageService.onSyncConversation();

						console.log('jMessage initialized early, now it is logined');
						d && this.globalService.toast(this.translate.instant('jMessage logined'), 1500, 'top');
					});
				} else {
					console.log('jMessage is already initialized and logined');
				}
				// });
			} else {
				if (!obj.jMessageService.JIM.isInit() && obj.globals.jMessageStatus !== 'initializing') {
					obj.jMessageService.initJMessage().then((d1) => {
						console.log('jMessage initialized');
					});
				}
				console.log('getMe wrong, navigate setting');
				this.router.navigate(['/tabs/settings']);
			}
		});

		// // 每3sec刷新
		// setInterval(() => {
		// 	if (this.loginState.myInfo.token && this.loginState.myInfo.id && this.loginState.myInfo.password) {
		// 		this.globals.interval += 1;
		// 		if (
		// 			!obj.jMessageService.JIM.isInit() &&
		// 			(obj.globals.jMessageStatus !== 'initializing' || this.globals.interval % 20 === 0)
		// 		) {
		// 			this.jMessageService.initJMessage().then((d1) => {
		// 				if (obj.jMessageService.JIM.isInit() && !obj.jMessageService.JIM.isLogin()) {
		// 					this.jMessageService
		// 						.login(this.loginState.myInfo.id, this.loginState.myInfo.password)
		// 						.then((data) => {
		// 							this.jMessageService.onMsgReceive();
		// 							this.jMessageService.onSyncConversation();
		// 						});
		// 				}
		// 			});
		// 		}
		// 	} else {
		// 		// this.authService.getMe().subscribe(res => {
		// 		// })
		// 	}
		// }, 5000);
	}
}

// DataURIToBlob(dataURI: string) {
//     const splitDataURI = dataURI.split(',')
//     const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
//     const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

//     const ia = new Uint8Array(byteString.length)
//     for (let i = 0; i < byteString.length; i++)
//         ia[i] = byteString.charCodeAt(i)

//     return new Blob([ia], { type: mimeString })
// }

// obj.jMessageService.getUserInfo(res.id).then(userInfo =>{
//     console.log(res)
//     if(userInfo && userInfo.user_info){
//         console.log(userInfo.user_info)
//     obj.jMessageService.onMsgReceive();
// let hasExtraAvatar = true;
// if(!userInfo.user_info.extras) hasExtraAvatar = false;
// if(userInfo.user_info.extras){
//     if(JSON.stringify(userInfo.user_info.extras) == "{}") hasExtraAvatar = false
// }
// if(res.imageURL && hasExtraAvatar===false){
// if(res.imageURL.includes("base64")){
//     console.log('base64')
//     const file = this.DataURIToBlob(res.imageURL)
//     const formData = new FormData();
//     formData.append('upload', file, 'image.jpg')
//     obj.jMessageService.updateSelfAvatar(formData).then(updateResult => {
//         console.log(updateResult)
//     })
// }else {
//     var form = new FormData();
//     http.request('http://nodejs.org/images/logo.png', function(response) {
//         form.append('my_logo', response);
//     });
// }
// }
//     }
// })
