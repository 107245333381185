import { Component, Input } from '@angular/core';
@Component({
	selector: 'app-table',
	templateUrl: 'table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent {
	@Input() tableData: any[][];

	@Input() rowsOnClicks: any;

	constructor() {}

	test(i) {
		alert(i);
	}
}
