import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Components } from '@ionic/core';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { CityService } from '../../services/city.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './searchmodal.page.html',
  styleUrls: ['./searchmodal.page.scss'],
})
export class SearchmodalPage {
  @Input() modal: Components.IonModal;

  // @Input() headers: [];

  keys = [];
  values = {} as any;

  // headers = ['id', 'nickname', 'imageURL', 'currency', 'introduction',
  // 'age', 'reviewNumber', 'category', 'likedCount', 'certificates'];
  // stringTypeHeaders = ['id', 'nickname', 'introduction',  'category', 'certificates', 'startTime', 'endTime'];
  // numberTypeHeaders = ['age', 'reviewNumber', 'likedCount'];

  headers = [];
  stringTypeHeaders = [];
  numberTypeHeaders = [];
  numberComparators = ['$gte', '$lte', '=='];
  stringComparators = ['$regex', '='];
  city;

  filters = {
    city: '',
    category: '',
    page: 1,
    // startTime: undefined,
    // endTime: undefined
  };
  itemFilters = {
    page: 1,
    perPage: 100,
  };
  objectKeys = Object.keys;
  objectValues = Object.values;
  comparator = {
    age: '$lte',
    nickname: '=',
    introduction: '$regex',
    certificates: '$regex',
    city: '$regex',
    price: '$lte',
  };
  value = {};
  medicalValue: any;
  result = 'select';
  cityData = [];
  medicalData = [];
  cityValue: any;
  cityFilter: any;
  medicalFilter: any;

  page: any;

  parsedMedicalValue = '';
  parsedCityValue = '';

  constructor(
    private navParams: NavParams,
    private cityService: CityService,
    private utilservices: UtilService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.headers.map((element) => {
      this.comparator[element] = this.numberTypeHeaders.includes(element)
        ? this.numberComparators[0]
        : this.stringTypeHeaders.includes(element)
        ? this.stringComparators[0]
        : element;
    });

    //??这里没有获取到headers，所以在ngoninit里面执行了一遍
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    try {
      for (const element of this.headers) {

          this.comparator[element] = this.numberTypeHeaders.includes(element)
            ? this.numberComparators[0]
            : this.stringTypeHeaders.includes(element)
            ? this.stringComparators[0]
            : null;

      }
      console.log(this.headers,'132')
      console.log(this.values);
      // eslint-disable-next-line guard-for-in
      for (const key in this.values) {
        const queryOp = ['$regex', '$gte', '$lte'].find((op) =>
          this.values[key].hasOwnProperty(op)
        );
        if (queryOp) {
          this.values[key] = this.values[key][queryOp];
        }
      }
    } catch (err) {
      console.error(err);
    }

    this.cityValue = this.values['city'];
    this.cityFilter = this.values['city'];

    this.medicalValue = this.values['category'];
    this.medicalFilter = this.values['category'];

    this.parsedCityValue =
      typeof this.cityValue === 'object' &&
      Object.values(this.cityValue)[0].toString();

    this.parsedMedicalValue =
      typeof this.medicalValue === 'object' &&
      Object.values(this.medicalValue)[0].toString();

    //这个city和category有两种模式，一种是带$regex，一种是不带的

    this.page = this.values['page'];

    try {
      this.activatedRoute.url.subscribe(async () => {
        [this.medicalData, this.cityData] =
          await this.utilservices.changeLanguage();
      });
    } catch (err) {
      console.error(err);
    }
  }

  onOk(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    this.cityValue = value.map((v) => v).join(',');
    this.cityFilter = this.cityValue.includes(',全部')
      ? {
          $regex: this.cityValue.substring(0, this.cityValue.indexOf(',全部')),
        }
      : this.cityValue;
    this.parsedCityValue = this.cityValue;
  }

  onMedicalOk(result) {
    const value = [];
    result.map((item) => value.push(item.label || item));
    this.medicalValue = value.map((v) => v).join(',');
    this.medicalFilter = this.medicalValue.includes(',全部')
      ? {
          $regex: this.medicalValue.substring(
            0,
            this.medicalValue.indexOf(',全部')
          ),
        }
      : this.medicalValue;
    this.parsedMedicalValue = this.medicalValue;
  }

  onChangeFilter(key, event) {
    let value = event.target.value;
    if (value && value.length !== 0) {
      this.value[key] = value;
    }
  }

  deleteFilter(newObjKey) {
    delete this.value[newObjKey];
  }

  inputValueFetch(newObjKey) {
    return this.value[newObjKey];
  }

  async dismiss() {
    for (let key in this.filters) {
      if (this.filters[key] == '') {
        delete this.filters[key];
      }
    }
    await this.modal.dismiss(this.filters);
  }

  async closeModal() {
    this.headers.map((element) => {
      if (this.values[element] && this.values[element].length > 0) {
        if (this.comparator[element] === '=') {
          this.filters[element] = this.values[element];
        } else {
          const filter = {};
          filter[this.comparator[element]] = this.values[element];
          this.filters[element] = filter;
        }
      }
    });

    if (this.cityFilter) {
      this.filters.city = this.cityFilter;
    }

    if (this.medicalFilter) {
      this.filters.category = this.medicalFilter;
    }

    if (this.page > 1) {
      this.filters.page = parseInt(this.page);

    }

    for (const key in this.filters) {
      if (this.filters[key] == '') {
        delete this.filters[key];
      }
    }

    await this.modal.dismiss(this.filters);
  }
}
