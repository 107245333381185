import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { default as localForage, getAllDataFromLocalForage } from 'ngrx-store-persist';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

getAllDataFromLocalForage({
	driver: localForage.INDEXEDDB,
	keys: [
		'login',
		'msglist',
		'conversations',
		'teachers',
		'services',
		'videos',
		'manageOrgs',
		'orgs',
		'events',
		// 'system',
	], //需要持久化的NgRxState
}).then(() => {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err) => console.log(err));
});

if (environment.production) {
	enableProdMode();
}
