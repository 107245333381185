import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { LogOut } from 'src/app/store/actions/login.action';
import { environment } from 'src/environments/environment';
import { Globals } from '../globals';
import { JMessageService } from '../services/jMessage.service';
import { AppState } from '../store';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    alreadyLoggedIn = false;
    myInfo: any = {};

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public globalService: GlobalService,
        private router: Router,
        private translate: TranslateService,
        public jMessage: JMessageService,
        private store: Store<AppState>
    ) {
        // this.myInfo = globals.myInfo;
        // this.alreadyLoggedIn = globals.alreadyLoggedIn;
        this.store
            .pipe(select('login'))
            .pipe(first())
            .subscribe((res) => {
                this.myInfo = res.myInfo;
                res.status === 'success'
                    ? (this.alreadyLoggedIn = true)
                    : (this.alreadyLoggedIn = false);
            });
    }

    login(data): Observable<any> {
        return this.http
            .post<any>(environment.defaultURL + '/user/login', data)
            .pipe(
                tap((res) => {
                    this.globals.jMessageStatus = 'notStart';
                    this.log('login' + res);
                }),
                catchError(this.handleError('login', []))
            );
    }

    getMe(): Observable<any> {
        return this.http.get<any>(environment.defaultURL + '/user/me').pipe(
            tap((res) => {
                this.log('login' + res);
            }),
            catchError(this.handleError('login', []))
        );
    }

    logout(): Observable<any> {
        this.globals.jMessageStatus = 'notStart';

        return this.http.get<any>(environment.defaultURL + '/signout').pipe(
            tap(() => this.log('logout')),
            catchError(this.handleError('logout', []))
        );
    }

    // register (data): Observable<any> {
    //   return this.http.post<any>(environment.defaultURL + '/user/', data)
    //     .pipe(
    //       tap(_ => this.log('login')),
    //       catchError(this.handleError('login', []))
    //     );
    // }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            switch(error.error.errorCode) {
                case 1002 : this.globalService.toast(
                    this.translate.instant('invalidPassword'),
                    1500,
                    'middle'
                );
                break;
                case 1015 : this.globalService.toast(
                    this.translate.instant('No user found'),
                    1500,
                    'top'
                );
                break;
                default:
                  break;
            }
            this.store.dispatch(LogOut()); //清除token
            this.jMessage.JIM.loginOut();

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);
            if (this.router.url !== '/tabs/settings/signUp') {
                this.router.navigate(['/tabs/settings']);
            }
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        console.log(message);
    }
}
