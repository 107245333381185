import { createReducer, on } from '@ngrx/store';
import {
	loadConversationsError,
	loadConversationsStart,
	loadConversationsSuccess,
	loadNextPageConversationsStart,
	readConversation,
	updateConversionStart,
} from '../actions/conversations.action';
//状态名称
export const conversationsKey = 'conversations';

//状态类型接口

export interface State {
	data?: any;
	status?: string;
}

// 初始状态
export const initialState: State = {
	data: [],
	status: 'idle',
};

const testMergeById = () => {
	const a1 = [
		{ id: 1, msg: 2, add: 3 },
		{ id: 3, msg: 3 },
	];
	const a2 = [
		{ id: 1, msg: 360 },
		{ id: 2, msg: 4 },
	];
	const k = a1.map((itm1) => {
		const newArr = a2.find((itm2) => itm1.id === itm2.id);
		return newArr ? { ...itm1, ...newArr } : itm1;
	});

	const n = a2.filter((itm2) => !k.find((itm1) => itm1.id === itm2.id));

	console.log([...k, ...n]);
};
// getConversation的list和onSyncConversation的list合并;
const mergeById = (a1: any, a2: any) => {
	//删掉空白的id的conversation
	a2 = a2.filter((x) => x.id && x.id.length);
	//用第二个arr去覆盖合并到第一个arr
	if (a1 && a1.length) {
		const k = a1.map((itm1) => {
			const newArr = a2.find((itm2) => itm1.id === itm2.id);
			return newArr ? { ...itm1, ...newArr } : itm1;
		});
		//a1.map((itm1) => a2.find((itm2) => itm1.id === itm2.id) || itm1);
		const n = a2.filter((itm2) => !k.find((itm1) => itm1.id === itm2.id));
		return [...k, ...n];
	} else {
		return a2;
	}
};

//创建reducer函数
export const conversationsReducer = createReducer(
	initialState,
	//getConversation的list和onSyncConversation的list合并
	on(loadConversationsStart, (state, action) => ({
		...state,
		status: 'started',
		data: { [action.payload.id]: mergeById(state.data[action.payload.id], action.payload.data || [{}]) },
	})),
	on(loadNextPageConversationsStart, (state) => ({
		...state,
		status: 'started',
	})),
	//保存结果
	on(loadConversationsSuccess, (state, action) => {
		const newStateData = JSON.parse(JSON.stringify(state.data));

		newStateData[action.payload.myInfoId] = newStateData[action.payload.myInfoId].map((x) => {
			const exist = action.payload.idWithIMages.find((y) => y.id === x.id);
			return exist ? { ...x, avatar: exist.imageURL } : x;
		});

		return {
			...state,
			status: 'success',
			data: newStateData,
		};
	}),
	on(loadConversationsError, (state) => ({
		...state,
		status: 'fail',
	})),
	on(readConversation, (state, action) => {
		const a = JSON.parse(JSON.stringify(state.data));
		// a[action.user][action.id].needRead = 0; //错误
		// console.log(state);
		a[action.user]?.forEach((x, i) => {
			if (x.id === action.id) {
				a[action.user][i].needRead = action.needRead;
			}
		});
		console.log(a);

		return {
			...state,
			data: a,
		};
	}),
	//addConversationItem,
	on(updateConversionStart, (state, action) => {
		const con = JSON.parse(JSON.stringify(state.data)) ?? [];

		let none = 0;
		con[action.myInfoId] = con[action.myInfoId] || [];
		con[action.myInfoId].map((item) => {
			if (item.id === action.id) {
				none++;
				if (item.showTime < action.msg.create_time) {
					item.showMsg = action.msg.content;
					item.showTime = action.msg.create_time;
					item.needRead = action.sendOrNot === 'send' ? 0 : (item.needRead || 0) + 1;
				}
				return item;
			}
			return item;
		});
		if (none === 0) {
			con[action.myInfoId].push({
				id: action.id,
				// 当用户是msg from的时候，这个name就是target。反之亦然。
				name:
					action.myInfoId === action.msg.from_id
						? action.msg.target_name || action.msg.target_id
						: action.msg.from_name || action.msg.from_id,
				unread_msg_count: 0,
				showTime: action.msg.create_time,
				needRead: 0,
				showMsg: action.msg.content,
			});
		}
		con[action.myInfoId].sort((x, y) => y.showTime - x.showTime);
		return {
			...state,
			data: {
				...state.data,
				...con,
			},
		};
	})
	// on(addAvatar, (state, action) => {
	// 	const con = JSON.parse(JSON.stringify(state.data)) ?? [];
	// 	const imgMap = new Map();
	// 	action.payload.forEach((item) => {
	// 		imgMap.set(item.id, { imageURL: item.imageURL, _id: item._id });
	// 	});
	// 	console.log(con);
	// 	con[action.MyinfoId].map((item, index) => {
	// 		item.avatar = imgMap.get(item.id)?.imageURL;
	// 	}); //map防止如果传过去的数组头像和现在的对不上了
	// 	return {
	// 		...state,
	// 		data: {
	// 			...state.data,
	// 			...con,
	// 		},
	// 	};
	// })
);
