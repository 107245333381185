import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UploaderBuilder } from 'qiniu4js';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Globals } from '../globals';
import { AppState } from '../store';
import { GlobalService } from './global.service';

// declare let Qiniu: any;

@Injectable({
    providedIn: 'root',
})
export class ImageProcessService {
    login$: any;
    constructor(
        private http: HttpClient,
        public globals: Globals,
        private globalService: GlobalService,
        private store: Store<AppState>
    ) {
        this.store
            .pipe(select('login'))
            .pipe(first())
            .subscribe((res) => {
                this.login$ = res;
            });
    }

    getQiniuNewUploadToken(validation) {
        return this.http.get(environment.defaultURL + '/file/upload/tokenNew');
    }

    uploadImage(files) {
        this.globals.buttonDisabled = true;
        return new Promise((resolve, reject) => {
            const file = files[0]._file;
            const reader = new FileReader();
            const obj = this;

            reader.onload = (e) => {
                // let image = new Image();
                // image.src = reader.result.toString();
                //
                // image.onload = function () {
                //     let imageWidth = image.width,
                //         imageHeight = image.height;
                //     let canvas = document.createElement('canvas');
                //     canvas.width = imageWidth;
                //     canvas.height = imageHeight;
                //     let ctx = canvas.getContext('2d');
                //     ctx.drawImage(image, 0, 0, imageWidth, imageHeight);
                //     // The resized file ready for upload
                //     let finalFile = canvas.toDataURL();
                //
                //     obj.globals.myInfo.imageURL = finalFile;
                //     console.log(finalFile)
                resolve(reader.result);
                // };
            };
            reader.readAsDataURL(file);
        });
    }

    uploadToQiniu(imageType, i, newCertificate?) {
        const that = this;
        const imageKey =
            this.login$.myInfo._id + Math.random().toString(36).substr(2, 10);
        return new Promise<any>((resolve, reject) => {
            const uploader = new UploaderBuilder()
                .debug(true)
                //.domain({http: "http://oi7p2141n.bkt.clouddn.com", https: "https://oi7p2141n.bkt.clouddn.com"})
                //默认为{http: "http://upload.qiniu.com", https: "https://up.qbox.me"}
                .compress(0.5)
                .scale([480, 0])
                .retry(0) //设置重传次数，默认0，不重传
                .tokenShare(false)
                .chunk(true)
                .multiple(true)
                //.button('button')
                .accept(['image/*'])
                .tokenFunc((setToken) => {
                    that.getQiniuNewUploadToken(that.login$.myInfo).subscribe(
                        (data: any) => {
                            setToken(data.token);
                        }
                    );
                })
                .listener({
                    onReady: (tasks) => {},
                    onStart: (tasks) => {
                        console.log('start');
                    },
                    onTaskGetKey: (tasks) => {
                        console.log(imageKey);
                        return imageKey;
                    },
                    onTaskProgress: (task) => {},
                    onTaskSuccess: (task) => {},
                    onTaskFail: (task) => {},
                    onTaskRetry: (task) => {},
                    onFinish: (tasks) => {
                        that.globals.buttonDisabled = false;
                        that.uploadImage(tasks)
                            .then((imageURL) =>
                                resolve({ imageKey, imageURL, uploader })
                            )
                            .catch((err) => reject(err));
                    },
                })
                .build();
            uploader.chooseFile();
        });
    }
}
