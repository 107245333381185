import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../../globals';
import { ServiceService } from '../../../../services/service.service';
import { AuthService } from './../../../../services/auth.service';
import { GlobalService } from './../../../../services/global.service';

declare let Stripe: any;

@Component({
	selector: 'app-stripe-charge',
	templateUrl: './stripeCharge.page.html',
	styleUrls: ['./stripeCharge.page.scss'],
})
export class StripeChargePage implements OnInit {
	@ViewChild('cardElement', { static: false }) cardElement: ElementRef;

	calendarList: any = {};
	previousUrl;
	amount = 100;
	currency = 'usd';
	card;

	billing_details = {
		name: undefined,
		email: undefined,
		address: {
			postal_code: undefined,
		},
	};

	stripe = Stripe('pk_test_pwoJEw9UTRagCoIY7JlQLnDI00M3ki1QpS');
	alreadyLoggedIn = false;
	myInfo: any = {};

	//sk_test_brGaMJkOGFqYNmRvuxnY486A0005pWcVAJ
	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		public authService: AuthService,
		private globalService: GlobalService,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store
			.select('login')
			.pipe(first())
			.subscribe((res) => {
				this.myInfo = res.myInfo;

				this.alreadyLoggedIn = res.status === 'success';
			});
		this.activatedRoute.queryParams.subscribe((data) => {});
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/settings/myPoints'
				: this.activatedRoute.snapshot.paramMap.get('p');
	}

	ionViewDidEnter() {
		console.log(this.cardElement);

		const elementStyles = {
			base: {
				color: '#32325d',
				fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				padding: '15px',

				'::placeholder': {
					color: '#aab7c4',
				},
				':-webkit-autofill': {
					color: '#32325d',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
				':-webkit-autofill': {
					color: '#fa755a',
				},
			},
		};

		this.card = this.stripe.elements().create('card', { style: elementStyles });
		console.log(this.card);
		console.log(typeof this.card);
		this.card.mount(this.cardElement.nativeElement);
	}

	submit(formData) {
		const dataPost = {
			line_items: [
				{
					name: 'T-shirt',
					description: 'Comfortable cotton t-shirt',
					images: ['https://example.com/t-shirt.png'],
					amount: 500,
					currency: 'usd',
					quantity: 1,
				},
			],
		};
		console.log('submit');

		this.http
			.get(environment.defaultURL + '/charge/credit/secret?amount=' + this.amount + '&currency=' + this.currency)
			.subscribe(
				(data: any) => {
					console.log(data);
					console.log(this.card);
					if (data.client_secret) {
						this.stripe
							.confirmCardPayment(data.client_secret, {
								payment_method: {
									card: this.card,
									billing_details: {
										name: 'Jenny Rosen',
									},
								},
							})
							.then((result) => {
								if (result.error) {
									// Show error to your customer (e.g., insufficient funds)
									console.log(result.error.message);
								} else if (result.paymentIntent.status === 'succeeded') {
									console.log(result);
								}
							});
					}

					//https://api.stripe.com/v1/payment_pages?
					//key=pk_test_pwoJEw9UTRagCoIY7JlQLnDI00M3ki1QpS&
					//guid=fe9c3d74-753c-41a4-9ddd-4f65f6b20921&
					//muid=5b010d56-5ae3-429a-a18b-41812a86d2ca&sid=9891bc22-0afd-4e5d-bc9c-7196b64a3a21&
					//session_id=cs_test_kIAsbjGsCi09rN3Pz1qBUexNF7UcTBcGEhnG9puX7FV67XWHFwocWf7H
					//get item info
					//
					// let clientScret = data.payment_intent._client_secret
					// console.log(clientScret)
					// this.stripe.confirmCardPayment(clientScret, {
					//     payment_method: {
					//     card: card,
					//     billing_details: {
					//         name: 'Jenny Rosen'
					//     }
					//     }
					// }).then(function(result) {
					//     if (result.error) {
					//     // Show error to your customer (e.g., insufficient funds)
					//     console.log(result.error.message);
					//     } else {
					//     // The payment has been processed!
					//     if (result.paymentIntent.status === 'succeeded') {
					//         // Show a success message to your customer
					//         // There's a risk of the customer closing the window before callback
					//         // execution. Set up a webhook or plugin to listen for the
					//         // payment_intent.succeeded event that handles any business critical
					//         // post-payment actions.
					//     }
					//     }
					// });
				},
				(err) => {
					console.log(err);
				}
			);
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {
		this.authService.getMe().subscribe(
			(res) => {
				console.log(res);
				if (res.token) {
					this.alreadyLoggedIn = true;
					this.myInfo = res;
					this.billing_details.name = res.id;
				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	serializeQuery(params, prefix) {
		const query = Object.keys(params).map((key) => {
			const value = params[key];

			if (params.constructor === Array) {
				key = `${prefix}[]`;
			} else if (params.constructor === Object) {
				key = prefix ? `${prefix}[${key}]` : key;
			}

			if (typeof value === 'object') {
				return this.serializeQuery(value, key);
			} else {
				return `${key}=${encodeURIComponent(value)}`;
			}
		});

		return [].concat.apply([], query).join('&');
	}
}
