import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { LikeService } from '../../../services/like.service';
import { VideoService } from '../../../services/video.service';

@Component({
	selector: 'app-video-details',
	templateUrl: './videodetails.page.html',
	styleUrls: ['./videodetails.page.scss'],
})
export class VideodetailsPage implements OnInit {
	myId = null;
	currentUrl;
	previousUrl;
	video: any = {
		_id: '',
		id: '',
		review: [],
		introduction: '',
		likedBy: [],
		owner: {
			_id: '',
			nickname: '',
			imageURL: '',
		},
		url: '',
		imageURL: '',
		shopURL: '',
		category: {
			main: '',
		},
	};
	certificates;
	url;
	alreadyLoggedIn = false;
	myInfo: any = {};

    data: Object;


	constructor(
		private http: HttpClient,
		private videoService: VideoService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		public authService: AuthService,
		private likeService: LikeService,
		private domSanitizer: DomSanitizer,
		private globalService: GlobalService,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store
			.select('login')
			.pipe(first())
			.subscribe((res) => {
				this.myInfo = res.myInfo;
				this.alreadyLoggedIn = res.status === 'success';
			});
		this.myId = this.activatedRoute.snapshot.paramMap.get('myId');
        this.data = {_id:this.myId}
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/video'
				: this.activatedRoute.snapshot.paramMap.get('p');
		this.currentUrl = this.router.url;

		this.videoService.getVideoDetails(this.myId).subscribe((data: any) => {
			console.log(data);
			this.video = data;
			if (data.certificates) {
				this.certificates = data.certificates;
			}
			data.videoURL = data.videoURL.includes('https') ? data.videoURL : data.videoURL.replace('http', 'https');
			console.log(data.videoURL);
			this.video.url = this.domSanitizer.bypassSecurityTrustResourceUrl(data.videoURL);
			this.video.likedByUser = this.myInfo.likedVideo && this.myInfo.likedVideo.includes(this.video._id);
		});
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {}

	like(type, target) {
		this.likeService.like(type, target).subscribe((data) => {
			this.video = data;
		});
	}

	unlike(type, target) {
		this.likeService.unlike(type, target).subscribe((data) => {
			this.video = data;
		});
	}

	openPurchasePage() {
		//  window.open("http://" + this.videoDetails.shopURL, "_system");

		//let browser = new InAppBrowser("http://" + this.videoDetails.shopURL, '_blank');
		//let browser = new InAppBrowser('https://appear.in/' + "jfisdlfjsl",'_system');
		window.open('http://' + this.video.shopURL, '_system');
	}

	share() {
		this.globalService.share(this.video);
	}
}
