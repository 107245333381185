import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class ServiceService {
	public perpage = 10;
	queryString;

	constructor(private http: HttpClient, private utilService: UtilService) {}

	get(queryParams: any) {
		const params: HttpParams =
			this.utilService.makeQueryParams(queryParams);

		return this.http.get(environment.defaultURL + '/service', {
			params,
		});
	}

	getMyServices(search, page: number, category, subCategory, ownerId) {
		if (ownerId) {
			this.queryString =
				this.perpage + '&page=' + page + '&ownerId=' + ownerId;
		} else if (category && subCategory) {
			this.queryString =
				this.perpage +
				'&page=' +
				page +
				'&category=' +
				category +
				'&subCategory=' +
				subCategory;
		} else if (category) {
			this.queryString =
				this.perpage + '&page=' + page + '&category=' + category;
		} else {
			this.queryString = this.perpage + '&page=' + page;
		}
		if (search) {
			this.queryString += '&search=' + JSON.stringify(search);
		}

		return this.http.get(
			environment.defaultURL + '/myServices?limit=' + this.queryString
		);
	}

	getServiceDetails(_id) {
		return this.http.get(
			environment.defaultURL + '/serviceDetails?_id=' + _id
		);
	}

	putService(service) {
		if (service.price) {
			service.price = service.price * 1;
		}
		if (service.priceBeforeDiscount) {
			service.priceBeforeDiscount = service.priceBeforeDiscount * 1;
		}
		return this.http.put(environment.defaultURL + '/service', service);
	}

	postService(service) {
		if (service.price) {
			service.price = service.price * 1;
		}
		if (service.priceBeforeDiscount) {
			service.priceBeforeDiscount = service.priceBeforeDiscount * 1;
		}
		return this.http.post(environment.defaultURL + '/service', service);
	}

	deleteService(_id) {
		return this.http.delete(environment.defaultURL + '/service?_id=' + _id);
	}
}
