import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { LikeService } from '../../services/like.service';
import { TeacherService } from '../../services/teacher.service';
import { LikeComponent } from '../like/like.component';

@Component({
	selector: 'app-shared-teacher-list',
	templateUrl: 'sharedteacherlist.component.html',
})
export class SharedteacherlistComponent implements OnInit {
	@ViewChild(IonInfiniteScroll, { static: true })
	likecomponet: LikeComponent;
	infiniteScroll: IonInfiniteScroll;

	@Input() teachers: any;

	page = 1;
	noMoreData = false;
	currentUrl;
	alreadyLoggedIn = false;
	myInfo: any = {};

	constructor(
		private router: Router,
		private teacherService: TeacherService,
		private globalService: GlobalService,
		public authService: AuthService,
		private likeService: LikeService,
		public globals: Globals,
		private translate: TranslateService,
		public navCtrl: NavController,
		private _location: Location
	) {
		this.currentUrl = this.router.url;
	}

	ngOnInit() {
		// this.getTeachers()
		console.log(this.teachers);
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		const n = this.currentUrl.split('/').length;
		if (n > 3) {
			this._location.back();
		}
	}

	onSwipeRight($event) {}

	getIds(array) {
		return array.map((x) => x.id);
	}
}
