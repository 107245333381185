import { createReducer, on } from '@ngrx/store';
import { loadSystem } from '../actions/system.action';
//状态名称
export const systemKey = 'system';

//状态类型接口
export interface State {
	isMobile?: boolean;
	language?: string;
}
// 初始状态
export const initialState: State = {
	isMobile: true,
	language: 'ja',
};

//创建reducer函数
export const systemReducer = createReducer(
	initialState,
	on(loadSystem, (state, action) => ({
		...state,
		isMobile: action.payload.isMobile,
		language: action.payload.language,
	}))
);
