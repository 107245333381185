import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
import { EventService } from 'src/app/services/event.service';
import { OrgService } from 'src/app/services/org.service';
import { ServiceService } from 'src/app/services/service.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { VideoService } from 'src/app/services/video.service';
import { AppState } from 'src/app/store';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { LikeService } from '../../../services/like.service';

@Component({
	selector: 'app-my-likes',
	templateUrl: './myLikes.page.html',
	styleUrls: ['./myLikes.page.scss'],
})
export class MyLikesPage implements OnInit {
	id: string;
	password: string;
	teachers: any = [];
	page = 1;
	limit = 20;
	infiniteScrollEnd = false;
	favoriteClass = 'teacher';
	myLikes;
	orgs: any = [];
	services: any = [];
	videos: any = [];
	events: any = [];
	currentUrl;
	defaultSelected;
	category = null;
	subCategory = null;
	orgId = null;
	searchStringObject = {
		org: {},
		teacher: {},
		event: {},
		video: {},
		service: {},
	};
	myInfo: any = {};
	teachers$;
	services$;
	videos$;
	orgs$;
	events$;

	constructor(
		private http: HttpClient,
		private likeService: LikeService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public authService: AuthService,
		private globalService: GlobalService,
		private alertCtrl: AlertController,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private teacherService: TeacherService,

		private serviceService: ServiceService,
		private eventService: EventService,
		private videoService: VideoService,
		private orgService: OrgService,
		private store: Store<AppState>
	) {
		this.store.select('login').subscribe((res) => {
			this.myInfo = res.myInfo;
		});
		this.currentUrl = this.router.url;
		this.searchStringObject.teacher = {
			_id: { $in: this.myInfo.likedTeacher },
		};
		this.searchStringObject.video = {
			_id: { $in: this.myInfo.likedVideo },
		};
		this.searchStringObject.service = {
			_id: { $in: this.myInfo.likedService },
		};
		this.searchStringObject.event = {
			_id: { $in: this.myInfo.likedBigEvent },
		};
		this.searchStringObject.org = {
			_id: { $in: this.myInfo.likedOrg },
		};

		this.teachers$ = this.teacherService.get(this.searchStringObject.teacher);
		this.services$ = this.serviceService.get(this.searchStringObject.service);
		this.videos$ = this.videoService.get(this.searchStringObject.video);
		this.orgs$ = this.orgService.get(this.searchStringObject.org);
		this.events$ = this.eventService.get(this.searchStringObject.event);
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ngOnInit() {}

	// like(type, target) {
	// 	this.likeService.like(type, target).subscribe((data) => {
	// 		switch (type) {
	// 			case 'org':
	// 				this.orgs = data;
	// 				break;

	// 			case 'teacher':
	// 				this.teachers = data;
	// 				break;

	// 			case 'video':
	// 				this.videos = data;
	// 				break;

	// 			case 'service':
	// 				this.services = data;
	// 				break;

	// 			case 'event':
	// 				this.events = data;
	// 				break;
	// 		}
	// 	});
	// }

	// unlike(type, target) {
	// 	this.likeService.unlike(type, target).subscribe((data) => {
	// 		switch (type) {
	// 			case 'org':
	// 				this.orgs = data;
	// 				break;
	// 			case 'teacher':
	// 				this.teachers = data;
	// 				break;
	// 			case 'video':
	// 				this.videos = data;
	// 				break;
	// 			case 'service':
	// 				this.services = data;
	// 				break;
	// 			case 'event':
	// 				this.events = data;
	// 				break;
	// 		}
	// 	});
	// }

	clickSegment(name) {}
}
