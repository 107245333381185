import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { JMessagePlugin } from '@jiguang-ionic/jMessage/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { JPush } from '@jiguang-ionic/jpush/ngx';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// AoT requires an exported function for factories
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgCalendarModule } from 'ionic2-calendar';
import { IonicRatingModule } from 'ionic4-rating';
import { ListModule, NgZorroAntdMobileModule, PickerModule } from 'ng-zorro-antd-mobile';
import { storageSyncMetaReducer } from 'ngrx-store-persist';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './components/chat/chat.component';
import { LikeComponent } from './components/like/like.component';
import { ReviewPage } from './components/review/review.page';
import { SearchmodalPage } from './components/searchmodal/searchmodal.page';
import { SharedeventlistComponent } from './components/sharedeventlist/sharedeventlist.component';
import { SharedmenuComponent } from './components/sharedmenu/sharedmenu.component';
import { SharedorglistComponent } from './components/sharedorglist/sharedorglist.component';
import { SharedservicelistComponent } from './components/sharedservicelist/sharedservicelist.component';
import { SharedteacherlistComponent } from './components/sharedteacherlist/sharedteacherlist.component';
import { SharedvideolistComponent } from './components/sharedvideolist/sharedvideolist.component';
import { TableComponent } from './components/table/table.component';
import { SwipeDirective } from './directives/swipe.directive';
import { Globals } from './globals';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ChangeObserverService } from './services/changeObserver.service';
import { JMessageService } from './services/jMessage.service';
import { ConversationsEffects } from './store/effects/conversations.effect';
import { EventsEffects } from './store/effects/events.effect';
import { MyAppointmentsEffects } from './store/effects/myAppointments.effect';
import { LikeEffects } from './store/effects/likes.effect';
import { LoginEffects } from './store/effects/login.effect';
import { ManageOrgsEffects } from './store/effects/manageOrgs.effect';
import { OrgsEffects } from './store/effects/orgs.effect';
import { ServicesEffects } from './store/effects/services.effect';
import { SystemEffects } from './store/effects/system.effect';
import { TeachersEffects } from './store/effects/teachers.effect';
import { reducers } from './store/index';
import { ConversationPage } from './tabs/conversation/conversation.page';
import { EventPage } from './tabs/event/event.page';
import { EventdetailsPage } from './tabs/event/eventdetails/eventdetails.page';
import { EventpayPage } from './tabs/event/eventdetails/eventpay/eventpay.page';
import { OrgPage } from './tabs/org/org.page';
import { OrgdetailsPage } from './tabs/org/orgdetails/orgdetails.page';
import { OrgteachersPage } from './tabs/org/orgdetails/orgteachers/orgteachers.page';
import { ServicePage } from './tabs/service/service.page';
import { MakereservationPage } from './tabs/service/servicedetails/makereservation/makereservation.page';
import { ReservationdetailsPage } from './tabs/service/servicedetails/makereservation/reservationdetails/reservationdetails.page';
import { ServicedetailsPage } from './tabs/service/servicedetails/servicedetails.page';
import { ApplyForOrgManagerPage } from './tabs/settings/apply-for-orgManager/apply-for-orgManager.page';
import { ApplyForProPage } from './tabs/settings/apply-for-pro/apply-for-pro.page';
import { ApplyForSecretaryPage } from './tabs/settings/apply-for-secretary/apply-for-secretary.page';
import { ApproveOrgPage } from './tabs/settings/approveOrg/approveOrg.page';
import { ApproveOrgManagerPage } from './tabs/settings/approveOrgManager/approveOrgManager.page';
import { ApproveProPage } from './tabs/settings/approvePro/approvePro.page';
import { ApproveSecPage } from './tabs/settings/approveSec/approveSec.page';
import { LanguageSettingsPage } from './tabs/settings/languageSettings/languageSettings.page';
import { CreateOrgPage } from './tabs/settings/manageOrg/createOrg/createOrg.page';
import { ManageOrgPage } from './tabs/settings/manageOrg/manageOrg.page';
import { ManageOrgMemberPage } from './tabs/settings/manageOrg/manageOrgMembers/manageOrgMember/manageOrgMember.page';
import { ManageOrgMembersPage } from './tabs/settings/manageOrg/manageOrgMembers/manageOrgMembers.page';
import { ReviewOrgMemberApplicationPage } from './tabs/settings/manageOrg/manageOrgMembers/reviewOrgMemberApplication/reviewOrgMemberApplication.page';
import { UpdateOrgInfoPage } from './tabs/settings/manageOrg/updateOrgInfo/updateOrgInfo.page';
import { CommentPage } from './tabs/settings/myAppointments/comment/comment.page';
import { MyAppointmentsPage } from './tabs/settings/myAppointments/myAppointments.page';
import { MyCalendarAsUserPage } from './tabs/settings/myCalendarAsUser/myCalendarAsUser.page';
import { ReviewReservationPage } from './tabs/settings/myCalendarAsUser/reviewReservation/reviewReservation.page';
import { MyEventsPage } from './tabs/settings/myEvents/myEvents.page';
import { NewEventPage } from './tabs/settings/myEvents/newEvent/newEvent.page';
import { UpdateEventPage } from './tabs/settings/myEvents/updateEvent/updateEvent.page';
import { ChangePasswordPage } from './tabs/settings/myInformation/changePassword/changePassword.page';
import { JoinOrgPage } from './tabs/settings/myInformation/joinOrg/joinOrg.page';
import { MyInformationPage } from './tabs/settings/myInformation/myInformation.page';
import { MyInformationChangePage } from './tabs/settings/myInformation/myInformationChange/myInformationChange.page';
import { MyLikesPage } from './tabs/settings/myLikes/myLikes.page';
import { MyPointsPage } from './tabs/settings/myPoints/myPoints.page';
import { StripeChargePage } from './tabs/settings/myPoints/stripeCharge/stripeCharge.page';
import { MySchedulePage } from './tabs/settings/mySchedule/mySchedule.page';
import { UpdateSchedulePage } from './tabs/settings/mySchedule/updateSchedule/updateSchedule.page';
import { MyServicesPage } from './tabs/settings/myServices/myServices.page';
import { NewServicePage } from './tabs/settings/myServices/newService/newService.page';
import { UpdateServicePage } from './tabs/settings/myServices/updateService/updateService.page';
import { MyVideosPage } from './tabs/settings/myVideos/myVideos.page';
import { NewVideoPage } from './tabs/settings/myVideos/newVideo/newVideo.page';
import { UpdateVideoPage } from './tabs/settings/myVideos/updateVideo/updateVideo.page';
import { SettingsPage } from './tabs/settings/settings.page';
import { SignUpPage } from './tabs/settings/signUp/signUp.page';
import { RetrievePasswordPage } from './tabs/settings/retrievePassword/retrievePassword.page';
import { UserAgreementPage } from './tabs/settings/userAgreement/userAgreement.page';
import { TabsPage } from './tabs/tabs.page';
import { TeacherPage } from './tabs/teacher/teacher.page';
import { Teacher3ServicesPage } from './tabs/teacher/teacherdetails/teacher3Services/teacher3Services.page';
import { TeacherCertificatePage } from './tabs/teacher/teacherdetails/teacherCertificate/teacherCertificate.page';
import { TeacherdetailsPage } from './tabs/teacher/teacherdetails/teacherdetails.page';
import { VideoPage } from './tabs/video/video.page';
import { VideodetailsPage } from './tabs/video/videodetails/videodetails.page';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { VideosEffects } from './store/effects/videos.effect';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, '../assets/i18n/', '.json');

@NgModule({
	declarations: [
		AppComponent,
		TabsPage,
		TeacherPage,
		TeacherdetailsPage,
		Teacher3ServicesPage,
		TeacherCertificatePage,
		OrgPage,
		OrgdetailsPage,
		OrgteachersPage,
		ChatComponent,
		SharedmenuComponent,
		SharedteacherlistComponent,
		SharedorglistComponent,
		SharedservicelistComponent,
		SharedvideolistComponent,
		SharedeventlistComponent,
		TableComponent,
		LikeComponent,
		SearchmodalPage,
		ReviewPage,
		ConversationPage,
		VideoPage,
		VideodetailsPage,
		ServicePage,
		ServicedetailsPage,
		MakereservationPage,
		ReservationdetailsPage,
		EventPage,
		EventdetailsPage,
		EventpayPage,
		SettingsPage,
		SignUpPage,
		RetrievePasswordPage,
		UserAgreementPage,
		MyAppointmentsPage,
		CommentPage,
		MyCalendarAsUserPage,
		ReviewReservationPage,
		MyEventsPage,
		NewEventPage,
		UpdateEventPage,
		MyInformationPage,
		ApproveProPage,
		ApproveOrgManagerPage,
		ApproveOrgPage,
		ApproveSecPage,
		JoinOrgPage,
		ChangePasswordPage,
		MyInformationChangePage,
		MyLikesPage,
		MyPointsPage,
		StripeChargePage,
		MySchedulePage,
		UpdateSchedulePage,
		MyServicesPage,
		NewServicePage,
		UpdateServicePage,
		MyVideosPage,
		NewVideoPage,
		UpdateVideoPage,
		ApplyForProPage,
		ApplyForOrgManagerPage,
		CreateOrgPage,
		UpdateOrgInfoPage,
		ManageOrgPage,
		ApplyForSecretaryPage,
		ManageOrgMembersPage,
		ManageOrgMemberPage,
		ReviewOrgMemberApplicationPage,
		LanguageSettingsPage,
		SwipeDirective,
	],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			mode: 'ios',
			backButtonText: '',
		}),
		NgxIonicImageViewerModule,
		AppRoutingModule,
		HttpClientModule,
		IonicRatingModule,
		CommonModule,
		FormsModule,
		NgCalendarModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
		}),
		BrowserAnimationsModule,
		ListModule,
		PickerModule,
		NgZorroAntdMobileModule,
		StoreModule.forRoot(reducers, {
			metaReducers: [storageSyncMetaReducer],
		}),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
		EffectsModule.forRoot([
			TeachersEffects,
			LoginEffects,
			EventsEffects,
			MyAppointmentsEffects,
			OrgsEffects,
			ServicesEffects,
			ManageOrgsEffects,
            VideosEffects,
			LikeEffects,
			ConversationsEffects,
			SystemEffects,
		]),
		StoreRouterConnectingModule.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	exports: [],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
		JPush,
		// JMessagePlugin,

		JMessageService,
		ChangeObserverService,
		Globals,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
