import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { JMessageService } from 'src/app/services/jMessage.service';
import { LikeService } from 'src/app/services/like.service';
import { AppState } from 'src/app/store';
import { likeError, likeStart, unlikeStart } from '../actions/likes.action';
import { addMyInfoLike, removeMyInfoLike } from '../actions/login.action';

@Injectable()
export class LikeEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private authService: AuthService, // we will need this service for API calls

		private globalService: GlobalService,

		private jMessage: JMessageService,
		private store: Store<AppState>,
		private likeService: LikeService
	) {}

	loadLike$ = createEffect(() =>
		this.actions$.pipe(
			ofType(likeStart),
			//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
			switchMap((action) => {
				return this.likeService.like(action.likeType, action._id).pipe(
					map((res) => {
						let type: string = 'liked' + action.likeType[0].toUpperCase() + action.likeType.slice(1);
						return addMyInfoLike({
							filedName: type,
							targetId: action._id,
						}); //改变Myinfo
					}),
					catchError(() => of(likeError()))
				);
			})
		)
	);

	loadUnLike$ = createEffect(() =>
		this.actions$.pipe(
			ofType(unlikeStart),
			//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
			switchMap((action) => {
				return this.likeService.unlike(action.unlikeType, action._id).pipe(
					map((res) => {
						const type = 'liked' + action.unlikeType.replace(/\b[a-z]/g, (l) => l.toUpperCase());
						return removeMyInfoLike({
							filedName: type,
							targetId: action._id,
						}); //改变Myinfo
					}),
					catchError(() => of(likeError()))
				);
			})
		)
	);
}
