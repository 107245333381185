import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OrgService } from 'src/app/services/org.service';
import { AppState } from 'src/app/store';
import { loadManageOrgsError, loadManageOrgsStart, loadManageOrgsSuccess } from '../actions/manageOrgs.action';

@Injectable()
export class ManageOrgsEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private orgService: OrgService, // we will need this service for API calls
		private store: Store<AppState>
	) {}

	loadManageOrgs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadManageOrgsStart),
			//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
			switchMap(() => {
				return this.orgService.orgsManagerOf().pipe(
					map((res) => {
						return loadManageOrgsSuccess({ payload: res });
					}),
					catchError(() => of(loadManageOrgsError()))
				);
			})
		)
	);
}
