import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class VideoService {
	public perpage = 10;
	queryString;

	constructor(private http: HttpClient, private utilService: UtilService) {}

	get(queryParams: any) {
		const params: HttpParams =
			this.utilService.makeQueryParams(queryParams);

		return this.http.get(environment.defaultURL + '/video', {
			params,
		});
	}

	getMyVideos(search, page: number, category, subCategory, ownerId) {
		if (ownerId) {
			this.queryString =
				this.perpage + '&page=' + page + '&ownerId=' + ownerId;
		} else if (category && subCategory) {
			this.queryString =
				this.perpage +
				'&page=' +
				page +
				'&category=' +
				category +
				'&subCategory=' +
				subCategory;
		} else if (category) {
			this.queryString =
				this.perpage + '&page=' + page + '&category=' + category;
		} else {
			this.queryString = this.perpage + '&page=' + page;
		}
		if (search) {
			this.queryString += '&search=' + JSON.stringify(search);
		}

		return this.http.get(
			environment.defaultURL + '/myVideos?limit=' + this.queryString
		);
	}

	getVideoDetails(_id) {
		return this.http.get(
			environment.defaultURL + '/videoDetails?_id=' + _id
		);
	}

	putVideo(video) {
		if (video.price) {
			video.price = video.price * 1;
		}
		if (video.priceBeforeDiscount) {
			video.priceBeforeDiscount = video.priceBeforeDiscount * 1;
		}
		return this.http.put(environment.defaultURL + '/video', video);
	}

	postVideo(video) {
		if (video.price) {
			video.price = video.price * 1;
		}
		if (video.priceBeforeDiscount) {
			video.priceBeforeDiscount = video.priceBeforeDiscount * 1;
		}
		return this.http.post(environment.defaultURL + '/video', video);
	}

	deleteVideo(_id) {
		return this.http.delete(environment.defaultURL + '/video?_id=' + _id);
	}
}
