import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IonInfiniteScroll,
  ModalController,
  NavController,
} from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SharedeventlistComponent } from 'src/app/components/sharedeventlist/sharedeventlist.component';
import { Globals } from 'src/app/globals';
import { AppState } from 'src/app/store';
import {
  loadEventsStart,
  loadNextPageEventsStart,
} from 'src/app/store/actions/events.action';
import { SearchmodalPage } from '../../components/searchmodal/searchmodal.page';
import { EventService } from '../../services/event.service';
import { TeacherService } from '../../services/teacher.service';
@Component({
  selector: 'app-event',
  templateUrl: './event.page.html',
  styleUrls: ['./event.page.scss'],
})
export class EventPage implements OnInit {
  // @ViewChild(SharedeventlistComponent, { static: true })
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  sharedeventlistComponent: SharedeventlistComponent;

  public menu1: any = [];
  public menu2: any = [];
  public menu3 = [];
  public menu4 = [];
  public grids = [];
  public events: any;
  events$: Observable<any>;

  login$: Observable<any>;

  page: number;

  city = null;

  id = undefined;
  certificates = [];
  keyword = null;
  eventLoaded = false;
  currentUrl;
  searchString = {};
  category = null;
  subCategory = null;
  orgId = null;

  headers = ['nickname', 'introduction', 'reviewNumber', 'likedCount'];

  insertValues = {
    nickname: '',
    introduction: '',
    reviewNumber: '',
    category: '',
    likedCount: '',
    page: 1,
  };

  stringTypeHeaders = ['nickname', 'introduction'];
  numberTypeHeaders = ['reviewNumber', 'likedCount'];

  constructor(
    private http: HttpClient,
    private teacherService: TeacherService,
    private eventService: EventService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private store: Store<AppState>,
    public modalController: ModalController,
    private navCtrl: NavController,
    public globals: Globals
  ) {
    this.currentUrl = this.router.url;
  }
  ngOnInit() {
    this.getEvents();
    this.activatedRoute.url.subscribe(() => {
      this.getEvents();
    });
    this.events$ = this.store.select('events').pipe(
      tap((res) => {
        for (const key in res.requestParams) {
          if (res.requestParams[key] != null) {
            this.insertValues[key] = res.requestParams[key];
          }
        }
      })
    );

    this.login$ = this.store.select('login');
  }

  public getEvents() {
    this.store.dispatch(
      loadEventsStart({
        payload: {
          page: this.page || 1,
          limit: 20,
        },
      })
    );
  }

  loadData(event) {
    //到达底部了， dispatch一下
    this.store.dispatch(loadNextPageEventsStart());
    event.target.complete();
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: SearchmodalPage,
      componentProps: {
        headers: this.headers,
        numberTypeHeaders: this.numberTypeHeaders,
        stringTypeHeaders: this.stringTypeHeaders,
        values: this.insertValues,
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        console.log(dataReturned.data);
        this.searchString = dataReturned.data;
        this.store.dispatch(
          loadEventsStart({
            payload: {
              // search: JSON.stringify(this.searchString),
              page: this.page,
              limit: 20,
              // category: dataReturned.data.category,
              // city: dataReturned.data.city,
              // memberOf: this.orgId,
              ...dataReturned.data,
            },
          })
        );
        // this.infiniteScroll.disabled = false;
      }
    });

    return await modal.present();
  }
}
