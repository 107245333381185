import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	perpage = 20;
	queryString;

	constructor(private http: HttpClient, public globalService: GlobalService) {}

	adminGetUser(search, page: number, category, city, orgId) {
		this.queryString = this.perpage + '&page=' + page;
		if (category) {
			search.category = category;
		}

		if (city) {
			search.city = city;
		}
		console.log(search);
		if (search && search.startTime) {
			this.queryString += '&startTime=' + search.startTime;
			delete search.startTime;
		}
		if (search && search.endTime) {
			this.queryString += '&endTime=' + search.endTime;
			delete search.endTime;
		}
		if (search) {
			this.queryString += '&search=' + JSON.stringify(search);
		}
		if (orgId) {
			this.queryString += '&orgId=' + orgId;
		}

		return this.http.get(environment.defaultURL + '/user/adminGetUser?limit=' + this.queryString);
	}

	changePassword(data) {
		return this.http.put(environment.defaultURL + '/user/password', data);
	}

	userDetails() {
		return this.http.get(environment.defaultURL + '/user/me');
	}

	getUserDetails(_id) {
		return this.http.get(environment.defaultURL + '/userDetails?_id=' + _id);
	}

	myCalendarAsUser() {
		return this.http.get(environment.defaultURL + '/user/getMyCalendarAsUser');
	}

	applyForPro(myInfo) {
		return this.http.post(environment.defaultURL + '/user/applyForPro', myInfo);
	}

	applyForOrgManager(myInfo) {
		return this.http.post(environment.defaultURL + '/user/applyForOrgManager', myInfo);
	}
	approveApplyForOrgManager(pro) {
		return this.http.post(environment.defaultURL + '/user/approveApplyForOrgManager', pro);
	}

	rejectApplyForOrgManager(pro) {
		return this.http.post(environment.defaultURL + '/user/rejectApplyForOrgManager', pro);
	}

	approveApplyForPro(pro) {
		return this.http.post(environment.defaultURL + '/user/approveApplyForPro', pro);
	}

	rejectApplyForPro(pro) {
		return this.http.post(environment.defaultURL + '/user/rejectApplyForPro', pro);
	}

	getHeadImages(ids) {
		console.log(ids);
		return this.http.post(environment.defaultURL + '/user/getHeadImages', ids);
	}
}
