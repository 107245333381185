import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(private http: HttpClient) {}

  handleError(error: Response | any) {
    let errMsg: string;
    if (!error.ok) {
      errMsg = "Can't connect to server.";
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Promise.reject(errMsg);
  }

  getLocalJson(localJson): Observable<any> {
    return this.http.get(localJson, { withCredentials: false }).pipe(
      tap((data) => data),
      catchError(this.handleError)
    );
  }

  async initMultiLevelList(localJson: string) {
    try {
      const cityData = [];
      this.getLocalJson(localJson).subscribe((districtData) => {
        Object.keys(districtData).forEach((index) => {
          const itemLevel1 = {
            value: districtData[index].code,
            label: districtData[index].name,
            children: [],
          };
          const data = districtData[index].cities;
          Object.keys(data).forEach((index1) => {
            const itemLevel2 = {
              value: data[index1].code,
              label: data[index1].name,
              children: [],
            };
            const data2 = data[index1].districts;
            Object.keys(data2).forEach((index2) => {
              const itemLevel3 = {
                value: index2,
                label: data2[index2],
                children: [],
              };
              itemLevel2.children.push(itemLevel3);
            });
            itemLevel1.children.push(itemLevel2);
          });
          cityData.push(itemLevel1);
        });
      });

      return cityData;
    } catch (err) {
      console.error(err);
    }
  }
}
