import { createAction, props } from '@ngrx/store';

export const loadLogin = createAction(
	'[Login] Load Login',
	props<{ validation: any; token: any; myInfo: any }>()
);

export const loadLoginSuccess = createAction(
	'[Login] Load Login Success',
	props<{ payload: any }>()
);
export const loadLoginError = createAction('[Login] Load Login Error');

export const LogOut = createAction('[Login] LogOut');
export const LogOutSuccess = createAction('[Login] LogOut Success');
export const LogOutError = createAction('[Login] LogOut Error');

export const ChangeMyInfo = createAction(
	'[login] change MyInfo',
	props<{ payload: Object }>()
);

export const addMyInfoLike = createAction(
	'[login] add MyInfo Liked',
	props<{ filedName: any; targetId: any }>()
);

export const removeMyInfoLike = createAction(
	'[login] remove MyInfo Liked',
	props<{ filedName: any; targetId: any }>()
);

export const tokenLoginStart = createAction('[Login] Token Login Start');

// export const addMyInfo = createAction('addMyInfo', props<{ myInfo: any }>());
