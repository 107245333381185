import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { LikeService } from '../../../services/like.service';
import { ServiceService } from '../../../services/service.service';

@Component({
	selector: 'app-service-details',
	templateUrl: './servicedetails.page.html',
	styleUrls: ['./servicedetails.page.scss'],
})
export class ServicedetailsPage implements OnInit {
	myId = null;
	currentUrl;
	previousUrl;
	service: any = {
		_id: '',
		id: '',
		review: [],
		introduction: '',
		likedBy: [],
		owner: {
			_id: '',
			nickname: '',
			imageURL: '',
		},
		URL: null,
		imageURL: '',
		shopURL: '',
		category: {
			main: '',
		},
	};
	certificates;
	url;
	alreadyLoggedIn = false;
	myInfo: any = {};

    data: Object;

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public authService: AuthService,
		private likeService: LikeService,
		private globalService: GlobalService,
		public globals: Globals,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		this.store.select('login').subscribe((res) => {
			this.myInfo = res.myInfo;
			this.alreadyLoggedIn = res.status === 'success';
		});
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
        this.myId = this.activatedRoute.snapshot.paramMap.get('myId');
        this.data = {_id:this.myId}
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/service'
				: this.activatedRoute.snapshot.paramMap.get('p');
		this.currentUrl = this.router.url;

		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.currentUrl = event.url;
			}
		});

		this.serviceService.getServiceDetails(this.myId).subscribe((data: any) => {
			this.service = data;
            this.service.rateAvg = this.service.rateAvg.toFixed(1);
			if (data.certificates) {
				this.certificates = data.certificates;
			}
			this.url = data.serviceURL;
			this.service.likedByUser = this.myInfo.likedService && this.myInfo.likedService.includes(this.service._id);
		});
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	share() {
		this.globalService.share(this.service);
	}

	ngOnInit() {}

	like(type, target) {
		this.likeService.like(type, target).subscribe((data: any) => {
			this.service = data;
		});
	}

	unlike(type, target) {
		this.likeService.unlike(type, target).subscribe((data) => {
			this.service = data;
		});
	}

	serviceURL() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.service.serviceURL);
	}

	openPurchasePage() {
		//  window.open("http://" + this.serviceDetails.shopURL, "_system");

		//let browser = new InAppBrowser("http://" + this.serviceDetails.shopURL, '_blank');
		//let browser = new InAppBrowser('https://appear.in/' + "jfisdlfjsl",'_system');
		window.open('http://' + this.service.shopURL, '_system');
	}
}
