import { createReducer, on } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import {
	loadEventsError,
	loadEventsStart,
	loadEventsSuccess,
	loadNextPageEventsStart,
} from '../actions/events.action';
//状态名称
export const eventsKey = 'events';

//状态类型接口
export interface RequestParams {
	limit?: number;
	page?: number;

	category?: any;

	city?: any;

	ownerId?: any;

	reviewCount?: number;

	nickname?: string;

	introduction?: string;
}
export interface State {
	requestParams: RequestParams;
	data: any[];
	status: string;
	noMoreData: boolean;
}

// 初始状态
export const initialState: State = {
	requestParams: {
		limit: environment.perPage,
		page: 1,
	},
	data: [],
	status: 'idle',
	noMoreData: false,
};

//创建reducer函数
export const eventsReducer = createReducer(
	initialState,
	//保存 request params
	on(loadEventsStart, (state, action) => {
		return {
			...state,
			status: 'started',
			requestParams: action.payload,
		};
	}),
	on(loadNextPageEventsStart, (state) => {
		return {
			...state,
			status: 'started',
		};
	}),
	//保存结果
	on(loadEventsSuccess, (state, action) => {
		return {
			...state,
			status: 'success',
			noMoreData: action.noMoreData,
			data: action.payload,
		};
	}),
	on(loadEventsError, (state) => ({
		...state,
		status: 'fail',
	}))

	// on(unlikeEventStart, (state, action) => {
	// 	let event = JSON.parse(JSON.stringify(state.data));
	// 	for (let key in event) {
	// 		if (event[key]._id == action.tarGetId) {
	// 			event[key].likedByUser = false;
	// 		}
	// 	}
	// 	return {
	// 		...state,
	// 		data: event,
	// 	};
	// })
);
