import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { OrgService } from 'src/app/services/org.service';
import { AppState } from 'src/app/store';
import { UserService } from '../../../../services/user.service';

@Component({
    selector: 'app-org-teachers',
    templateUrl: './orgteachers.page.html',
    styleUrls: ['./orgteachers.page.scss'],
})
export class OrgteachersPage implements OnInit {
    page = 1;
    previousUrl;
    currentUrl;

    teachers$: Observable<any>;
    allTeacherVideos: any = [];
    allTeacherServices: any = [];
    allTeacherBigEvents: any = [];

    orgteachers: any = [];
    serviceType = null;
    searchString = {};
    category = null;
    subCategory = null;
    orgId = null;

    myInfo;

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private navCtrl: NavController,

        private orgService: OrgService,

        private store: Store<AppState>
    ) {
        this.store.select('login').subscribe((res) => {
            this.myInfo = res.myInfo;
        });
        this.orgId = this.activatedRoute.snapshot.paramMap.get('orgId');
        console.log(this.myInfo);
        this.currentUrl = this.router.url;
        this.previousUrl =
            this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
            this.activatedRoute.snapshot.paramMap.get('p') === null
                ? '/tabs/teachers'
                : this.activatedRoute.snapshot.paramMap.get('p');

        console.log(this.orgId);
        this.teachers$ = this.orgService.orgMembers(this.orgId);
    }

    ngOnInit() {}
}
