import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../../../globals';
import { AuthService } from '../../../../../services/auth.service';
import { ChangeObserverService } from '../../../../../services/changeObserver.service';
import { GlobalService } from '../../../../../services/global.service';
import { OrgService } from '../../../../../services/org.service';
import { ServiceService } from '../../../../../services/service.service';
import { TeacherService } from '../../../../../services/teacher.service';
import { UserService } from '../../../../../services/user.service';

@Component({
	selector: 'app-review-org-member-application',
	templateUrl: './reviewOrgMemberApplication.page.html',
	styleUrls: ['./reviewOrgMemberApplication.page.scss'],
})
export class ReviewOrgMemberApplicationPage implements OnInit {
	id: string;
	password: string;
	param = 'world';
	certificates = [];
	categoryName = [];
	candidate: any = {};

	uploadedImg = { data: undefined };
	page = 1;
	searchString = {};
	_id;
	org_id;
	currentUrl;

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private teacherService: TeacherService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public globals: Globals,
		public authService: AuthService,
		private userService: UserService,
		private orgService: OrgService,

		private changeObserver: ChangeObserverService,
		private globalService: GlobalService,
		public navCtrl: NavController,
		private _location: Location
	) {
		this._id = this.activatedRoute.snapshot.paramMap.get('_id');
		this.org_id = this.activatedRoute.snapshot.paramMap.get('org_id');
		console.log(this.org_id);
		this.currentUrl = this.router.url;

		this.userService.getUserDetails(this._id).subscribe((res: any) => {
			this.candidate = res;
		});
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {}

	rejectMember(_id) {
		this.orgService.rejectOrgCandidate(this.org_id, _id).subscribe(
			(data) => {
				console.log(data);
				this.globalService.toast(this.translate.instant('success'), 1500, 'top');
				// this.candidates = this.candidates.filter(x => x !== _id)
			},
			(error) => {
				this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
			}
		);
	}

	approveMember(_id) {
		this.orgService.acceptOrgCandidate(this.org_id, _id).subscribe(
			(data) => {
				this.globalService.toast(this.translate.instant('success'), 1500, 'top');
				// this.candidates = this.candidates.filter(x => x !== _id)
			},
			(error) => {
				this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
			}
		);
	}
}
