/**
 * Created by liuchao on 6/25/16.
 */
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
import 'rxjs/add/operator/map';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { JMessageService } from '../../../services/jMessage.service';

@Component({
	selector: 'app-user-agreement',
	templateUrl: './userAgreement.page.html',
	styleUrls: ['./userAgreement.page.scss'],
})
export class UserAgreementPage {
	userAgreement = 'User Agreement';

	constructor(
		public globalService: GlobalService,
		public globals: Globals,
		public authService: AuthService,
		private translate: TranslateService,
		private jMessage: JMessageService,
		private http: HttpClient,
		public navCtrl: NavController,
		private _location: Location
	) {}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ionViewWillEnter() {}
}
