import { createAction, props } from '@ngrx/store';
import { RequestParams } from '../reducer/events.reducer';

export const loadEventsStart = createAction(
	'[Events] Load Events',
	props<{
		payload: RequestParams;
	}>()
);
export const loadNextPageEventsStart = createAction(
	'[Events] Load Next Page Events'
);
export const loadEventsSuccess = createAction(
	'[Events] Load Events Success',
	props<{ payload: any[]; noMoreData: boolean }>()
);
export const loadEventsError = createAction('[Events] Load Events Error');
