import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class EventService {
	public perpage = 10;
	queryString;

	constructor(private http: HttpClient, private utilService: UtilService) {}

	get(queryParams) {
		const params: HttpParams =
			this.utilService.makeQueryParams(queryParams);

		console.log(this.queryString);
		return this.http.get(environment.defaultURL + '/bigEvent', {
			params,
		});
	}

	getMyEvents(search, page: number, category, city, ownerId) {
		if (ownerId) {
			this.queryString =
				this.perpage + '&page=' + page + '&ownerId=' + ownerId;
		} else if (category && city) {
			this.queryString =
				this.perpage +
				'&page=' +
				page +
				'&category=' +
				category +
				'&city=' +
				city;
		} else if (category) {
			this.queryString =
				this.perpage + '&page=' + page + '&category=' + category;
		} else {
			this.queryString = this.perpage + '&page=' + page;
		}
		if (search) {
			this.queryString += '&search=' + JSON.stringify(search);
		}

		return this.http.get(
			environment.defaultURL + '/myBigEvents?limit=' + this.queryString
		);
	}

	toParam(param) {
		if (param) {
			console.log(param);
			return Object.keys(param)[0];
		}
		return '';
	}

	getEventDetails(_id) {
		return this.http.get(
			environment.defaultURL + '/bigEventDetails?_id=' + _id
		);
	}

	putEvent(bigEvent) {
		console.log(bigEvent);
		if (bigEvent.price) {
			bigEvent.price = bigEvent.price * 1;
		}
		if (bigEvent.priceBeforeDiscount) {
			bigEvent.priceBeforeDiscount = bigEvent.priceBeforeDiscount * 1;
		}
		return this.http.put(environment.defaultURL + '/bigEvent', bigEvent);
	}

	postEvent(bigEvent) {
		if (bigEvent.price) {
			bigEvent.price = bigEvent.price * 1;
		}
		if (bigEvent.priceBeforeDiscount) {
			bigEvent.priceBeforeDiscount = bigEvent.priceBeforeDiscount * 1;
		}
		return this.http.post(environment.defaultURL + '/bigEvent', bigEvent);
	}

	deleteEvent(_id) {
		return this.http.delete(
			environment.defaultURL + '/bigEvent/deleteEvent?_id=' + _id
		);
	}
}
