import { createReducer, on } from '@ngrx/store';
import {
	loadManageOrgsError,
	loadManageOrgsStart,
	loadManageOrgsSuccess,
} from '../actions/manageOrgs.action';

//状态名称
export const manageOrgsKey = 'manageOrgs';

//状态类型接口
export interface State {
	status: string;
}

// 初始状态
export const initialState: State = {
	status: 'idle',
};

//创建reducer函数
export const reducer = createReducer(
	initialState,
	on(loadManageOrgsStart, (state, action) => ({
		...state,
		status: 'started',
	})),
	//保存结果
	on(loadManageOrgsSuccess, (state, action) => ({
		...state,
		status: 'success',
		data: action.payload,
	})),
	on(loadManageOrgsError, (state) => ({
		...state,
		status: 'fail',
	}))
);
