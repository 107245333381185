import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonInput, NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { readConversation } from 'src/app/store/actions/conversations.action';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { ChangeObserverService } from '../../services/changeObserver.service';
import { GlobalService } from '../../services/global.service';
import { JMessageService } from '../../services/jMessage.service';

@Component({
	selector: 'app-chat',
	templateUrl: 'chat.component.html',
	styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
	@ViewChild('content') content: IonContent;
	@ViewChild('ionicInput') ionicInput: IonInput;
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef;
	@ViewChild('imgInput', { static: false }) imgInput: ElementRef;

	toUserNickName;
	toUser;
	toUserAvatar;
	mUser;
	contentText;
	msgList = [];
	previousUrl;
	rows = 1;
	user;
	currentUrl;
	target_id;
	target_name;
	target_uuid;
	target_avatar;
	isDesktop = false;
	isPwa = false;
	currentMsgListLength = 0;

	ImageUrl: string;

	msgs: any;

	conversation: any;

	login: any;

	changeStyle = 'bottom:5px';

	constructor(
		public jMessage: JMessageService,

		private activatedRoute: ActivatedRoute,
		private router: Router,
		public globals: Globals,
		public authService: AuthService,
		private changeObserver: ChangeObserverService,
		private globalService: GlobalService,
		private platform: Platform,
		private cd: ChangeDetectorRef,
		private store: Store<AppState>,
		public navCtrl: NavController,
		private _location: Location
	) {
		const parsedParam = this.activatedRoute.snapshot.paramMap;
		this.target_id = parsedParam.get('targetId');
		this.target_name = parsedParam.get('targetName');
		this.target_uuid = parsedParam.get('targetUuid');
		this.ImageUrl = parsedParam.get('targetImage') || '';

		this.previousUrl =
			parsedParam.get('p') === 'undefined' || parsedParam.get('p') === null
				? '/tabs/teacher'
				: parsedParam.get('p');
		this.currentUrl = this.router.url;

		this.isDesktop = this.platform.is('desktop') ? true : false;
		this.isPwa = this.platform.is('pwa') ? true : false;

		this.store.select('login').subscribe((res) => {
			this.user = res.myInfo.id;
			this.login = res;
		});

		this.store.select('msglist').subscribe((res) => {
			const msgList = res[this.login.myInfo.id] || [];
			this.msgs = msgList.filter((x) => x.from_id === this.target_id || x.target_id === this.target_id);
		});

		this.store.select('conversations').subscribe((res) => {
			this.conversation = res.data[this.login.myInfo.id];
			// this.jMessage.JIM.addSingleReceiptReport({
			// 	username: this.login.myInfo.id,
			// 	msg_ids: this.msgs.map((x) => x.msg_id),
			// })
		});

		this.toUser = this.target_id;

		const targetConversation = !this.conversation ? null : this.conversation.find((x) => x.id === this.target_id);

		this.target_avatar = targetConversation?.avatar;
		this.changeObserver.currentEvent.subscribe((data) => {
			if (data.name === 'jMessage:onMsgReceive') {
				this.content.scrollToBottom(300);
			}
		});

		setTimeout(() => {
			this.content.scrollToBottom(300);
		}, 1000);

		this.platform.keyboardDidShow.subscribe((ev) => {
			const { keyboardHeight } = ev;
			this.changeStyle = `bottom: ${keyboardHeight}px`;

			// this.changeStyle =
			// 	'`transform:translate3d(0, ${keyboardHeight + 100}px, 0)`';
			// console.log(this.changeStyle);
		});

		this.platform.keyboardDidHide.subscribe(() => {
			// Move input back to original location
			this.changeStyle = 'bottom:10px';
		});
	}

	navBack() {
		this.store.dispatch(
			readConversation({
				id: this.toUser,
				user: this.user,
				needRead: 0,
			})
		);
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	//只要进入页面就执行
	formatTime(time) {
		return this.globalService.formatTime(time);
	}

	filterMsgList(msgList) {
		if (Array.isArray(msgList)) {
			return msgList.filter(
				(msgItem) =>
					(this.user === msgItem.from_id && this.toUser === msgItem.target_id) ||
					(this.toUser === msgItem.from_id && this.user === msgItem.target_id)
			);
		}
	}

	ionViewWillLeave() {
		this.jMessage.clearUnreadMessage(this.target_id);
	}

	selectImageSource() {
		this.imgInput.nativeElement.click();
	}

	//选中调用选取文件的input输入框
	selectFileSource() {
		this.fileInput.nativeElement.click();
	}

	// Used for browser direct file upload
	//发送图片
	uploadFile(event) {
		const eventObj: any = event as any;
		const target: HTMLInputElement = eventObj.target as HTMLInputElement;
		const file: File = target.files[0];
		const fd = new FormData();
		const fileName = this.login.myInfo.id + Date.now();
		fd.append(fileName, file);
		if (file) {
			this.jMessage.sendSinglePic(this.toUser, fd, this.ImageUrl, this.target_name).then(() => {
				setTimeout(() => {
					this.content.scrollToBottom(300);
					this.globals.buttonDisabled = false;
				}, 100);
				this.contentText = '';
			});
		}
	}

	//发送文件
	sendFile(event) {
		const eventObj: any = event as any;
		const target: HTMLInputElement = eventObj.target as HTMLInputElement;
		const file: File = target.files[0];
		const fd = new FormData();
		const fileName = this.login.myInfo.id + Date.now();
		fd.append(fileName, file);
		if (file) {
			this.jMessage.sendSingleFile(this.toUser, fd, this.ImageUrl, this.target_name).then(() => {
				setTimeout(() => {
					this.content.scrollToBottom(300);
					this.globals.buttonDisabled = false;
				}, 100);
				this.contentText = '';
			});
		}
	}

	//点击文件时a标签有些文件下载不了，需要到极光上来获取文件
	downloadFile(res: any) {
		// const blob = new Blob(['123'], { type: 'application/octet-stream' });
		// const url = window.URL.createObjectURL(blob);
		// const a = document.createElement('a');
		// const fileName = '123.txt';
		// a.href = url;
		// a.download = fileName;
		// a.click();
		// window.URL.revokeObjectURL(url);
	}

	/**
	 * 发送文本消息
	 */
	toSendMsg() {
		const obj = this;
		obj.globals.buttonDisabled = true;
		if (obj.contentText) {
			obj.jMessage.sendSingleMsg(obj.toUser, obj.contentText, this.ImageUrl, this.target_name).then(() => {
				setTimeout(() => {
					obj.content.scrollToBottom(30);
					obj.globals.buttonDisabled = false;
				}, 100);
				obj.contentText = '';
			});
		}
		obj.globals.buttonDisabled = false;
	}

	//初始化
}
