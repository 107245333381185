import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class OrgService {
  public perpage = 10;
  queryString;

  constructor(
    private http: HttpClient,
    public globalService: GlobalService,
    private utilService: UtilService
  ) {}

  adminGetOrg(search, page: number, category, city, orgId) {
    this.queryString = this.perpage + '&page=' + page;
    if (category) {
      search['category'] = category;
    }

    if (city) {
      search['city'] = city;
    }
    console.log(search);
    if (search && search.startTime) {
      this.queryString += '&startTime=' + search.startTime;
      delete search.startTime;
    }
    if (search && search.endTime) {
      this.queryString += '&endTime=' + search.endTime;
      delete search.endTime;
    }
    if (search) {
      this.queryString += '&search=' + JSON.stringify(search);
    }
    if (orgId) {
      this.queryString += '&orgId=' + orgId;
    }

    return this.http.get(
      environment.defaultURL +
        '/organization/adminGetUser?limit=' +
        this.queryString
    );
  }

  get(queryParams: any) {
    const params: HttpParams = this.utilService.makeQueryParams(queryParams);

    return this.http.get(environment.defaultURL + '/organization', {
      params,
    });
  }

  getOrgDetails(_id) {
    return this.http.get(
      environment.defaultURL + '/organizationDetails?_id=' + _id
    );
  }

  putOrg(org) {
    if (org.price) {
      org.price = org.price * 1;
    }
    if (org.priceBeforeDiscount) {
      org.priceBeforeDiscount = org.priceBeforeDiscount * 1;
    }
    return this.http.put(environment.defaultURL + '/org', org);
  }

  postOrg(org) {
    return this.http.post(environment.defaultURL + '/org', org);
  }

  deleteOrg(_id) {
    return this.http.delete(environment.defaultURL + '/org?_id=' + _id);
  }

  tryCreateOrg(org) {
    return this.http.post<any>(
      environment.defaultURL + '/organization/tryCreateOrg',
      org
    );
  }

  approveOrgCreation(org) {
    return this.http.post(
      environment.defaultURL + '/organization/approveOrgCreation',
      org
    );
  }

  rejectOrgCreation(org) {
    return this.http.post(
      environment.defaultURL + '/organization/rejectOrgCreation',
      org
    );
  }

  organizations(search, page: number, category, city, orgId, userLevel?) {
    this.queryString = this.perpage * 2 + '&page=' + page;
    if (category) {
      this.queryString += '&category=' + category;
    }
    if (city) {
      this.queryString += '&city=' + city;
    }
    if (orgId) {
      this.queryString += '&orgId=' + orgId;
    }
    if (userLevel) {
      this.queryString += '&userLevel=' + userLevel;
    }

    if (search && search.startTime) {
      this.queryString += '&startTime=' + search.startTime;
      delete search.startTime;
    }
    if (search && search.endTime) {
      this.queryString += '&endTime=' + search.endTime;
      delete search.endTime;
    }
    if (search) {
      this.queryString += '&search=' + JSON.stringify(search);
    }

    return this.http.get(
      environment.defaultURL +
        '/organization/organizations?limit=' +
        this.queryString
    );
  }

  applyToJoinOrg(org) {
    return this.http.post<any>(
      environment.defaultURL + '/organization/applyToJoinOrg',
      org
    );
  }

  quitOrg(org) {
    return this.http.post<any>(
      environment.defaultURL + '/organization/quitOrg',
      org
    );
  }

  acceptOrgCandidate(_id, member_id) {
    return this.http.post<any>(
      environment.defaultURL + '/organization/acceptOrgCandidate?_id=' + _id,
      { _id: member_id }
    );
  }

  rejectOrgCandidate(_id, member_id) {
    return this.http.post<any>(
      environment.defaultURL + '/organization/rejectOrgCandidate?_id=' + _id,
      { _id: member_id }
    );
  }

  orgsSecretaryOf() {
    return this.http.get(
      environment.defaultURL + '/organization/orgsSecretaryOf'
    );
  }

  orgsManagerOf() {
    return this.http.get(
      environment.defaultURL + '/organization/orgsManagerOf'
    );
  }

  orgsMemberOf() {
    return this.http.get(environment.defaultURL + '/organization/orgsMemberOf');
  }

  orgCandidates(org_id) {
    let query = '';
    if (org_id) {
      query = '?_id=' + org_id;
    }
    return this.http.get<any>(
      environment.defaultURL + '/organization/orgCandidates' + query
    );
  }

  orgMembers(org_id) {
    let query = '';
    if (org_id) {
      query = '?_id=' + org_id;
    }
    return this.http.get<any>(
      environment.defaultURL + '/organization/orgMembers' + query
    );
  }

  orgSecretaries(org_id) {
    let query = '';
    if (org_id) {
      query = '?_id=' + org_id;
    }
    return this.http.get<any>(
      environment.defaultURL + '/organization/orgSecretaries' + query
    );
  }

  applyForSecretary(myInfo) {
    return this.http.post(
      environment.defaultURL + '/organization/applyForSecretary',
      myInfo
    );
  }

  approveApplyForSec(sec) {
    return this.http.post(
      environment.defaultURL + '/organization/approveApplyForSec',
      sec
    );
  }

  rejectApplyForSec(sec) {
    return this.http.post(
      environment.defaultURL + '/organization/rejectApplyForSec',
      sec
    );
  }

  setSecretary(org_id, member_id) {
    // id in query is org_id, _id in body is the member you set secretary
    return this.http.post(
      environment.defaultURL + '/organization/setSecretary?_id=' + org_id,
      {
        _id: member_id,
      }
    );
  }

  dismissSecretary(_id, org_id) {
    let query = '';
    if (org_id) {
      query = '?_id=' + org_id;
    }
    return this.http.post(
      environment.defaultURL + '/organization/dismissSecretary' + query,
      {
        _id,
      }
    );
  }
}
