import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventService } from 'src/app/services/event.service';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import {
	loadEventsError,
	loadEventsStart,
	loadEventsSuccess,
	loadNextPageEventsStart,
} from '../actions/events.action';
import { RequestParams } from '../reducer/events.reducer';

@Injectable()
export class EventsEffects {
	constructor(
		private actions$: Actions, // this is an RxJS stream of all actions
		private eventService: EventService, // we will need this service for API calls
		private store: Store<AppState>
	) {}

	loadEvents$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadEventsStart),
			map((action) => action.payload),
			switchMap((payload) =>
				this.eventService.get(payload).pipe(
					map((res: any[]) => {
						let limit = payload['limit'] || environment.perPage;
						return loadEventsSuccess({
							payload: res,
							noMoreData: res.length < limit,
						});
					}),
					catchError(() => of(loadEventsError()))
				)
			)
		)
	);

	loadNextPageEvents$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadNextPageEventsStart),
			withLatestFrom(this.store.select('events')),
			//switchMap gets the newest (takeLatest), mergeMap gets every (takeEvery)
			switchMap(([action, oldEventsRedux]) => {
				let requestParams: RequestParams = oldEventsRedux.requestParams;
				let page: number = requestParams['page'] + 1;
				let limit: number =
					oldEventsRedux['limit'] || environment.perPage;
				let partialEvents: any[] = oldEventsRedux['data'].slice(
					0,
					(page - 1) * limit
				);

				//如果此时发来的是分页请求且已经判断没有数据了，则不再发送请求.否则正常数据
				return oldEventsRedux.noMoreData === true
					? EMPTY
					: this.eventService.get({ ...requestParams, page }).pipe(
							map((res: any[]) => {
								//返回小于limit的时候，nomoredata为true
								return loadEventsSuccess({
									payload: [...partialEvents, ...res],
									noMoreData: res.length < limit,
								});
							}),
							catchError(() => of(loadEventsError()))
					  );
			})
		)
	);
}
