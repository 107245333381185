import { createAction, props } from '@ngrx/store';

export const loadManageOrgsStart = createAction(
	'[ManageOrgs] Load ManageOrgs'
);
export const loadManageOrgsSuccess = createAction(
	'[ManageOrgs] Load ManageOrgs Success',
	props<{ payload: object }>()
);
export const loadManageOrgsError = createAction('[ManageOrgs] Load ManageOrgs Error');
