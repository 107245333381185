/**
 * Created by liuchao on 6/25/16.
 */
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
import 'rxjs/add/operator/map';
import { AppState } from 'src/app/store';
import { loadLogin } from 'src/app/store/actions/login.action';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { JMessageService } from '../../../services/jMessage.service';

@Component({
	selector: 'app-sign-up',
	templateUrl: './signUp.page.html',
	styleUrls: ['./signUp.page.scss'],
})
export class SignUpPage {
	id: string;
	password: string;
	param = 'world';
	validation: any = {};
	buttonDisabled = false;
	alreadyLoggedIn = false;
	myInfo: any = {};
	isShow = false;
	emailCode: any;

	constructor(
		public globalService: GlobalService,
		public authService: AuthService,
		private translate: TranslateService,
		private jMessage: JMessageService,
		public globals: Globals,
		private http: HttpClient,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store.select('login').subscribe((res) => {
			this.myInfo = res.myInfo;

			this.alreadyLoggedIn = res.status === 'success';
		});
	}

	verificationCode(form) {
		const email = form.value.id;
		this.http
			.post(environment.defaultURL + '/user/verificationCode', {
				Email: email,
			})
			.subscribe((res) => {
				console.log(res);
				if (res !== false) {
					this.isShow = true;
					const obj: any = res;
					this.emailCode = obj.code;
					this.globalService.toast(
						this.translate.instant('successfully post verification code'),
						1500,
						'top'
					);
					return this.emailCode;
				} else {
					this.globalService.toast(this.translate.instant('post verification code error'), 1500, 'top');
				}
			},
            (err) => {
                if (err.error.errorCode === 1016) {
                    this.globalService.toast(this.translate.instant('User id already registered'), 1500, 'top');
                } else
				    this.globalService.toast(this.translate.instant('err'), 1500, 'top');
			});
		//这里调用获取验证码的接口，
		//当返回true的时候，将this.isShow改为true，注册按钮本身不变，
		//但是要加一个验证码，然后验证码要改一下后端的登录，
		//写入user前判断一下验证码和缓存里的验证码是否正确，
		//如果正确，那么注册，不正确，直接return false
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this.navCtrl.navigateBack('/tabs/settings');
	}

	onSwipeRight($event) {}

	ionViewWillEnter() {}

	register(form) {
		const validation = form.value;
        console.log(validation,'111')
		if (!validation.id || !validation.nickname || !validation.password) {
			this.globalService.toast(this.translate.instant('id,password,nickname are needed.'), 1500, 'top');
		} else if (validation.id.length < 4 || validation.password.length < 6) {
			this.globalService.toast(
				this.translate.instant('id and password need more than 4 or 6 characters!'),
				1500,
				'top'
			);
		} else if (!this.jMessage.JIM.isInit()) {
			this.jMessage.initJMessage().then((d1) => {
				this.doRegister(validation);
				console.log('jMessage initialized');
			});
		} else {
			this.doRegister(validation);
		}
	}

	doRegister(validation) {
		console.log(validation);
		this.globalService.presentLoading('registering');
		this.http.post(environment.defaultURL + '/user/', validation).subscribe(
			(data) => {
				if (data === null) {
					this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
				} else if (data === false) {
					this.globalService.toast(this.translate.instant('verification code error'), 1500, 'top');
				} else {
					this.store.dispatch(loadLogin({ validation, token: null, myInfo: {} }));
					this.navCtrl.back();
				}
			},
			(err) => {
                if (err.error.errorCode === 1016) {
                    this.globalService.toast(this.translate.instant('User id already registered'), 1500, 'top');
                } else
				    this.globalService.toast(this.translate.instant('err'), 1500, 'top');
			}
		);
	}

	testJSON(text) {
		if (typeof text !== 'string') {
			return false;
		}
		try {
			JSON.parse(text);
			return true;
		} catch (error) {
			return false;
		}
	}
}
