import { createAction, props } from '@ngrx/store';
import { RequestParams } from '../reducer/teachers.reducer';

export const loadTeachersStart = createAction(
	'[Teachers] Load Teachers',
	props<{
		payload: RequestParams;
	}>()
);
export const loadNextPageTeachersStart = createAction('[Teachers] Load Next Page Teachers');
export const loadTeachersSuccess = createAction(
	'[Teachers] Load Teachers Success',
	props<{ payload: any[]; noMoreData: boolean }>()
);
export const loadTeachersError = createAction('[Teachers] Load Teachers Error');
