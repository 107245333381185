import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { LikeService } from '../../services/like.service';
import { OrgService } from '../../services/org.service';
import { LikeComponent } from '../like/like.component';

@Component({
	selector: 'app-shared-org-list',
	templateUrl: 'sharedorglist.component.html',
})
export class SharedorglistComponent implements OnInit {
	@ViewChild(IonInfiniteScroll, { static: true })
	likeComponent: LikeComponent;

	infiniteScroll: IonInfiniteScroll;

	@Input() searchString: any;

	@Input() orgId: any;

	@Input() orgs: any;
	page = 1;
	// orgs: any = [];
	noMoreData = false;
	currentUrl;
	alreadyLoggedIn = false;
	myInfo: any = {};

	constructor(
		private router: Router,
		private orgService: OrgService,
		private globalService: GlobalService,
		public authService: AuthService,
		private likeService: LikeService,
		public globals: Globals,
		private translate: TranslateService,
		public navCtrl: NavController,
		private _location: Location
	) {
		this.currentUrl = this.router.url;
	}
	// ngOnChanges(changes: SimpleChanges): void {
	// 	throw new Error('Method not implemented.');
	// }

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		const n = this.currentUrl.split('/').length;
		if (n > 3) {
			this._location.back();
		}
	}

	onSwipeRight($event) {}

	// async doit(){
	//   await this.orgs
	//   console.log(this.orgs)
	// }
	ngOnInit() {
		// this.getOrgs()
		console.log(this.orgs);
	}

	getOrgs() {}

	toggleInfiniteScroll() {
		this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
	}

	// ngOnChanges(changes: SimpleChanges) {
	// 	this.orgs = [];
	// 	this.getOrgs();
	// }
}
