import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { readConversation } from 'src/app/store/actions/conversations.action';
import { Globals } from '../../../globals';
import { AuthService } from '../../../services/auth.service';
import { GestureCtrlService } from '../../../services/gesture-ctrl.service';
import { GlobalService } from '../../../services/global.service';
import { LikeService } from '../../../services/like.service';
import { TeacherService } from '../../../services/teacher.service';

@Component({
	selector: 'app-teacher-details',
	templateUrl: './teacherdetails.page.html',
	styleUrls: ['./teacherdetails.page.scss'],
})
export class TeacherdetailsPage implements OnInit {
	@ViewChild('content') content: ElementRef;

	openTeacherReviewPage;
	myId = null;
	currentUrl;
	previousUrl;
	user;
	toUser;
	screenWidth = { value: 0 };

	teacher: any = {
		imageURL: '',
		likedBy: [],
		likedCount: 0,
		organization: '',
		nickname: '',
		sex: '',
		age: '',
		reviewNumber: 0,
		introduction: '',
		_id: '',
		id: '',
	};
	certificates = [];
	alreadyLoggedIn = false;
	myInfo: any = {};

	data: Object;

	constructor(
		private http: HttpClient,
		private teacherService: TeacherService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		public authService: AuthService,
		private router: Router,

		private likeService: LikeService,
		private globalService: GlobalService,
		public globals: Globals,
		public gestureCtrlService: GestureCtrlService,
		public navCtrl: NavController,
		private store: Store<AppState>,
		private _location: Location
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store
			.select('login')
			.pipe(first())
			.subscribe((res) => {
				this.myInfo = res.myInfo;
				this.alreadyLoggedIn = res.status === 'success';
			});
		this.myId = this.activatedRoute.snapshot.paramMap.get('myId');
		this.data = { _id: this.myId };

		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/teacher'
				: this.activatedRoute.snapshot.paramMap.get('p');
		this.currentUrl = this.router.url;

		this.teacherService.getTeacherDetails(this.myId).subscribe((res: any) => {
			this.teacher = res;
			this.toUser = JSON.stringify({
				_id: res._id,
				imageURL: res.imageURL,
				id: res.id,
			});
			if (res.certificates) {
				this.certificates = res.certificates;
			}

			this.teacher.likedByUser = this.myInfo.likedTeacher && this.myInfo.likedTeacher.includes(this.teacher._id);
		});
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {
		const res = this.myInfo;
		this.user = JSON.stringify({
			_id: res._id,
			imageURL: res.imageURL,
			id: res.id,
		});
	}

	ionViewDidEnter() {
		// console.log('getsture');
		// console.log(this.content);
		// const result = this.gestureCtrlService.useSwiperGesture(this.content);
		// console.log(result);
		// result === 'right' ? this._location.back() : null;
	}

	//        var timestamp = new Date().getTime();

	like(type, target) {
		this.likeService.like(type, target).subscribe((data) => {
			this.teacher = data;
		});
	}

	unlike(type, target) {
		this.likeService.unlike(type, target).subscribe((data) => {
			this.teacher = data;
		});
	}

	share() {
		this.globalService.share(this.teacher);
	}

	goChat(teacher) {
		this.store.dispatch(
			readConversation({
				id: teacher.id,
				user: this.myInfo.id,
				needRead: 0,
			})
		);
		this.router.navigate([
			'/tabs/teacher/teacherdetails/',
			teacher._id,
			'chat',
			teacher.id,
			teacher.nickname,
			teacher._id,
			teacher.imageURL,
			{ p: this.currentUrl },
		]);

		// } else {
		//     this.globalService.toast(this.translate.instant('onlyCanChatYouLike'), 1500, 'top');
		// }
	}

	openTeacherCertificate(certificate) {
		const navigationExtras: NavigationExtras = {
			queryParams: {
				certificate: JSON.stringify(certificate),
			},
		};
		this.navCtrl.navigateForward(
			['/tabs/teacher/teacherdetails', this.teacher._id, 'teacherCertificate'],
			navigationExtras
		);
	}

	//   like(type, target) {
	//   if (!this.validation._id) {
	//     this.teacherProvider.alert('', this.teacherProvider.translateItem('loginPlease'), ['OK'], this.alertCtrl).present();
	//   } else {
	//     var likeInfo = {
	//       _id: target._id,
	//       type: type
	//     }
	//     this.favoriteProvider.like(likeInfo, this.validation).then(data => {
	//       let fieldName = 'liked' + type.toLowerCase().replace(/\b[a-z]/g, function (l) {
	//         return l.toUpperCase();
	//       });
	//       if (data) {
	//         this.validation[fieldName].push(target._id);
	//         if (this.teacherService.likedBy) this.teacherService.likedBy.push(this.validation._id)
	//         this.teacherService.likedCount += 1
	//         this.teacherProvider.saveToStorage('ribenxueValidation',this.validation)
	//       }
	//       // var index = this.validation.likedTeacher.indexOf(teacher._id);
	//       //this.validation.likedTeacher.splice(index, 1);
	//
	//       this.teacherProvider.saveToStorage('ribenxueValidation',this.validation)
	//     }, err => {
	//       if (this.testJSON(err._body)) {
	//         var errJson = JSON.parse(err._body)
	//         if (errJson.message)
	//           this.teacherProvider.alert('', this.teacherProvider.translateItem(errJson.message), ['OK'], this.alertCtrl).present();
	//       }
	//     })
	//   }
	// }
}
