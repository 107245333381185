import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { Globals } from '../../../../globals';
import { AuthService } from '../../../../services/auth.service';
import { GlobalService } from '../../../../services/global.service';
import { OrgService } from '../../../../services/org.service';
import { SettingsService } from '../../../../services/settings.service';
import { UserService } from '../../../../services/user.service';

declare let Qiniu: any;

@Component({
  selector: 'app-join-org',
  templateUrl: './joinOrg.page.html',
  styleUrls: ['./joinOrg.page.scss'],
})
export class JoinOrgPage implements OnInit {
  @ViewChild('Select', { static: false }) selectComp;
  @ViewChildren('Select2') selectComp2s;
  @ViewChild('Select3', { static: false }) selectComp3;

  id: string;
  password: string;
  param = 'world';
  newCertificate: any = {};
  certificates: any = [];
  itemName;
  itemValue: any;
  uploadedImg = { data: undefined };
  selected1;

  trueOrFalse;
  selected: any = [];
  buttonDisabled = false;
  deviceTypeIsIpad = false;
  orgs: any = [];
  previousUrl;
  orgsMemberOf: any = [];
  page = 1;
  search = {};

  myInfo;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    public globals: Globals,
    public authService: AuthService,
    private globalService: GlobalService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private userService: UserService,
    private orgService: OrgService,

    public navCtrl: NavController,
    public alertController: AlertController,
    private _location: Location,
    private store: Store<AppState>
  ) {
    // this.myInfo = globals.myInfo;
    this.store
      .select('login')
      .pipe(first())
      .subscribe((res) => {
        this.myInfo = res.myInfo;
      });
    this.itemName = this.activatedRoute.snapshot.paramMap.get('itemName');
    this.itemValue = this.activatedRoute.snapshot.paramMap.get('itemValue');
    this.orgService
      .organizations(this.search, this.page, null, null, null, 1)
      .subscribe(
        (res) => {
          // this.globalService.toast(this.translate.instant('success'), 1500, 'top');
          console.log(res);
          this.page += 1;
          this.orgs = this.orgs.concat(res);
        },
        (err) => {
          if (err) {
            this.globalService.toast(
              this.translate.instant(err._body.toString()),
              1500,
              'top'
            );
          }
        }
      );

    this.orgService.orgsMemberOf().subscribe((data) => {
      this.orgsMemberOf = data;
      console.log(data);
    });

    this.previousUrl =
      this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
      this.activatedRoute.snapshot.paramMap.get('p') === null
        ? '/tabs/settings/myInformation'
        : this.activatedRoute.snapshot.paramMap.get('p');
  }

  onSwipeLeft($event) {
    this.navCtrl.setDirection('back', true, 'back');
    this._location.back();
  }

  onSwipeRight($event) {}

  ngOnInit() {}

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  applyToJoinOrg(org) {
    console.log(org);
    return this.orgService.applyToJoinOrg(org).subscribe((data) => {
      this.globalService.toast(this.translate.instant('success'), 1500, 'top');
      //this.myInfo.orgAppliedFor = org;
      this.navCtrl.navigateBack(this.previousUrl);
    });
  }

  quitOrg = async (org) => {
    const confirm = await this.globalService.confirmationAlert(
      this.translate.instant('comfirmation'),
      this.translate.instant('Do you really want to quit')
    );
    if (confirm) {
      return this.orgService.quitOrg(org).subscribe(() => {
        // this.globalService.toast(this.translate.instant('success'), 1500, 'top');
        this.navCtrl.navigateBack(this.previousUrl);
      });
    } 
  };

  applyToChooseSecretary(secretary) {
    return this.http
      .post<any>(
        environment.defaultURL + '/user/applyToChooseSecretary',
        secretary
      )
      .pipe(
        tap((_) => {
          this.globalService.toast(
            this.translate.instant('success'),
            1500,
            'top'
          );
          this.navCtrl.navigateBack(this.previousUrl);
        }),
        catchError(this.handleError('applyToChooseSecretary', []))
      );
  }

  applyToDeleteSecretary(secretary) {
    return this.http
      .post<any>(
        environment.defaultURL + '/user/applyToDeleteSecretary',
        secretary
      )
      .pipe(
        tap((_) => {
          this.globalService.toast(
            this.translate.instant('success'),
            1500,
            'top'
          );
          this.navCtrl.navigateBack(this.previousUrl);
        }),
        catchError(this.handleError('applyToDeleteSecretary', []))
      );
  }
}
