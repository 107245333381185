import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../../globals';
import { AuthService } from '../../../../services/auth.service';
import { GlobalService } from '../../../../services/global.service';
import { JMessageService } from '../../../../services/jMessage.service';
import { SettingsService } from '../../../../services/settings.service';
import { UserService } from '../../../../services/user.service';

declare let Qiniu: any;

@Component({
	selector: 'app-change-password',
	templateUrl: './changePassword.page.html',
	styleUrls: ['./changePassword.page.scss'],
})
export class ChangePasswordPage {
	data: any = {};

	previousUrl;

	constructor(
		private http: HttpClient,
		private settingsService: SettingsService,
		public globals: Globals,
		public authService: AuthService,
		public jMessageService: JMessageService,
		private globalService: GlobalService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private userService: UserService,
		public navCtrl: NavController,
		private _location: Location
	) {
		this.previousUrl =
			this.activatedRoute.snapshot.paramMap.get('p') === 'undefined' ||
			this.activatedRoute.snapshot.paramMap.get('p') === null
				? '/tabs/settings/myInformation'
				: this.activatedRoute.snapshot.paramMap.get('p');
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	changePass() {
		if (this.data.oldPassword && this.data.newPassword) {
			this.userService.changePassword(this.data).subscribe(
				(r) => {
					console.log(r);
                    //前端修改密码，目前JIM密码修改写到后端
					// this.jMessageService.updateSelfPwd(this.data.oldPassword, this.data.newPassword);
					this.globalService.toast(this.translate.instant('successfullyUpdated'), 1500, 'top');
					this._location.back();
				},
				(err) => {
                    if (err.error.errorCode === 1002) {
                        this.globalService.toast(this.translate.instant('Wrong password'), 1500, 'top');
                    } else
					    this.globalService.toast(this.translate.instant('err'), 1500, 'top');
				}
			);
		} else {
            !this.data.oldPassword
                ? this.globalService.toast(
                    this.translate.instant('Please enter the old password'),
                    1500,
                    'top'
                )
                : this.globalService.toast(
                    this.translate.instant('Please enter the new password'),
                    1500,
                    'top'
                );
        }
	}
}
