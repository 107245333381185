import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { LikeService } from '../../services/like.service';
import { ServiceService } from '../../services/service.service';
import { LikeComponent } from '../like/like.component';

@Component({
	selector: 'app-shared-service-list',
	templateUrl: 'sharedservicelist.component.html',
})
export class SharedservicelistComponent implements OnInit {
	// @ViewChild(IonInfiniteScroll, {static: true}) infiniteScroll: IonInfiniteScroll;
	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

	likecomponent: LikeComponent;

	@Input() searchString: any;
	@Input() orgId: any;

	@Input() services: any;

	page = 1;
	// services: any = [];
	noMoreData = false;
	currentUrl;
	alreadyLoggedIn = false;
	myInfo: any = {};

	constructor(
		private router: Router,
		private serviceService: ServiceService,
		private globalService: GlobalService,
		private likeService: LikeService,
		public authService: AuthService,
		public globals: Globals,
		private translate: TranslateService,
		public navCtrl: NavController,
		private _location: Location
	) {
		this.currentUrl = this.router.url;
		console.log(this.services);
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		const n = this.currentUrl.split('/').length;
		if (n > 3) {
			this._location.back();
		}
	}

	onSwipeRight($event) {}

	// async doit(){
	//   await this.services
	//   console.log(this.services)
	// }
	ngOnInit() {
		// this.getServices();
	}

	loadData(event) {}

	getServices() {}

	toggleInfiniteScroll() {
		this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
	}
}
