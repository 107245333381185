import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store';
import { Globals } from '../../../../../globals';
import { AuthService } from '../../../../../services/auth.service';
import { ChangeObserverService } from '../../../../../services/changeObserver.service';
import { GlobalService } from '../../../../../services/global.service';
import { OrgService } from '../../../../../services/org.service';
import { ServiceService } from '../../../../../services/service.service';
import { TeacherService } from '../../../../../services/teacher.service';
import { UserService } from '../../../../../services/user.service';

@Component({
	selector: 'app-manage-org-member',
	templateUrl: './manageOrgMember.page.html',
	styleUrls: ['./manageOrgMember.page.scss'],
})
export class ManageOrgMemberPage implements OnInit {
	page = 1;
	searchString = {};
	_id;
	org_id;
	myInfo;
	alreadyLoggedIn;
	currentUrl;
	member: any = {
		imageURL: '',
		likedBy: [],
		likedCount: 0,
		organization: '',
		nickname: '',
		sex: '',
		age: '',
		reviewNumber: 0,
		introduction: '',
		_id: '',
		id: '',
	};

	constructor(
		private http: HttpClient,
		private serviceService: ServiceService,
		private teacherService: TeacherService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,

		private sanitizer: DomSanitizer,
		public globals: Globals,
		public authService: AuthService,
		private userService: UserService,
		private orgService: OrgService,

		private changeObserver: ChangeObserverService,
		private globalService: GlobalService,
		public navCtrl: NavController,
		private _location: Location,
		private store: Store<AppState>
	) {
		// this.myInfo = globals.myInfo;
		// this.alreadyLoggedIn = globals.alreadyLoggedIn;
		this.store.select('login').subscribe((res) => {
			this.myInfo = res.myInfo;
			this.alreadyLoggedIn = res.status === 'success';
		});
		this._id = this.activatedRoute.snapshot.paramMap.get('_id');
		this.org_id = this.activatedRoute.snapshot.paramMap.get('org_id');
		console.log(this.org_id);

		this.currentUrl = this.router.url;

		this.userService.getUserDetails(this._id).subscribe((res: any) => {
			this.member = res;
		});
	}

	onSwipeLeft($event) {
		this.navCtrl.setDirection('back', true, 'back');
		this._location.back();
	}

	onSwipeRight($event) {}

	ngOnInit() {}

	dismissSecretary(member_id) {
		this.orgService.dismissSecretary(member_id, this.org_id).subscribe(
			(data) => {
				this.globalService.toast(this.translate.instant('success'), 1500, 'top');
				// this.candidates = this.candidates.filter(x => x !== _id)
				this._location.back();
			},
			(error) => {
				this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
			}
		);
	}

	setSecretary(member_id) {
		this.orgService.setSecretary(this.org_id, member_id).subscribe(
			(data) => {
				this.globalService.toast(this.translate.instant('success'), 1500, 'top');
				// this.candidates = this.candidates.filter(x => x !== _id)
				this._location.back();
			},
			(error) => {
				this.globalService.toast(this.translate.instant('fail'), 1500, 'top');
			}
		);
	}
}
